import React, { Component } from 'react';

import HorizontalLabelPositionBelowStepper from '../../../../components/Stepper/index'

import {utils } from '../../../../utils/utils';

import InstructionCard from '../components/InstructionCard/index';

import BlockUI from '../../../../utils/blockUI';

import { localStorageLabel } from '../../../../utils/enum/index';

import { fetchMsmeData, updateExistingDistributor } from '../../service/index'

import OnboardingInviteCard from '../components/OnboardingInviteCard';

import _ from 'lodash';

const { getItemByName, storeDataFromDatabase, logOut } = utils;

const { STEPPER_INDEX } = localStorageLabel;

export default class Onboarding extends Component {

  state = {
    loader: false
  }

  startLoader = () => this.setState({ loader: !this.state.loader });

  checkIfTheOnboardingIndexExists = () => {
    return !getItemByName(STEPPER_INDEX);
  }

  componentDidMount = async () => {

    /**
     * Triggering logout operation when user open same application in multiple tabs for assessment
     * Reason why added? If multiple window open by distributor and performing logout to clear localStorage data.
     */
    window.addEventListener("storage", (event) => {
      logOut()
    })

    if (this.checkIfTheOnboardingIndexExists()) {
      this.startLoader();
      let { status, result = {} } = await fetchMsmeData();
      const { stepInfo = {} } = result;
      const isInvalidStepInfo = _.isEmpty(stepInfo);
      if (isInvalidStepInfo) {
        await updateExistingDistributor();
        // incase of existing distributor without stepInfo truncating result
        result = {};
      }
      status && result && this.saveDataToLocalStorage(result);
      this.startLoader();
    }
  }

  renderPreOnboardingScreen = () => (
    <div>
      <OnboardingInviteCard />
      <InstructionCard onStartAssessment={this.onStartAssessment} />
    </div>
  )

  movetoNextStepper = (index) => {
    localStorage.setItem(STEPPER_INDEX, index);
    setTimeout(() => { window.location.reload(); }, 500);
  }

  saveDataToLocalStorage = (result) => {
    const {
      onboardingStep = '', aadhaarInfo = {}, gstInfo = {}, saleDeed = "", gstCertificate = "",
      pancard = "", nocDocument = "", stepInfo = {}, panInfo = {}, companyDetails = {}, itrInfo = [],
      gstReturns = [], parsedGSTR = [], parsedITR = [], bankStatement = {}, odDetails = {},
      dateOfIncorporation = "", businessCode = "", agreementDeed =""
    } = result;
    const { ODaccount = "", ODLimit = "" } = odDetails || {};
    storeDataFromDatabase("aadharDetails", aadhaarInfo);
    storeDataFromDatabase("businessCode", businessCode);
    storeDataFromDatabase("taxpayerInfo", gstInfo);
    storeDataFromDatabase("saleDeed", saleDeed);
    storeDataFromDatabase("gstCertificate", gstCertificate);
    storeDataFromDatabase("PAN", pancard);
    storeDataFromDatabase("panNumber", panInfo);
    storeDataFromDatabase("personalPanNumber", panInfo);
    storeDataFromDatabase("nocDocument", nocDocument);
    storeDataFromDatabase("stepInfo", stepInfo);
    storeDataFromDatabase("agreementDeed", agreementDeed);
    storeDataFromDatabase("partnershipCertificate", companyDetails);
    storeDataFromDatabase("itrInfo", itrInfo);
    storeDataFromDatabase("gstReturns", gstReturns);
    storeDataFromDatabase("parsedITR", parsedITR);
    storeDataFromDatabase("parsedGSTR", parsedGSTR);
    storeDataFromDatabase("statementFetch", bankStatement);
    storeDataFromDatabase("ODaccount", ODaccount);
    storeDataFromDatabase("ODLimit", ODLimit);
    storeDataFromDatabase("companyDetails", companyDetails);
    storeDataFromDatabase("dateOfIncorporation", dateOfIncorporation);
    if(onboardingStep) {
      this.movetoNextStepper(onboardingStep)
    }
  }

  onStartAssessment = () => this.movetoNextStepper(0)

  render() {

    const { loader } = this.state;

    return (<div className="flexbox flexboxSpaceBox">
      <div className="FormLayout">
        {loader ? <BlockUI /> : <div className="marginTopForm">
          {this.checkIfTheOnboardingIndexExists() ?
            this.renderPreOnboardingScreen() :
            <HorizontalLabelPositionBelowStepper />}
        </div>}
      </div>
    </div>)
  }

}