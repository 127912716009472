import React, { Fragment } from "react";

import Modal from 'react-awesome-modal';

import VerifyIcon from '../../../../../../components/Indicators/VerifyIcon';

const { onFileUpload } = require('../../service/index')

const { languageStrings } = require('../../../../../../utils/enum/languageStrings')

const { GSTR_RETURNS: { SUCCESSFULLY_UPLOADED } } = languageStrings;

const renderImage = (captchaImage) => {
    return <img src={(captchaImage)} alt='logo' className='captchaImage' />
}

const renderCaptchaBox = (props) => {
    const { state, onValueChange } = props;
    const { captchaImage, captchaCode } = state;
    return <Fragment> <div className="flex">
        {renderImage(captchaImage)}
    </div>
        <div className="flex">
            <input className={'msmeInput filepadding'}
                placeholder='Enter Captcha Code' type="string"
                name="captchaCode" value={captchaCode}
                onChange={onValueChange} defaultValue=""
            />
        </div>
    </Fragment>
}

const renderVerifyButton = (initiateVerification) => {
    return <p className={"combutton"} onClick={() => initiateVerification()}>Verify</p>
}

export const Loader = () => {
    return (
        <div className="textcenter">
            <img src={require('../../../../../assets/images/loader.gif')} alt='logo' className={'loaderGif'} />
        </div>
    )
}

export const RenderModel = (props) => {

    const { closeModal, state, onValueChange, initiateVerification } = props;

    const { captchaImage, loader, enableCaptchaVerification = false } = state;

    return <Modal visible={enableCaptchaVerification} effect="fadeInUp" onClickAway={() => closeModal()}>
        <div className="padding50">
            {captchaImage && renderCaptchaBox({ state, onValueChange })}
            <div className="textcenter">
                {loader ? <Loader /> : renderVerifyButton(initiateVerification)}
            </div>
        </div>
    </Modal>
}

export const renderInputFields = (props) => {

    const { state, inputFields, index } = props;

    const { gstrNumber, fileUploadInfo } = state;

    const {
        label, name, verifiedStatus, accept, filingMonth,
        updateState, renderSuccessGrowl, renderErrorGrowl,
        truncateFileUpload, fileUploadRef, additionalLabel = ''
    } = inputFields;

    return <div className='IncExpFormField' key={index}>

        <label className='flexlabel'>{label}{additionalLabel}<div className="mandatory">*</div></label>
 
        <div className="halfWidth">
            <div className="flex">
                <input className='msmeInput paddingtopFile ITRfileMargin filepadding inputLayout'
                    name={name} type='file' style={verifiedStatus ? { display: 'none', width: "80%" } : {}}
                    ref={reference => fileUploadRef[`${name}`] = reference}
                    onChange={(event => onFileUpload(event, updateState, {
                        gstrNumber, filingMonth,
                        showSuccessGrowl: renderSuccessGrowl,
                        showErrorGrowl: renderErrorGrowl,
                        fileUploadInfo,
                        truncateFileUpload: truncateFileUpload,
                        acceptType: accept
                    }))}
                    accept={accept}
                    disabled={verifiedStatus}
                />
                {verifiedStatus && <label>{SUCCESSFULLY_UPLOADED} &nbsp;</label>}
                <VerifyIcon isVerify={verifiedStatus} />
            </div>
        </div>
    </div>
}

export const renderGSTRVerification = (props) => {

    const {
        placeHolderString, inputType,
        isDisabled = true, label = "", required,
        initiateVerification, inputValue
    } = props;

    return (
        <div className="BusinessFormField">

            <label className='flexlabel'>{label} {required && <div className="mandatory">*</div>}</label>

            <div style={{ display: 'flex', width: '50%', flexDirection: 'column' }}>

                <div style={{ display: 'flex' }}>

                    <input
                        className={"msmeInput filepadding"}
                        value={inputValue}
                        placeholder={placeHolderString}
                        type={inputType}
                        disabled={isDisabled}
                    />

                    <div className={'prospectiveDistributor-awcode-verify-button'}
                        onClick={() => initiateVerification()}>
                        <p className='prospectiveDistributor-awcode-verify-button-text'>
                            {'Verify'}
                        </p>
                    </div>

                </div>

            </div>
        </div>)
}