import React, { Component, Fragment } from 'react';

import Modal from 'react-awesome-modal';

import { sendAadharCaptcha, sendAadharOTPmsg, verifyAadharOTP, fetchDocumentDetails, aadharSkipOtp, verifyPinCode } from '../../../../screens/MSMEOnboarding/Component/AdhaarForm/service';

import Loader from '../../../../utils/loader';

import { utils } from '../../../../utils/utils';

import { updateMsme } from '../../Component/service/index';

import { generateAadharPayload, generateAadharDetailsPayload } from '../../Component/FormPayload/index';

import { validateAadharForm, isValid, validatePersonalInfo } from "../Validation/index"

import Snackbar from '../../../../utils/alert';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import { languageStrings } from '../../../../utils/enum/languageStrings';

const { getDetailsByName } = utils;

const { localStorageStrings } = require('../../../../utils/enum/localStorageStrings');

const {
    AADHAAR_DETAILS, AADHAAR_FRONT_DETAILS
} = localStorageStrings;

const { AADHAAR_SCREEN } = languageStrings;

const DEFAULT_FILE_UPLOAD = 'UPLOAD';
const OTP = 'OTP';
const AADHAAR_FRONT = 'AADHAR_FRONT';
const AADHAAR_BACK = 'AADHAR_BACK';
const PIN_CODE_LENGTH = 6;

const moment = require('moment');

export default class AdhaarForm extends Component {
    constructor(props) {
        super(props);
        Mixpanel.track(trackString.PERSONAL_DETAILS_FORM_PAGE)
    }
    state = {
        showOTPPage: false,
        showDetails: getDetailsByName(AADHAAR_DETAILS) ? true : false,
        showSubmit: getDetailsByName(AADHAAR_DETAILS) ? false : true,
        aadharNumber: '',
        otp: '',
        loader: false,
        showVerify: false,
        aadharDetails: getDetailsByName(AADHAAR_DETAILS) ? getDetailsByName(AADHAAR_DETAILS) : {},
        fileUpload: DEFAULT_FILE_UPLOAD,
        name: "",
        dob: "",
        gender: "",
        careof: "",
        country: "",
        district: "",
        houseNumber: "",
        landmark: "",
        locality: "",
        pin_code: "",
        post_office: "",
        state: "",
        street: "",
        address: "",
        subDistrict: "",
        datePicker: "",
        ocrAddress: "",
        isSkipOtp: false,
        isPersonalInformationVerified: false,
        popLoader: false,
        errorStatus: {
            dob: false,
            name: false,
            gender: false,
            careof: false,
            address: false,
            district: false,
            state: false,
            pin_code: false,
        }
    }

    showOTPPage = async () => {
        await sendAadharOTPmsg({ state: this.state, updateState: this.updateState });
    }

    verifyOTP = async () => {
        await verifyAadharOTP({ state: this.state, updateState: this.updateState });
    }
    verifyCaptcha = async () => {
        this.setState({ otp: "" })
        await sendAadharCaptcha({ state: this.state, updateState: this.updateState });
    }

    onValueChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onHandleChange = async event => {
        const { name, value } = event.target;
        if( name === 'pin_code'){
            (value.length === PIN_CODE_LENGTH) ? await verifyPinCode({ pinCode: value, updateState: this.updateState, state: this.state }) : 
            this.setState({ district: "", state: "", stateName: "" })
        }
        const { errorStatus = {} } = this.state;
        let validationResult = isValid(name, value);
        this.setState({ errorStatus: { ...errorStatus, [name]: validationResult }, [name]: value })
    }

    renderAadharVerifyInput = (inputFields) => {
        const { label, value, placeholder, name } = inputFields;
        return <>
            <div className="spaceAround">
                <div className="verticalCenter">
                    <label className={name === 'aadharNumber' ? 'flexlabel warningText' : 'flexlabel'}>{label} <div className="mandatory">*</div></label>
                </div>
                <div className="halfWidth">
                    <input className='msmeInput' value={value} placeholder={placeholder} type='phone'
                        name={name} onChange={this.onValueChange} defaultValue="" />
                    <div>
                        {name === 'aadharNumber' && <div className="filepadding">
                            <div className="warningText">{AADHAAR_SCREEN.REQUIRED_TO_VERIFY_IDENTITY}</div>
                        </div>} </div>
                </div>
            </div>
            {this.renderResendOTP()}
        </>
    }
    renderOTPPage = () => {
        const { showOTPPage, aadharNumber, otp } = this.state;
        return showOTPPage ?
            this.renderAadharVerifyInput({ name: 'otp', value: otp, placeholder: AADHAAR_SCREEN.ENTER_OTP, label: AADHAAR_SCREEN.ENTER_AADHAAR_OTP })
            :
            this.renderAadharVerifyInput({ name: 'aadharNumber', value: aadharNumber, placeholder: AADHAAR_SCREEN.ENTER_AADHAAR_NUMBER, label: AADHAAR_SCREEN.PLEASE_PROVIDE_YOUR_AADHAAR_NUMBER })
    }

    updateAadhar = async () => {

        const formInfo = { aadhaarInfo: getDetailsByName(AADHAAR_DETAILS) };

        await updateMsme({ formInfo, props: this.props, updateState: this.updateState });

    }

    updateState = (data) => {
        this.setState(data);
    }

    renderAadharFields = (inputFields) => {
        const { name, value } = inputFields;
        return <Fragment>
            <div className="adharDetailsHeader">
                {name}
            </div>
            <div className={'adharDetailValue'} > {value} </div>
        </Fragment>
    }
    renderAadharDetails = () => {
        const { aadharDetails } = this.state;
        const { name, dob, gender, careof, photo, address, district } = aadharDetails;
        const aadharFields = [
            { name: AADHAAR_SCREEN.NAME, value: name },
            { name: AADHAAR_SCREEN.DOB, value: dob },
            { name: AADHAAR_SCREEN.GENDER, value: gender },
            { name: AADHAAR_SCREEN.FATHER_NAME, value: careof },
            { name: AADHAAR_SCREEN.ADDRESS, value: `${address} ${district}` }
        ];

        return (
            <>
                <div className="aadharSpace">
                    <div>
                        {aadharFields.map(field => this.renderAadharFields(field))}
                    </div>
                    <div>
                        {photo && <div>
                            <img height='200px' src={`data:image/png;base64,${photo}`} alt='pic' />
                        </div>}
                    </div>
                </div>
                <div className='textcenter'>
                    {!this.state.loader ? <p className={"combutton aadharButtonMargin"} onClick={() => { this.updateAadhar() }} >{AADHAAR_SCREEN.SUBMIT}</p> : null}
                </div>
            </>
        )
    }

    renderModal = () => {
        return <Modal visible={this.state.visible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
            <div className="padding50">
                {this.state.captachaImage && this.renderCaptchaBox()}
                <div className="textcenter">
                    {this.state.loader ? <Loader /> : this.renderVerifyButton()}
                </div>
            </div>
        </Modal>
    }
    renderVerifyButton = () => {
        return <p className={"combutton"} onClick={() =>
            this.showOTPPage()}>{AADHAAR_SCREEN.VERIFY}</p>
    }

    renderCaptchaBox = () => {
        const { captachaImage, captachaCode } = this.state;
        return <Fragment> <div className="flex">
            {this.renderImage(captachaImage)}
        </div>
            <div className="flex">
                <input className={'msmeInput filepadding'} placeholder='Enter Captcha Code' type="string" name="captachaCode" value={captachaCode} onChange={this.onValueChange} defaultValue="" />
            </div>
        </Fragment>
    }
    renderImage = (captachaImage) => {
        return <img src={(captachaImage)} alt='logo' className='captchaImage' />
    }

    closeModal = () => this.setState({
        loader: false, visible: false, captachaCode: "",
        captachaImage: ""
    });

    renderTitle = () => {
        return <div className="textcenter">
            <h3 className="subtitle formtitleMargin">{AADHAAR_SCREEN.PERSONAL_DETAILS}</h3>
        </div>
    }

    renderErrorGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
    }
    renderSuccessGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
    }
    handleClose = () => { this.setState({ showGrowl: false }); };

    renderResendOTP = () => {
        return <div>
            {!this.state.showDetails && this.state.showOTPPage &&
                <div className="resendOtp" onClick={() => { this.verifyCaptcha() }} >{AADHAAR_SCREEN.RESEND_OTP}</div>}
        </div>
    }

    renderPopLoader = () => {
        return <Modal visible={this.state.popLoader} effect="fadeInUp">
            <div className="padding50">
                <div className="textcenter">
                    <Loader />
                </div>
            </div>
        </Modal>
    }

    renderButton = () => {

        const { showVerify, showSubmit, loader } = this.state;

        return <div className='textcenter'>
            {showSubmit && !loader && <p className={"combutton marginButton"} onClick={() => { this.verifyCaptcha() }} >{AADHAAR_SCREEN.VERIFY_USING_OTP}</p>}

            {showVerify && <div className={"combutton marginButton"} onClick={() => { this.verifyOTP() }} >{AADHAAR_SCREEN.VERIFY}</div>}

            {<div className="resendOtp" onClick={() => { this.autoFetchForm() }}>{AADHAAR_SCREEN.SKIP_OTP_STRING}</div>}

        </div>
    }


    renderAadharOTP = () => {
        const { finishFormCallback } = this.props;

        const { showDetails } = this.state;
        return <Fragment>
            {showDetails && this.renderAadharDetails(finishFormCallback)}

            {!showDetails && this.renderOTPScreen()}
        </Fragment>
    }

    renderOTPScreen = () => {
        const { isSkipOtp, isPersonalInformationVerified } = this.state;
        return <Fragment>
            {!isSkipOtp && this.renderOTPPage()}

            {!isSkipOtp && this.renderButton()}
            {isSkipOtp && this.renderAadharWithOTP()}

            {!isPersonalInformationVerified && isSkipOtp && <div className="textcenter">
                <p className={"combutton"}
                    onClick={() => { this.verifyAadharDetails() }} >{AADHAAR_SCREEN.VERIFY}</p>
            </div>}
        </Fragment>
    }

    verifyAadharDetails = async () => {

        try {

            const { errorStatus = {} } = this.state;

            let result = validateAadharForm(this.state);

            if (result === false) return this.renderErrorGrowl(AADHAAR_SCREEN.ENTER_ALL_MANDATORY_FIELDS);

            // validating whether all the fields having valid information & not in native language
            const isValidInfo = validatePersonalInfo(errorStatus);

            if (isValidInfo === false) return this.renderErrorGrowl(AADHAAR_SCREEN.PLEASE_ENTER_THE_VALID_DATA);

            const aadharDetails = this.renderAadharPayload();

            localStorage.setItem(AADHAAR_DETAILS, JSON.stringify(aadharDetails));

            this.setState({ isPersonalInformationVerified: true, aadharDetails, showDetails: true })
        }
        catch (err) { }
        finally {
            this.setState({ isPersonalInformationVerified: false })
        }
    }
    renderAadharPayload = () => {
        const {
            name,
            dob,
            gender,
            careof,
            district,
            pin_code,
            state,
            address,
            photo=""
        } = this.state;
        let formatDOB = dob;
        let formatGender = gender;
        if (gender && gender.includes('Male')) formatGender = 'M';
        if (gender && gender.includes('Female')) formatGender = 'F';
        if (dob) formatDOB = moment(dob).format(`DD-MM-YYYY`)

        return  {
            name,
            dob: formatDOB,
            gender: formatGender,
            careof,
            district,
            country: "India",
            pin_code,
            address,
            state,
            subDistric: district,
            photo
        }
    }

    renderAadharWithOTP = () => {
        const aadharDetails = generateAadharDetailsPayload({ stateValue: this.state, onValueChange: this.onHandleChange });
        return aadharDetails.map(field => this.renderInputFields(field));
    }

    renderAadharFiles = () => {
        const aadharPayload = generateAadharPayload({ state: this.state, onFileChange: this.onFileChange });
        return <Fragment>
            {aadharPayload.map(field => this.renderInputFields(field))}
            {!this.state.loader && <div className="textcenter"><p className={"combutton marginButton"} onClick={() => { this.movetoOtpScreen() }} >{AADHAAR_SCREEN.SUBMIT}</p></div>}
        </Fragment>
    }
    renderInputFields = (inputFields) => {
        const { label, isVerify, required, name, errorString = "" } = inputFields;
        let { errorStatus = {} } = this.state;
        const isInvalid = errorStatus[`${name}`];
        return <div className='AadharFormField'>
            <label className='flexlabel textLeft'>{label} <div className="mandatory">*</div></label>
            <div className={name === "gender" || required ? "flex halfWidth" : "halfWidth"}>
                {this.renderSingleBox(inputFields)}
                {required && this.renderVerifyIcon(isVerify)}
                {isInvalid && <div className="errMsgMarginTop">{errorString}</div>}
            </div>
        </div>
    }

    renderRadioButton = (option, name) => {
        return (
            <div className="spaceBetween verticalText marginGender">
                <input className="checkboxInput" type="radio" name={name} value={option} onClick={this.onValueChange} checked={this.state.gender === option}/>
                <label className='flexlabel'>{option}</label>
            </div>
        )
    }
    renderVerifyIcon = (isVerify) => {
        return <img src={(isVerify ? require('../../../assets/images/right.jpeg') : require('../../../assets/images/wrong.jpg'))} alt='logo' className='iconImg' />
    }

    renderSingleBox = (inputFields) => {
        const { type, name, onChange, placeholder, value, disabled, stateList, accept = "", max = "" } = inputFields;
        const { state="", stateName="" } = this.state;
        const disableStateName = (state!=="" && stateName!== "");
        return <Fragment>
            {type === 'file' && <input className={`paddingtopFile msmeInput fileUploadWidth filepadding`} accept={accept} type={type} name={name} onChange={this.onFileChange} />}
            {type === 'radio' &&
                ["Male", "Female"].map(field => this.renderRadioButton(field, 'gender'))}
            {type === 'dropdown' && <select className="msmeInput filepadding" name={name} id={name} onChange={this.onHandleChange} disabled={disableStateName}>
                {disableStateName && 
                <option selected={disableStateName}  value={''} defaultChecked={disableStateName}>{stateName || "Please choose your state"}</option>}
                {!disableStateName && stateList && stateList.length && (stateList.map((e, index) => (
                    <option id={index} value={e.value}>{e.label}</option>
                )))}
            </select>}
            {(type === 'number') && <input className={`msmeInput filepadding`} placeholder={placeholder} type={type} name={name} value={value} onChange={onChange} defaultValue="" disabled={disabled} />}
            {(type === 'string') && <input className={`msmeInput filepadding`} placeholder={placeholder} type={type} name={name} value={value} onChange={onChange} defaultValue="" disabled={disabled} maxLength={max}/>}
            {type === 'date' && <input className={`msmeInput filepadding`} placeholder={'DD-MM-YYYY'} type={type} name={name} max={max} value={value} onChange={this.changeCalendar} onClick={this.changeCalendar} defaultValue="" disabled={disabled} />}
        </Fragment>
    }

    changeCalendar = (event) => {
        const { value, name } = event.target;
        const { errorStatus = {} } = this.state;
        // validation check for DOB
        let validationResult = isValid(name, moment(value, `YYYY-MM-DD`));
        this.setState({ errorStatus: { ...errorStatus, [name]: validationResult }, [name]: value })
    }
    onFileChange = async (event) => {
        if (event.target.type === 'file' && event.target.files && event.target.files.length) {
            const fileName = event.target.name;
            const formData = new FormData();
            let file = event.target.files[0];
            const size = file && file.size;
            const MB = (size / 1024) / 1000;
            if (MB >= 1) {

                Mixpanel.track(`UPLOADING_${fileName}_FAILED`)

                this.renderErrorGrowl(AADHAAR_SCREEN.FILE_SIZE_IS_TOO_LARGE);
                return
            }
            formData.append('files', file, fileName);


            const result = await fetchDocumentDetails({ updateState: this.updateState, formData });
            this.setState({ popLoader: false });
            if (result && result.documentDetails) {
                return this.formatAadharDetails(fileName, result.documentDetails);
            } else {

                Mixpanel.track(`UPLOADING_${fileName}_FAILED`)

                this.renderErrorGrowl(AADHAAR_SCREEN.PLEASE_UPLOAD_A_VALID_DOCUMENT);
            }
        }
    }

    formatAadharDetails = (fileName, documentDetails) => {
        if (fileName === AADHAAR_FRONT) {
            const { aadhaarNumberFront } = documentDetails;
            if (!aadhaarNumberFront) {

                Mixpanel.track(trackString.UPLOADING_AADHAAR_FRONT_FAILED);

                return this.renderErrorGrowl(AADHAAR_SCREEN.PLEASE_UPLOAD_A_VALID_DOCUMENT);
            }
            this.renderSuccessMsg();
            localStorage.setItem(AADHAAR_FRONT_DETAILS, JSON.stringify(documentDetails));

            Mixpanel.track(trackString.UPLOADED_AADHAAR_FRONT_SUCCESSFULLY);

            this.checkMovetoNextStage({ aadhaarNumberFront, loader: false });
        }
        if (fileName === AADHAAR_BACK) {
            const { aadhaarNumberBack, address } = documentDetails;
            this.renderSuccessMsg();
            localStorage.setItem(AADHAAR_BACK, JSON.stringify(documentDetails));

            Mixpanel.track(trackString.UPLOADED_AADHAAR_BACK_SUCCESSFULLY);

            this.checkMovetoNextStage({ aadhaarNumberBack, ocrAddress: address, loader: false });
        }
    }

    renderSuccessMsg = () => {
        this.renderSuccessGrowl(AADHAAR_SCREEN.DOCUMENT_VALIDATED_SUCCESSFULLY);
    }

    checkMovetoNextStage = (payload) => {
        this.setState(payload, () => { this.moveToNextStep(); });
    }

    moveToNextStep = () => {
        const { aadhaarNumberFront } = this.state;
        if (aadhaarNumberFront) {
            this.setState({
                aadharNumber: aadhaarNumberFront
            });
        }
    }

    movetoOtpScreen = () => {

        const AADHAAR_FRONT_INFO = getDetailsByName(AADHAAR_FRONT);

        const AADHAAR_BACK_INFO = getDetailsByName(AADHAAR_BACK);

        if (AADHAAR_BACK_INFO && AADHAAR_FRONT_INFO) {
            this.setState({ fileUpload: OTP });

            Mixpanel.track(trackString.SUBMITTED_AADHAAR_DETAILS_SUCCESSFULLY);

            return
        }

        Mixpanel.track(trackString.SUBMITTING_AADHAAR_DETAILS_FAILED);

        this.renderErrorGrowl(AADHAAR_SCREEN.PLEASE_UPLOAD_A_VALID_DOCUMENT);
    }

    autoFetchForm = async () => {

        await aadharSkipOtp({ updateState: this.updateState, state: this.state });

        this.setState({ isSkipOtp: true, loader: false });
    }


    componentDidMount() {

        const AADHAAR_FRONT_INFO = getDetailsByName(AADHAAR_FRONT);

        const AADHAAR_BACK_INFO = getDetailsByName(AADHAAR_BACK);

        let aadhaarNumberFront = "";

        let aadhaarNumberBack = "";

        if (AADHAAR_FRONT_INFO) aadhaarNumberFront = AADHAAR_FRONT_INFO && AADHAAR_FRONT_INFO.aadhaarNumberFront;

        if (AADHAAR_BACK_INFO) aadhaarNumberBack = AADHAAR_BACK_INFO && AADHAAR_BACK_INFO.aadhaarNumberBack;

        if (aadhaarNumberFront && aadhaarNumberBack && aadhaarNumberFront === aadhaarNumberBack) {
            return this.setState({
                fileUpload: OTP, aadharNumber: aadhaarNumberFront,
            });
        }
        this.setState({ aadhaarNumberFront, aadhaarNumberBack });

    }

    render() {

        const { showGrowl, growlStatus, growlMsg, loader, fileUpload } = this.state;

        return (
            <Fragment>
                <div className={"msmeOnboardingContainer"} id="formStepper">
                    {this.renderPopLoader()}
                    {this.renderTitle()}
                    {this.renderModal()}
                    <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
                    <div className='adharForm'>
                        {fileUpload === DEFAULT_FILE_UPLOAD && this.renderAadharFiles()}
                        {fileUpload === OTP && this.renderAadharOTP()}
                        {loader && <Loader />}
                    </div>
                </div>
            </Fragment>
        );
    }
}
