const { config: {
  PERFIOS_SERVICES, PERFIOS_SCANNED_SERVICES
} } = require('../../../../../config/config');

const axios = require('axios');

const { utilFunctions } = require('../../../../../utils');

const { formatDateBetweenRange } = utilFunctions;

const { utils } = require('../../../../../utils/utils');

const { Mixpanel } = require('../../../../../utils/mixPanel');

const { trackString } = require('../../../../../utils/mixpanelTracks');

const { perfiosFetch, fetchBankStatement, initiateScannedTransaction, uploadScannedTransactionStatement,
  cancelPerfiosScannedTransaction, generateScannedStatementReport,
  processPerfiosScannedStatement } = require('../../../../../utils/apiService');

const { getOrganizationID, getUserPhone, storeDataFromDatabase, getDetailsByName, getDataFromLocalStorage } = utils;

const {
  isValidAPIResponse, parseInitiationResponse, parseFileUploadResponse,
  generatePerfiosProcessStatement,
  parseScannedAPIResponse, isFileSizeTooLarge, isValidNameMatch
} = require('./utils');

const {
  localStorageStrings: {
    BANK_STATEMENT_FETCH,
    TAXPAYER_INFO,
    AADHAAR_DETAILS,
    STEP_INFO_LABEL
  }
} = require('../../../../../utils/enum/localStorageStrings');

const {
  languageStrings: {
    BANK_STATEMENT_VERIFICATION: {
      FACING_ISSUE_WHILE_FETCHING_BANK_STATEMENT, BANK_STATEMENT_FETCHED_SUCCESSFULLY,
      SCANNED_STATEMENT_PROCESSING_IS_INITIATED, FILE_SIZE_IS_TOO_LARGE
    }
  }
} = require('../../../../../utils/enum/languageStrings');

/**
 * @name formatBankStatementFetch
 * @param {*} fetchType 
 * @returns {object}
 */
export const formatBankStatementFetch = (fetchType) => {
  const { yearMonthFrom, yearMonthTo } = formatDateBetweenRange()
  return {
    clientTxId: fetchUniqueKey(),
    loanAmount: "100000",
    loanDuration: "24",
    loanType: "Home",
    callbackUrl: `${PERFIOS_SERVICES}/perfiousCallback`,
    email: "test@perfios.com",
    destination: fetchType,
    returnURL: `${window.location.origin}/dashboard/%s/%s`,
    acceptancePolicy: "atLeastOneTransactionPerMonthInRange",
    yearMonthFrom,
    yearMonthTo
  }
}

/**
 * @name fetchUniqueKey
 * @description Fetch the Unique UserID
 */
export const fetchUniqueKey = () => {
  return btoa(getOrganizationID() + getUserPhone());
}

export const fetchBankStatementAnalysis = async (payload) => {

  const { updateState, renderErrorGrowl, fetchType } = payload;

  updateState({ loader: true });

  const statementFetchOptions = formatBankStatementFetch(fetchType);

  try {

    const result = await perfiosFetch(statementFetchOptions);

    if (result) {

      Mixpanel.track(trackString.FETCHED_BANK_DETAILS_FROM_NET_BANKING_SUCCESSFULLY);

      document.getElementById('Perfios').innerHTML = result;

      document.querySelector("[name='autoform']").submit();

    }
    else {
      Mixpanel.track(trackString.FETCHING_BANK_DETAILS_FROM_NET_BANKING_FAILED);
    }
  } catch (error) {

    Mixpanel.track(trackString.FACING_ISSUE_WHILE_FETCHING_BANK_DETAILS);

    renderErrorGrowl(FACING_ISSUE_WHILE_FETCHING_BANK_STATEMENT);

    updateState({ loader: false, errorMsg: FACING_ISSUE_WHILE_FETCHING_BANK_STATEMENT });

  } finally {

    updateState({ loader: false })

  }
}

/**
 * @name fetchBankDetails
 * @description Fetch the Bank Statement Analysis Report
 */
export const fetchBankDetails = async (clientTxId, context) => {

  context.setState({ loader: true });

  try {

    const result = await fetchBankStatement(clientTxId);

    const { status = false, message = "" } = result;

    if (status) {
      // extracting account holder name
      const { customerInfo = {} } = result;

      const { name: accountHolderName = "" } = customerInfo;

      // getting personal details from aadhaar document.
      const { name = "" } = getDataFromLocalStorage(AADHAAR_DETAILS);

      // getting business details from GST certificate
      const { gst = {} } = getDetailsByName(TAXPAYER_INFO);

      const { tradeNam: businessName = "" } = gst || {};

      if (!isValidNameMatch(name, accountHolderName, businessName)) {

        Mixpanel.track(trackString.DISCREPANCY_FOUND_IN_BANK_ACCOUNT_HOLDER_CHECK);

        // updating bank statement with validation status false for name mis-match found
        context.setState({ statementFetch: { ...result, isValidAccountHolder: false }, loader: false });

      } else {
        // updating bank statement with validation status true for valid name match found
        context.setState({ statementFetch: { ...result, isValidAccountHolder: true }, loader: false });

        Mixpanel.track(trackString.VERIFIED_BANK_ACCOUNT_HOLDER_CHECK_SUCCESSFULLY);

      }
      context.setState({
        statementFetch: result, loader: false
      });

      context.renderSuccessGrowl(BANK_STATEMENT_FETCHED_SUCCESSFULLY);

      await storeDataFromDatabase(BANK_STATEMENT_FETCH, result);

      Mixpanel.track(trackString.VERIFIED_BANK_STATEMENT_SUCCESSFULLY)

    } else {

      Mixpanel.track(trackString.FETCHING_BANK_STATEMENT_FAILED)

      context.renderErrorGrowl(message);

      context.state.fetchType < 2 && context.setState({ visible: true });
    }
  } catch (error) {

    Mixpanel.track(trackString.FACING_ISSUE_WHILE_FETCHING_BANK_DETAILS)

    context.renderErrorGrowl(FACING_ISSUE_WHILE_FETCHING_BANK_STATEMENT);

    context.setState({ loader: false, errorMsg: FACING_ISSUE_WHILE_FETCHING_BANK_STATEMENT });
  } finally {
    context.setState({ loader: false });
  }
}

/**
 * @name fetchBankNames
 * @param {*} payload 
 * @returns institution names for which bank statement will be processed
 */
export const fetchBankNames = async (payload) => {
  Mixpanel.track(trackString.FETCHING_BANK_NAMES_FOR_STATEMENT_PROCESSING)
  try {
    const result = await axios.post(`${PERFIOS_SCANNED_SERVICES}/fetchInstitutionList`, payload);
    return result.data;
  } catch (error) {
    Mixpanel.track(trackString.FAILED_TO_FETCH_BANK_NAMES_FOR_STATEMENT_PROCESSING)
    return { success: false }
  }
}

/**
 * @name getInitiatePerfiosData
 * @param {*} institutionId 
 * @description payload for initiating the scanned statement processing API
 */
const getInitiatePerfiosData = institutionId => {
  const { yearMonthFrom, yearMonthTo } = formatDateBetweenRange()
  return {
    payload: {
      loanAmount: "100000",
      loanDuration: "24",
      loanType: "Home",
      processingType: "STATEMENT",
      transactionCompleteCallbackUrl: `${PERFIOS_SCANNED_SERVICES}/perfiosCallback`,
      txnId: fetchUniqueKey(),
      acceptancePolicy: "atLeastOneTransactionPerMonthInRange",
      institutionId,
      uploadingScannedStatements: true,
      yearMonthFrom,
      yearMonthTo
    }
  }
}

/**
 * @name uploadScannedStatement
 * @param {object}
 */
export const uploadScannedStatement = async (payload) => {

  const { file = '', institutionId = '' } = payload;

  const formData = new FormData();

  formData.append('somefile', file);

  // initiate transaction information
  const initiateTransactionPayload = getInitiatePerfiosData(institutionId)

  Mixpanel.track(trackString.INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION)

  // initiating the scanned statement processing API to get Perfios ID
  const initiationResponse = await initiateScannedTransaction(initiateTransactionPayload);

  if (!isValidAPIResponse(initiationResponse)) {

    Mixpanel.track(trackString.FAILED_TO_INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION)

    return initiationResponse
  }

  Mixpanel.track(trackString.SUCCESSFULLY_INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION)

  const perfiosTransactionId = parseInitiationResponse(initiationResponse);

  formData.append('perfiosTransactionId', perfiosTransactionId);

  Mixpanel.track(trackString.INITIATED_UPLOAD_SCANNED_TRANSACTION_STATEMENT)

  // API for uploading the scanned statement
  const fileUploadResponse = await uploadScannedTransactionStatement(formData);

  if (!isValidAPIResponse(fileUploadResponse)) {

    Mixpanel.track(trackString.FAILED_TO_INITIATE_UPLOAD_SCANNED_TRANSACTION_STATEMENT)

    // initiated cancel perfios scanned transaction
    Mixpanel.track(trackString.INITIATING_CANCEL_PERFIOS_SCANNED_TRANSACTION)

    await cancelPerfiosScannedTransaction({ transactionId: perfiosTransactionId })

    Mixpanel.track(trackString.SUCCESSFULLY_CANCELLED_PERFIOS_SCANNED_TRANSACTION)

    return fileUploadResponse
  }

  Mixpanel.track(trackString.SUCCESSFULLY_UPLOADED_SCANNED_TRANSACTION_STATEMENT)

  const fileId = parseFileUploadResponse(fileUploadResponse)

  const processStatementPayload = generatePerfiosProcessStatement({
    perfiosTransactionId,
    fileId
  })

  Mixpanel.track(trackString.INITIATED_PROCESS_PERFIOS_SCANNED_STATEMENT)

  // API for processing scanned statement
  const processStatementResult = await processPerfiosScannedStatement(processStatementPayload);

  if (!isValidAPIResponse(processStatementResult)) {

    Mixpanel.track(trackString.FAILED_TO_INITIATE_PROCESS_PERFIOS_SCANNED_STATEMENT)

    // Initiated cancel transaction due to issue while processing statement
    Mixpanel.track(trackString.INITIATING_CANCEL_PERFIOS_SCANNED_TRANSACTION)

    await cancelPerfiosScannedTransaction({ transactionId: perfiosTransactionId })

    // Finished cancel transaction due to issue while processing statement
    Mixpanel.track(trackString.SUCCESSFULLY_CANCELLED_PERFIOS_SCANNED_TRANSACTION)

    return processStatementResult;
  }

  Mixpanel.track(trackString.SUCCESSFULLY_INITIATED_PROCESS_PERFIOS_SCANNED_STATEMENT)

  Mixpanel.track(trackString.INITIATED_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT)

  // API for initiating report generation
  const generateReportResult = await generateScannedStatementReport({ perfiosTransactionId });

  if (!isValidAPIResponse(generateReportResult)) {

    // facing issue while generating report
    Mixpanel.track(trackString.FAILED_TO_INITIATE_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT)

    return generateReportResult;

  }

  Mixpanel.track(trackString.SUCCESSFULLY_INITIATED_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT)

  return { success: true, message: SCANNED_STATEMENT_PROCESSING_IS_INITIATED, perfiosTransactionId }

}

export const onFileChange = async (event, payload) => {

  Mixpanel.track(trackString.INITIATED_SCANNED_STATEMENT_PROCESS)

  const { state, renderErrorGrowl, updateState, scannedFile, renderSuccessGrowl } = payload

  try {

    const { bankData = { id: '' } } = state;

    if (event.target.files && event.target.files.length) {

      let file = event.target.files[0];

      const size = file && file.size;

      if (isFileSizeTooLarge(size)) {

        // Uploaded file size is too large & fr scanned copy statement validation.

        Mixpanel.track(trackString.UPLOADING_SCANNED_STATEMENT_FAILED_DUE_TO_SIZE_ISSUE)

        renderErrorGrowl(FILE_SIZE_IS_TOO_LARGE);

        return
      }

      updateState({ popLoader: true });

      const perfiosScannedAPIResponse = await uploadScannedStatement({
        file,
        institutionId: bankData.id
      });

      const { message, perfiosTransactionId } = parseScannedAPIResponse(perfiosScannedAPIResponse);

      updateState({ popLoader: false });

      if (isValidAPIResponse(perfiosScannedAPIResponse)) {

        Mixpanel.track(trackString.SUCCESSFULLY_INITIATED_SCANNED_STATEMENT_PROCESS);

        renderSuccessGrowl(message);

        const stepInfo = getDataFromLocalStorage(STEP_INFO_LABEL);

        const statementFetch = {
          customerInfo: {
            perfiosTransactionId,
            customerTransactionId: fetchUniqueKey()
          }
        }

        storeDataFromDatabase(STEP_INFO_LABEL, {
          ...stepInfo,
          perfiosScannedStatement: true,
          perfiosTransactionId
        })

        localStorage.setItem(BANK_STATEMENT_FETCH, JSON.stringify(statementFetch));

        updateState({
          perfiosScannedStatement: true,
          statementFetch
        });

      } else {

        Mixpanel.track(trackString.FAILED_INITIATING_SCANNED_STATEMENT_PROCESS);

        scannedFile.value = null;

        renderErrorGrowl(message);

        return;
      }
    }
  }
  catch (error) {

    Mixpanel.track(trackString.FACING_ISSUE_WHILE_SCANNED_STATEMENT_UPLOAD)

    return;

  }
  finally {
    updateState({ popLoader: false });
  }

}