export const inputFieldOptions = {
    NEW_USER: 'New Distributor',
    EXISTING_USER: "Existing Distributor"
}

export const languageStrings = {
    NOT_HAVING_GSTR_FOR_ELIGIBILITY: "Minimum 9 months GSTr is required to complete the assessment",
    HAVING_VALID_GSTR_FILING_MONTHS: "Got valid GSTR filing months",
    NOT_HAVING_UPDATED_ITR_FILING: "Minimum 2 year ITR file required to complete the assessment",
    HAVING_VALID_ITR_FILING: "Got valid ITR filing!"
}