import React from 'react';

import { languageStrings } from '../../../../../utils/enum/languageStrings';

const { instructionPayload } = require('../../../payload/index');

const { PLAY_STORE_ADOBE_SCANNER } = require('../../../../../utils/enum/index');

const {
    ONBOARDING: {
        BEFORE_START_ASSESSMENT,
        PLEASE_KEEP_DOCUMENTS_READY_BEFORE_START_ASSESSMENT,
        SUGGESTED_APP_FOR_SCANNING,
        ADOBE_PLAY_STORE_LINK,
        DIGITAL_OR_SCANNED_COPY,
        DOCUMENT,
        START_ONBOARDING
    }
} = languageStrings;


/**
 * @description Function for rendering the individual table row with label & instruction
 * @name renderTableData
 */
const renderInstructionInfo = () => instructionPayload.map((tableData, index) => {
    const { column1, column2 } = tableData
    return (
        <tr key={index}>
            <td>{column1}</td>
            <td>{column2}</td>
        </tr>
    )
})

const InstructionCard = (props) => {
    let { onStartAssessment } = props;
    return (
        <div>
            <h3>{BEFORE_START_ASSESSMENT}</h3>
            <p>{PLEASE_KEEP_DOCUMENTS_READY_BEFORE_START_ASSESSMENT}</p>
            <p><strong>{SUGGESTED_APP_FOR_SCANNING}</strong></p>
            <p>{ADOBE_PLAY_STORE_LINK}
                <a href={`${PLAY_STORE_ADOBE_SCANNER}`} target="_blank" rel="noopener noreferrer">
                    {`${PLAY_STORE_ADOBE_SCANNER}`}
                </a>
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th className="tableTitle">{DOCUMENT}</th>
                        <th className="tableTitle">{DIGITAL_OR_SCANNED_COPY}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderInstructionInfo()}
                </tbody>
            </table>
            <div className="startAssessmentButton">
                <div className="combutton startButton" onClick={() => onStartAssessment()}>{START_ONBOARDING}</div>
            </div>
        </div>
    )
}

export default InstructionCard;