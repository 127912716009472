import _ from 'lodash';

import moment from 'moment';

import { fileUpload } from '../../../Component/service/index';

import {
    validateIndividualGSTRDocuments, fetchMsmeParsedDocuments,
    verifyGstr, verifyGstrCaptcha, createMSME
} from '../../../../../utils/apiService';

import { utils } from '../../../../../utils/utils'

import { utilFunctions } from '../../../../../utils'

import { Mixpanel } from '../../../../../utils/mixPanel';

import { trackString } from '../../../../../utils/mixpanelTracks';

const { isValid } = require('../../../../../utils/validator/index');

const {
    getDataFromLocalStorage, storeDataFromDatabase,
    acceptValidFileType, getTaxPayerInfo, getOrganizationID,
    getUserPhone
} = utils;

const {
    updateDefaultFilingInfo,
    isMandatoryDocumentsVerified,
    getMandatoryDocuments, getGSTRFiles, isValidGSTRQuarterlyFiling,
} = utilFunctions;

const { languageStrings } = require('../../../../../utils/enum/languageStrings');

const {
    GSTR_RETURNS: {
        FACING_ISSUE_WHILE_VERIFYING_INDIVIDUAL_GSTR,
        FACING_ISSUE_WHILE_UPLOADING,
        VERIFIED,
        FACING_ISSUE_WHILE_GETTING_EXISTING_VERIFIED_DOCUMENTS,
        KINDLY_UPLOAD_PROPER_PDF_FILE,
        ENTER_CAPTCHA_CODE,
        SOMETHING_WENT_WRONG,
        GST_VERIFY_MSG,
        GST_VERIFY_ERROR
    }
} = languageStrings;

const { localStorageStrings: {
    STEP_INFO_LABEL, GSTR_RETURNS_LABEL, TAXPAYER_INFO
} } = require('../../../../../utils/enum/localStorageStrings')

const { fixedValues: {
    DOCUMENT_TYPE,
    MANDATORY_GSTR_COUNT,
    INDIVIDUAL_GSTR_FILING_PERIOD,
    QUARTERLY_GSTR_FILING_PERIOD,
    MINIMUM_BUSINESS_START_YEAR,
    quarterlyToMonthlyMapping,
    JAN,
    FEB,
    MAR,
    JAN_MARCH,
    monthlyToQuarterlyMapping,
    MANDATORY_DOCUMENT_FOR_EXISTING_BUSINESS,
    MONTHLY_FILING_GAP,
    QUARTERLY_FILING_GAP,
    DEFAULT_FILING_COUNT,
    MAX_FILING_RANGE
} } = require('../../../../../utils/enum/currency')

const { IS_QUARTERLY_FILING } = require('../../../../../utils/validator/validationLabel')

/**
 * @description Function returns validation result of individual GSTR file uploaded.
 * @name validateGSTRDocument
 * @param {Object} payload 
 * @returns {object}
 */
const validateGSTRDocument = async (payload) => {
    try {
        let { status = false, message } = await validateIndividualGSTRDocuments(payload);

        return { status, message }

    } catch (err) {
        return { status: false, message: FACING_ISSUE_WHILE_VERIFYING_INDIVIDUAL_GSTR }
    }
}

/**
 * @description Function perform uploading files on S3 and process uploaded document
 * @name onFileUpload
 * @param {Object} event
 * @param {Function} updateState
 * @param {Object} payload
 * @returns {Object}
 */
export const onFileUpload = async (event, updateState, payload) => {

    const fileName = event.target.name;

    const file = event.target.files[0];

    const {
        gstrNumber, filingMonth, fileUploadInfo,
        showSuccessGrowl, showErrorGrowl, truncateFileUpload,
        acceptType
    } = payload;

    const validFileFormat = await acceptValidFileType(event.target.files, acceptType);

    // if in case of invalid file format uploaded with double extension will be handled here.
    if (!validFileFormat) {
        truncateFileUpload(fileName)
        return showErrorGrowl(KINDLY_UPLOAD_PROPER_PDF_FILE)
    }

    updateState({ popLoader: true, loader: true });

    try {

        const response = await fileUpload(file);

        if (response) {

            let { status = false, message } = await validateGSTRDocument({
                gstrFileUrl: response,
                gstrNumber,
                filingMonth
            })

            let updatedFilingInfo = updateDefaultFilingInfo({
                fileUploadInfo,
                filingMonth,
                verifiedStatus: status,
                url: status ? response : '',
            })

            await updateState({ fileUploadInfo: updatedFilingInfo, popLoader: false, loader: false });

            if (status) {

                const gstrFileURLs = getGSTRFiles(updatedFilingInfo);

                await storeDataFromDatabase(GSTR_RETURNS_LABEL, gstrFileURLs);

                return showSuccessGrowl(`${VERIFIED} ${filingMonth}`)
            }

            truncateFileUpload(fileName)

            return showErrorGrowl(message)

        }

        truncateFileUpload(fileName)

        return showErrorGrowl(FACING_ISSUE_WHILE_UPLOADING)

    } catch (err) {

        Mixpanel.track(trackString.FACING_ISSUE_WHILE_VERIFYING_GSTR_DOCUMENTS)

        truncateFileUpload(fileName);

        return showErrorGrowl(FACING_ISSUE_WHILE_UPLOADING)

    } finally {
        updateState({ popLoader: false, loader: false });
    }
}

/**
 * @description Function updated stepInfo once save & proceeding
 * @name updateStepInfo
 * @param {Object} fileUploadInfo 
 */
export const updateStepInfo = async (fileUploadInfo, isAllGSTRDocumentsVerified) => {

    const result = getDataFromLocalStorage(STEP_INFO_LABEL);

    let mandatoryDocuments = getMandatoryDocuments(fileUploadInfo);

    let verificationStatus = isMandatoryDocumentsVerified(mandatoryDocuments);

    verificationStatus ?
        Mixpanel.track(trackString.VERIFIED_GSTR_DOCUMENTS_SUCCESSFULLY)
        : Mixpanel.track(trackString.PARTIALLY_SAVED_AND_PROCEEDED_GSTR_FILING)

    await storeDataFromDatabase(STEP_INFO_LABEL, {
        ...result,
        gstrReturnsForm: isAllGSTRDocumentsVerified
    })

}

/**
 * @description Function that concat filing month & year & return it.
 * @name getFormattedFilingPeriod
 * @param {String} submittedPeriod 
 * @param {String} submittedYear 
 * @returns {String}
 */
const getFormattedFilingPeriod = (submittedPeriod, submittedYear) => {
    return `${submittedPeriod} ${submittedYear}`
}

/**
 * @description Function that converts GSTR3b documents information to return custom array.
 * @name formattedGSTR3bDocumentsInfo
 * @param {Array}} listOfGSTR3bDocuments 
 * @returns {Array}
 */
const formattedGSTR3bDocumentsInfo = (listOfGSTR3bDocuments = []) => {

    const finalResult = [];

    listOfGSTR3bDocuments.forEach((item) => {

        const { fileURL = "", parsedResult = {} } = item;

        const { submittedYear = "", submittedPeriod = "" } = parsedResult;

        const filingPeriod = getFormattedFilingPeriod(submittedPeriod, submittedYear);

        fileURL && filingPeriod && finalResult.push({
            fileURL,
            filingPeriod
        })
    })

    return finalResult
}

/**
 * @description Function fetch verified GSTR3b documents of the distributor
 * @name getDocumentsVerifiedByDistributor
 * @returns {Array}
 */
export const getDocumentsVerifiedGSTR3bDistributor = async (payload) => {

    const { showErrorGrowl } = payload;

    try {
        const response = await fetchMsmeParsedDocuments({
            documentType: DOCUMENT_TYPE.GSTR_3B
        });

        const { status = false, result = [] } = response;

        if (status === false) {
            return showErrorGrowl(FACING_ISSUE_WHILE_GETTING_EXISTING_VERIFIED_DOCUMENTS)
        }

        return formattedGSTR3bDocumentsInfo(result);

    }
    catch (error) {

        return showErrorGrowl(FACING_ISSUE_WHILE_GETTING_EXISTING_VERIFIED_DOCUMENTS);

    }
}

/**
 * @description Function returns file input rendering payload with mandatory check & pre-filling uploaded documents
 * @name getFilingInfoWithMandatoryCheck
 * @param {Array} updatedFilePayload 
 * @returns {Array}
 */
 export const getFilingInfoWithMandatoryCheck = (updatedFilePayload) => {

    // from last filing we are adding mandatory check
    let reversedFilingInfo = [...updatedFilePayload].reverse();

    let updatedResult = [];

    let mandatoryDocumentsCount = MANDATORY_GSTR_COUNT;

    for (let individualInfo of reversedFilingInfo) {

        const { filingMonth } = individualInfo;

        const isQuarterlyFiling = isValidGSTRQuarterlyFiling(filingMonth);

        if (mandatoryDocumentsCount > 0) {

            updatedResult.push({ ...individualInfo, isMandatory: true });

            // in case of quarterly file reducing count with 3 else by 1
            if (isQuarterlyFiling) { mandatoryDocumentsCount = mandatoryDocumentsCount - QUARTERLY_GSTR_FILING_PERIOD }
            else { mandatoryDocumentsCount = mandatoryDocumentsCount - INDIVIDUAL_GSTR_FILING_PERIOD }
        }
        else {
            updatedResult.push({ ...individualInfo, isMandatory: false });
        }

    }
    return [...updatedResult].reverse();
}

/**
 * @description Function that get updated file input payload with existing GSTR3b documents verified status.
 * @name getUpdatedInputPayload
 * @param {Array} inputFilePayload 
 * @param {Array} verifiedDocuments 
 * @returns {Array}
 */
export const getUpdatedInputPayload = (inputFilePayload = [], verifiedDocuments = []) => {

    let updatedFilePayload = [];

    inputFilePayload.forEach((item) => {

        const { filingMonth, isHavingFilingOptions = false, filingOptions = [] } = item;

        const updatedFilingMonths = isHavingFilingOptions ? filingOptions.map((item) => item.value) : [filingMonth];

        let filterResponse = verifiedDocuments.filter((item) => updatedFilingMonths.includes(item.filingPeriod));

        if (!_.isEmpty(filterResponse)) {

            const { fileURL, filingPeriod } = filterResponse && filterResponse[0];

            updatedFilePayload.push({ ...item, value: fileURL, verifiedStatus: true, filingMonth: filingPeriod });

        }
        else { updatedFilePayload.push(item) }

    });

    return getFilingInfoWithMandatoryCheck(updatedFilePayload)
}

/**
 * @description Function that validated GSTIN and returns captcha for verification.
 * @name verifyGST
 * @param {Object} props
 * @returns {Object}
 */
 export const verifyGST = async (props) => {

    const { state, renderErrorGrowl, updateState, renderSuccessGrowl } = props;

    const { gstrNumber } = state;

    updateState({ popLoader: true });

    const payload = { gstin: gstrNumber }

    try {
        const result = await verifyGstr(payload);

        const status_code = result && result['status-code'];

        if (status_code === '101') {

            updateState({
                popLoader: false,
                enableCaptchaVerification: true,
                captchaCode: "",
                captchaImage: result.data
            });

            return renderSuccessGrowl(ENTER_CAPTCHA_CODE);
        } else {
            return renderErrorGrowl(SOMETHING_WENT_WRONG)
        }
    } catch (error) {
        return renderErrorGrowl(SOMETHING_WENT_WRONG)
    } finally {
        updateState({ popLoader: false })
    }
}

/**
 * @description Function that validated captcha for GSTIN verification
 * @name verifyCaptcha
 * @param {Object} props 
 * @returns {Object}
 */
export const verifyCaptcha = async (props) => {

    const { state, renderErrorGrowl, updateState, renderSuccessGrowl } = props;

    const { gstrNumber, captchaCode } = state;

    if (!captchaCode) return renderSuccessGrowl(ENTER_CAPTCHA_CODE);

    updateState({ loader: true });

    const payload = {
        gstin: gstrNumber,
        captchaCode: captchaCode
    }

    try {

        const result = await verifyGstrCaptcha(payload);

        const status_code = result && result['status-code'];

        if (status_code === '101') {

            renderSuccessGrowl(GST_VERIFY_MSG);

            const gstInfo = getTaxPayerInfo() || {};

            const { isExistingDistributor = false, verifiedOn = "" } = gstInfo || {};

            const isValidGSTRInfo = !_.isEmpty(result.data);

            const updatedGstrInfo = isValidGSTRInfo ? result.data : gstInfo;

            const updatedGSTInfo = {
                ...updatedGstrInfo, isExistingDistributor,
                verifiedOn: isValidGSTRInfo ? moment().format(`YYYY-MM-DD`) : verifiedOn
            }

            const msmeInfo = {
                phone: getUserPhone(),
                organizationId: getOrganizationID(),
                gstInfo: updatedGSTInfo
            }

            //  Updating distributor information with new GSTR Filing pull
            // await updateDistributorGSTRInfo({ gstInfo: updatedGstrInfo });
            await createMSME({ msmeInfo });

            storeDataFromDatabase(TAXPAYER_INFO, updatedGSTInfo);

            updateState({
                isGstVerify: true, gstForm: result.data,
                enableCaptchaVerification: false, captchaCode: "",
                captchaImage: "", isGSTINVerified: true,
                isGSTRFilingUpToDate: isValidGSTRInfo
            });

            //reloading window to auto populate GSTR file upload information
            isValidGSTRInfo && window.location.reload();

        } else {

            renderErrorGrowl(result && result.message);

            updateState({
                loader: false, isVisible: false,
                isGSTINVerified: false, isGSTRFilingUpToDate: false
            });
        }
    } catch (error) {

        renderErrorGrowl(GST_VERIFY_ERROR);

        updateState({ loader: false, isVisible: false, isGSTINVerified: false, isGSTRFilingUpToDate: false });
    }
}

/**
 * @description Function that returns GSTR filing mandatory filing period
 * @name getMandatoryDocumentRange
 * @param {Array} fileUploadInfo
 * @returns {String}
 */
export const getMandatoryDocumentRange = (fileUploadInfo = []) => {
    // filtering out mandatory documents
    const mandatoryDocuments = fileUploadInfo.filter(item => item.isMandatory)

    // extracting filing month period
    const updatedFilingPeriod = mandatoryDocuments.map(item => item.filingMonth)

    // getting mandatory filing count
    const totalMandatoryFilingCount = _.size(updatedFilingPeriod)

    // is having max filing count - extracting - filing range
    if (totalMandatoryFilingCount >= MAX_FILING_RANGE) {
        return `${updatedFilingPeriod[0]} - ${updatedFilingPeriod[totalMandatoryFilingCount - 1]}`
    }

    // in case of minimal filing range returning it
    return _.isEmpty(updatedFilingPeriod) ? '' : `${updatedFilingPeriod[0]}`
}

/**
 * @description Function to check business is more than a year
 * @name checkBusinessIsMoreThanAYear
 * @param {Object} param0
 * @returns {Boolean}
 */
export const checkBusinessIsMoreThanAYear = ({ dateOfIncorporation, registrationDate }) => {

    // date of incorporation should be greater than the business start date.
    // GST starts from 2017 so gst should be less than date of incorporation
    // let isValidBusinessStartDate = moment(registrationDate).isAfter(dateOfIncorporation, 'year')

    // And check the date of incorporation is more than a year or not then return true.
    const isMoreThanAYear = moment().diff(moment(registrationDate), 'years') >= MINIMUM_BUSINESS_START_YEAR;

    return isMoreThanAYear;
}

/**
 * @description Function to generate previous filing year.
 * @name getPreviousFillingYear
 * @param {String} filingYear 
 * @returns {String}
 */
export const getPreviousFillingYear = (filingYear) => {

    let [previousFY] = `${filingYear}`.split('-');

    let previousBeforeFy = moment(previousFY, 'YYYY').subtract(1, 'year').format('YYYY');

    let formattedPreviousFy = moment(previousFY, 'YYYY').format('YY');

    return `${previousBeforeFy}-${formattedPreviousFy}`;

}

/**
 * @description Function returns formatted result of filing year
 * @name getFormattedFillingYear
 * @param {String} filingYear 
 * @returns {String}
 */
export const getFormattedFillingYear = (filingYear) => {

    let [previousFY, currentFY] = `${filingYear}`.split('-');

    let formattedCurrentFY = moment(currentFY, 'YYYY').format('YY');

    return `${previousFY}-${formattedCurrentFY}`;

}

/**
 * @description Function to generate formatted filing payload for rendering & verification process
 * @name formatFilingInfo
 * @param {Array} filingMonths 
 * @returns {Array}
 */
export const formatFilingInfo = (filingMonths) => {
    return filingMonths.map((item) => {

        const { filingPeriod, filingYear, additionalLabel = '', type = '' } = item || {};

        return {
            filingMonth: filingPeriod,
            filingOptions: [],
            isHavingFilingOptions: false,
            isMandatory: false,
            label: `GSTr-3B ${filingPeriod}`,
            value: "",
            verifiedStatus: false,
            filingYear,
            additionalLabel,
            type
        }
    })
}

/**
 * @description Function to generate list of filing months based on filing month & filing year
 * used to generate previous 2 filing information with respective quarterly filing information
 * @param {String} filingMonth 
 * @param {String} filingYear 
 * @returns {Array}
 */
export const generateFilingMonths = (filingMonth, filingYear) => {

    let updatedFilingMonths = [];

    let updatedFilingYear = filingYear;

    // for 12 month GSTR , we need to add 2 more months
    for (let item = 1; item <= 2; item++) {

        let filingPeriod = moment(`${filingMonth}`, 'MMMM').subtract(`${item}`, 'month').format('MMMM');

        // if filing month is JAN || FEB || MAR consider previous FY
        if ([JAN, FEB, MAR, JAN_MARCH].includes(filingPeriod)) updatedFilingYear = getPreviousFillingYear(filingYear);

        let equivalentQuarterlyFilingPeriod = _.get(monthlyToQuarterlyMapping, filingPeriod);

        updatedFilingMonths.push(
            {
                filingPeriod: `${equivalentQuarterlyFilingPeriod} ${getFormattedFillingYear(updatedFilingYear)}`,
                filingYear: updatedFilingYear,
                additionalLabel: '(If quarterly file upload here)',
                type: 'Quarterly'
            },
            {
                filingPeriod: `${filingPeriod} ${getFormattedFillingYear(updatedFilingYear)}`,
                filingYear: updatedFilingYear,
                additionalLabel: '(If monthly file upload here)',
                type: 'Monthly'
            }
        )
    }

    return formatFilingInfo(_.uniqBy(updatedFilingMonths, 'filingPeriod'));
}

/**
 * @description Function to generate monthly filing information with filing month and filing year
 * @name generateMonthlyFilingPayload
 * @param {String} firstMonthFilling 
 * @param {String} filingYear 
 * @returns {Array}
 */
export const generateMonthlyFilingPayload = (firstMonthFilling, filingYear) => {

    const [filingMonth] = `${firstMonthFilling}`.split(' ');

    const updatedFilingPeriod = generateFilingMonths(filingMonth, filingYear);

    return [...updatedFilingPeriod].reverse();

}

/**
 * @description Function to generate quarterly filing information with filing month and filing year
 * @name getQuarterlyFilingMonths
 * @param {String} filingMonth 
 * @param {String} filingYear 
 * @returns {Array}
 */
export const getQuarterlyFilingMonths = (filingMonth, filingYear) => {

    const [filingPeriod] = `${filingMonth}`.split(' ');

    const previousFilingMonth = _.get(quarterlyToMonthlyMapping, filingPeriod);

    return generateMonthlyFilingPayload(previousFilingMonth, filingYear);

}

/**
 * @description Function to generate list previous 2 month filing information for collecting latest
 * 12 month filing for the Business more than a year
 * @name generateListOfPreviousMonthlyFiling
 * @param {Array} updatedFilingMonths 
 * @returns {Array}
 */
export const generateListOfPreviousMonthlyFiling = (updatedFilingMonths) => {

    const firstMonthFilling = _.get(updatedFilingMonths, '[0]') || {};

    const { filingMonth = "" } = firstMonthFilling || {};

    let filingYear = "";

    // Checking if the current month is feb and march than taking financial year from current financial year
    if (filingMonth?.split(' ')[0] === "February" || filingMonth?.split(' ')[0] === "March") {
        const lastMonthFilling = _.last(updatedFilingMonths) || {};
        filingYear = lastMonthFilling?.filingYear;
    }
    else {
        filingYear = firstMonthFilling?.filingYear;
    }

    const isQuarterlyFilling = isValid(IS_QUARTERLY_FILING, filingMonth);

    return isQuarterlyFilling ? getQuarterlyFilingMonths(filingMonth, filingYear) : generateMonthlyFilingPayload(filingMonth, filingYear);
}

/**
 * @description Function that returns count of GSTR-3b documents verified
 * @name getGSTR3bDocumentsCount
 * @param {Array} documentsCount 
 * @returns {Number}
 */
export const getGSTR3bDocumentsCount = (documentsCount) => {

    let count = DEFAULT_FILING_COUNT;

    documentsCount.forEach((item) => {
        if (isValid(IS_QUARTERLY_FILING, item.filingMonth))
            count = count + QUARTERLY_FILING_GAP;
        else
            count = count + MONTHLY_FILING_GAP;
    })

    return count;
}

/**
 * @description Function that check is latest 12 month filing is verified for existing business
 * for assessment completion.
 * @name checkLatestFilingVerified
 * @param {Array} filingInfo 
 * @returns {Boolean}
 */
export const checkLatestFilingVerified = (filingInfo, isAllMandatoryDocumentsVerified) => {

    let verifiedDocumentsList = filingInfo.filter((item) => {

        let verifiedStatus = _.get(item, 'verifiedStatus') || false;

        return verifiedStatus === true;

    })

    let verifiedMonthlyDocumentsCount = getGSTR3bDocumentsCount(verifiedDocumentsList);

    return isAllMandatoryDocumentsVerified && verifiedMonthlyDocumentsCount >= MANDATORY_DOCUMENT_FOR_EXISTING_BUSINESS

}