const PROD_CONFIG = {
    MIXPANEL_TOKEN: "5c224a46bc89acd1dcacecbadaf50fc3",
    INSPECT_LET_TOKEN: 1823076248,
    KYC_SERVICES: "https://api.actyv.com/ekyc",
    DOC_SERVICES: `https://actyv-ocr.prod.actyv.com`,
    NIVEA_SERVICES: `https://actyv-nivea.prod.actyv.com/v1`,
    PERFIOS_SERVICES: `https://actyv-fin-analysis.prod.actyv.com/v2`,
    PERFIOS_SCANNED_SERVICES: `https://actyv-fin-analysis.prod.actyv.com/scanned/v1`,
    ACCOUNTING_SERVICE: `https://actyv-accounting-service.prod.actyv.com/v1`,
    ENVIRONMENT: 'PROD',
}

export const config = PROD_CONFIG

