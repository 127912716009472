import React from 'react';

import { fetchBankStatementAnalysis, onFileChange } from '../service/index';

import VerifyIcon from '../../../../../components/Indicators/VerifyIcon';

import { utilFunctions } from '../../../../../utils';

import { utils } from '../../../../../utils/utils';

import _ from 'lodash';

const { getDataFromLocalStorage } = utils;

const { getFormattedDate, formatDateBetweenRange } = utilFunctions;

const {
  languageStrings: {
    BANK_STATEMENT_VERIFICATION: {
      LOG_IN_NET_BANKING,
      DIGITAL_PDF_UPLOAD,
      SCANNED_STATEMENT_WARNING_MESSAGE,
      NOTE_FOR_SCANNED_STATEMENT_UPLOAD
    }
  }
} = require('../../../../../utils/enum/languageStrings')

const {
  localStorageStrings: {
    STEP_INFO_LABEL
  }
} = require('../../../../../utils/enum/localStorageStrings')

const { fixedValues: {
  BANK_STATEMENT_PROCESSING_TYPE,
  MAXIMUM_BANK_STATEMENT_MONTH,
  PERFIOS_STATEMENT_PROCESSING_TYPE
}
} = require('../../../../../utils/enum/currency')

const {
  NET_BANKING,
  DIGITAL_PDF,
  SCANNED_PDF
} = PERFIOS_STATEMENT_PROCESSING_TYPE;

const instructionInformation = {

  netBankingInstruction: {
    instructionTitle: 'Instructions for Net Banking:',
    instructionPointers: [
      `Click on the above button to proceed with Net Banking Login.`,
      `You will be taken to a new page. Select your bank from the list.`,
      `Enter your Netbanking username and password.`,
      `If you cannot find your bank or if you do not have Netbanking login, click on cancel.`
    ],
    bankStatementType: NET_BANKING
  },

  digitalPDFInstruction: {
    instructionTitle: 'Instructions for Digital statement upload:',
    instructionPointers: [
      `Upload digital pdf statement downloaded from net banking portal.`,
      `In the case of missing transaction details, try to upload the complete statement.`,
      `If you’re facing issues uploading the statement, kindly proceed to the next step by clicking on "Return to production" button in the right top corner in Digital-PDF upload screen.`
    ],
    bankStatementType: DIGITAL_PDF
  },

  scannedPDFInstruction: {
    instructionTitle: 'Instructions for Scanned statement upload:',
    instructionPointers: [
      `Upload file in PDF format.`,
      `The maximum file size should be 10MB.`,
      `This process will take more time to complete the assessment.`
    ],
    bankStatementType: SCANNED_PDF
  }

}

const instructionsForNetBanking = (props) => {
  let { payload, updateState, renderErrorGrowl } = props;
  const { netBankingInstruction } = instructionInformation;
  return (
    <div className="flex inputFileContainer marginTopForm">
      <div className="netBankingContainer">
        <div className="displayCol">
          <div className={"combutton"} onClick={() => {
            fetchBankStatementAnalysis({ ...payload, fetchType: 'netbankingFetch', updateState, renderErrorGrowl })
          }} >{LOG_IN_NET_BANKING}</div> <br />
        </div>
        {individualInstructionCard(netBankingInstruction)}
      </div>
      <img className="inputLayout" src={require("../../../../assets/images/netBanking.png")} alt="warning-icon" height="420px" width="50%" />
    </div>
  )
}

const individualInstructionCard = (payload) => {

  const { instructionTitle = "", instructionPointers = [], bankStatementType } = payload;

  const style = (bankStatementType !== "Net Banking") ? "instructionForm" : "instructionForm textLeft";

  return <div className={style}>
    {(bankStatementType === "Scanned PDF") &&
      <div className="adharDetailsRow">
        <img src={require("../../../../assets/images/ic_warning.svg")} alt="warning-icon" />
        <p><strong>{SCANNED_STATEMENT_WARNING_MESSAGE}</strong></p>
      </div>}
    <label><strong>{instructionTitle}</strong></label>
    <ol> {instructionPointers.map((item) => <li>{item}</li>)}</ol>
  </div>

}

const RenderInstructionCard = (props) => {

  const { bankStatementType, payload } = props;

  let instructionElement;

  const { digitalPDFInstruction, scannedPDFInstruction } = instructionInformation;

  switch (bankStatementType) {

    case NET_BANKING: instructionElement = instructionsForNetBanking(payload)
      break;

    case DIGITAL_PDF: instructionElement = individualInstructionCard(digitalPDFInstruction)
      break;

    case SCANNED_PDF: instructionElement = individualInstructionCard(scannedPDFInstruction)
      break;

    default: instructionElement = null;
  }

  return instructionElement;
}

const renderPerfiosRedirectButton = (props) => {

  const { buttonLabel, payload } = props;

  return <div className="displayCol">
    <div className={"combutton"}
      onClick={() => fetchBankStatementAnalysis(payload)}>
      {buttonLabel}
    </div>
  </div>
}

const renderUploadScannedStatementFileInput = (props) => {

  const stepInfo = getDataFromLocalStorage(STEP_INFO_LABEL) || {};

  const { bankData = {} } = stepInfo;

  let { state, renderErrorGrowl, updateState, scannedFile, renderSuccessGrowl } = props;

  return (!_.isEmpty(bankData)) && <div className="displayCol">
    <input
      className={`paddingtopFile msmeInput fileUploadWidth filepadding`}
      type={'file'}
      onChange={(event) => onFileChange(event, {
        state,
        renderErrorGrowl,
        updateState,
        scannedFile,
        renderSuccessGrowl
      })}
      accept="application/pdf"
      ref={reference => scannedFile = reference}
    />
    <span className='flexlabel textLeft'>{NOTE_FOR_SCANNED_STATEMENT_UPLOAD}</span>
  </div>
}

const renderSuccessScannedStatementUpload = () => {
  return <>
    <label>{'Successfully Uploaded'} &nbsp;</label>
    <VerifyIcon isVerify={true} />
  </>
}

export const renderPerfiosFetchOptions = (props) => {

  const { state, updateState, renderErrorGrowl } = props;

  const { fetchType, perfiosScannedStatement } = state;

  let payload = { updateState, renderErrorGrowl }

  let button;

  switch (BANK_STATEMENT_PROCESSING_TYPE[fetchType]) {

    case DIGITAL_PDF:
      button = renderPerfiosRedirectButton({
        buttonLabel: DIGITAL_PDF_UPLOAD, payload: {
          fetchType: 'statement',
          ...payload
        }
      })
      break;

    case SCANNED_PDF:
      button = !perfiosScannedStatement ?
        renderUploadScannedStatementFileInput(props) :
        renderSuccessScannedStatementUpload()
      break;

    default: button = null;
  }

  return button;
}


const getStartAndEndDate = () => {

  let { yearMonthFrom, yearMonthTo } = formatDateBetweenRange();

  return {
    yearMonthFrom: getFormattedDate({
      date: yearMonthFrom,
      currentDateFormat: "YYYY-MM",
      formatString: "MMM-YYYY"
    }),
    yearMonthTo: getFormattedDate({
      date: yearMonthTo,
      currentDateFormat: "YYYY-MM",
      formatString: "MMM-YYYY"
    })
  }

}

const getUpdatedHeaderLabel = (payload) => {

  const { yearMonthFrom, yearMonthTo, ODAccount } = payload;

  return ODAccount === "No" ?

    `Upload Latest ${MAXIMUM_BANK_STATEMENT_MONTH} Months Bank Statement from ${yearMonthFrom} to ${yearMonthTo}`
    :
    `Upload Latest ${MAXIMUM_BANK_STATEMENT_MONTH} Months OD Bank Statement  from ${yearMonthFrom} to ${yearMonthTo}`

}

const InstructionHeader = (props) => {
  const { instructionHeaderLabel } = props;
  return <div className="halfWidth verticalText">
    <label className='flexlabel textLeft'>{instructionHeaderLabel}<div className="mandatory">*</div></label>
  </div>
}

const InstructionCard = (props) => {

  const { state, bankStatementType } = props;

  const { ODaccount = "", } = state;

  const stepInfo = getDataFromLocalStorage(STEP_INFO_LABEL) || {};

  const { bankData = {} } = stepInfo;

  const showUploadOption = (bankStatementType === 'Scanned PDF' && !_.isEmpty(bankData)) ||
    (bankStatementType !== 'Scanned PDF');

  let { yearMonthFrom, yearMonthTo } = getStartAndEndDate();

  let instructionHeaderLabel = getUpdatedHeaderLabel({ ODAccount: ODaccount, yearMonthFrom, yearMonthTo, bankStatementType })

  return (
    <>

      <RenderInstructionCard payload={props} bankStatementType={bankStatementType} />

      {bankStatementType !== "Net Banking" &&
        <div className="flex inputFileContainer marginTopForm">

          {showUploadOption && <InstructionHeader instructionHeaderLabel={instructionHeaderLabel} />}

          {renderPerfiosFetchOptions(props)}

        </div>}

    </>
  )
}

export default InstructionCard;
