import { fileUpload } from '../../../Component/service/index';

import { validateIndividualITRDocuments } from '../../../../../utils/apiService';

import { utils } from '../../../../../utils/utils';

import { utilFunctions } from '../../../../../utils';

import { Mixpanel } from '../../../../../utils/mixPanel';

import { trackString } from '../../../../../utils/mixpanelTracks';

const { getDataFromLocalStorage, storeDataFromDatabase, acceptValidFileType } = utils;

const { updateDefaultITRFilingInfo, getMandatoryDocuments, isMandatoryDocumentsVerified } = utilFunctions;

const { languageStrings } = require('../../../../../utils/enum/languageStrings');

const {
    ITR_RETURNS: {
        FACING_ISSUE_WHILE_VERIFYING_INDIVIDUAL_ITR,
        FACING_ISSUE_WHILE_UPLOADING,
        KINDLY_UPLOAD_PROPER_PDF_FILE
    }
} = languageStrings;

const { localStorageStrings : {
    STEP_INFO_LABEL
} } = require('../../../../../utils/enum/localStorageStrings')

/**
 * @description Function returns validation result of individual ITR file uploaded.
 * @name validateITRDocument
 * @param {Object} payload 
 * @returns { object}
 */
const validateITRDocument = async (payload) => {
    try {
        let { status = false, message, result } = await validateIndividualITRDocuments(payload);

        return { status, message, result }

    } catch (err) {
        return { status: false, message: FACING_ISSUE_WHILE_VERIFYING_INDIVIDUAL_ITR }
    }
}

/**
 * @description Function perform uploading files on S3 and process uploaded document
 * @name onFileUpload
 * @param {Object} event
 * @param {Function} updateState
 * @param {Object} payload
 * @returns {Object}
 */
export const onFileUpload = async (event, updateState, payload) => {

    const fileName = event.target.name;

    const file = event.target.files[0];

    const {
        filedYear, fileUploadInfo, businessPan,
        showSuccessGrowl, showErrorGrowl, truncateFileUpload,
        acceptType
    } = payload;

    const validFileFormat = await acceptValidFileType(event.target.files, acceptType);

    // if in case of invalid file format uploaded with double extension will be handled here.
    if (!validFileFormat) {
        truncateFileUpload(fileName)
        return showErrorGrowl(KINDLY_UPLOAD_PROPER_PDF_FILE)
    }

    updateState({ popLoader: true });

    try {

        const response = await fileUpload(file);

        if (response) {

            let { status = false, message, result = [] } = await validateITRDocument({
                file: response,
                panNumber: businessPan,
                filedAssessmentYear: filedYear
            })

            let updatedFilingInfo = updateDefaultITRFilingInfo({
                fileUploadInfo,
                filedYear,
                verifiedStatus: status,
                url: status ? response : '',
                parsedInfo: result
            })

            await updateState({ fileUploadInfo: updatedFilingInfo, popLoader: false, loader: false });

            if (status) return showSuccessGrowl(`${message} ${filedYear}`)

            truncateFileUpload(fileName);

            return showErrorGrowl(message)

        }

        truncateFileUpload(fileName);

        return showErrorGrowl(FACING_ISSUE_WHILE_UPLOADING)

    } catch (err) {

        truncateFileUpload(fileName);

        Mixpanel.track(trackString.FACING_ISSUE_WHILE_VERIFYING_ITR_DOCUMENTS)

        return showErrorGrowl(FACING_ISSUE_WHILE_UPLOADING)

    } finally {
        updateState({ popLoader: false, loader: false });
    }
}

/**
 * @description Function updated stepInfo once save & proceeding
 * @name updateStepInfo
 * @param {Object} fileUploadInfo 
 */
export const updateStepInfo = async (fileUploadInfo) => {

    const result = getDataFromLocalStorage(STEP_INFO_LABEL);

    let mandatoryDocuments = getMandatoryDocuments(fileUploadInfo);

    let verificationStatus = isMandatoryDocumentsVerified(mandatoryDocuments);

    verificationStatus ?
        Mixpanel.track(trackString.VERIFIED_ITR_DOCUMENTS_SUCCESSFULLY)
        : Mixpanel.track(trackString.PARTIALLY_SAVED_AND_PROCEEDED_ITR_FILING)

    await storeDataFromDatabase(STEP_INFO_LABEL, {
        ...result,
        itrReturnsForm: verificationStatus
    })

}