import { createMSME, getSignedURL, uploads3Files, createCrifReport } from '../../../../utils/apiService';

import { utils } from '../../../../utils/utils';

const { getUserPhone , getOrganizationID , getUserData , storeUserData, getItemByName} = utils;

const MARCH_END = '-03-31';

const _ = require('lodash');

const moment = require('moment');

const ONE_STEP = 1;

export const skipSaveProceed = (payload) => {
  const {stepCompleted, props} = payload;
  if(stepCompleted){
    props.handleNext();
    return true;
  } return false;
}

const getActiveStepIndex = () => {
  return getItemByName('activeStep') ? Number(getItemByName('activeStep')) : 0;
}

const checkIfCrifStepAndGetStepIndex = () => {
  const crifStep = localStorage.getItem('crifStep');
  const activeStep = getActiveStepIndex();
  return (crifStep === 'true') ? (activeStep - ONE_STEP) : activeStep;
}

export const updateMsme = async (payload) => {

  const { formInfo, updateState , props} = payload;

  const msmePayload = {
    phone: getUserPhone(),
    organizationId: getOrganizationID(),
    onboardingStep: checkIfCrifStepAndGetStepIndex() + ONE_STEP
  }
  const msmeInfo = { ...msmePayload, ...formInfo };

  if (formInfo && formInfo.onBoarding) updateState({ loader: "show" }) 
  else updateState({ loader: true });

  try {
    
    const result = await createMSME({ msmeInfo });

    if (result && result.status === 'SUCCESS') {
      updateState({showGrowl: true, growlStatus: 'success', growlMsg: 'Updated Successfully' , loader: false});
      setTimeout(() => {props.handleNext();}, 500);
      if (formInfo && formInfo.onBoarding) {
        const distributorDetails = getUserData();
        distributorDetails.onboardingStatus = formInfo.onBoarding;
        storeUserData(distributorDetails);
        window.location.href = `${window.location.origin}/dashboard`;
      }
    }
  } catch (error) {
    updateState({loader: false  ,showGrowl: true, growlStatus: 'error', growlMsg: 'There is an issue in updating your msme details. Please try again after some time'});
  }
}

export const updateCrifReport = async (payload) => {

  const { updateState, panNo } = payload;

  const crifPayload = {
    phone: getUserPhone(),
    organizationId: getOrganizationID(),
    panNo
  }

  try {
    updateState({ loader: true });

    const result = await createCrifReport(crifPayload);

    if (result && result.success === true) {
      if (result.questionnaire) {
        localStorage.setItem("crifStep", true);
        localStorage.setItem("crifQuestion",result.question);
        localStorage.setItem("crifOptionList", JSON.stringify(result.optionsList));
        localStorage.setItem("buttonbehaviour", result.buttonbehaviour);
      }
      else{
        updateState({showGrowl: true, growlStatus: 'success', growlMsg: 'Security authentication successful' , loader: false});
        localStorage.setItem("crifStep", false);
      }
      return true;
    }
  } catch (error) {
    updateState({loader: false  ,showGrowl: true, growlStatus: 'error', growlMsg: 'Security authentication failed due to some technical issue, try again later!'});
    return false;
  } finally{
    updateState({loader: false})
  }
}

export const fileUpload = async (file) => {

  try {
    const response = await getSignedURL(file);

    if (response.result && response.result.signedURL) {

      const result = response.result;

      const statusCode = await uploads3Files(result.signedURL, file);

      if (statusCode === 200) return result.fileURL;
    }

    return false;

  } catch (error) {
    return false;
  }
}


/**
 * @description Function for returning current and previous year...
 * @name getCurrentAndPreviousAssessmentYear
 * @returns {Object}
 */
const getCurrentAndPreviousAssessmentYear = (todayDate = new Date()) => {
  if (_.isDate(todayDate)) {
    // var todayDate = new Date('2018-01-05'); // for passing custom date and testing UI
    var currentYear = todayDate.getFullYear(); // extracting year from today's date
    let nextYear = currentYear + 1;
    let startDate = `${currentYear}${MARCH_END}`;  // March end of current year
    let endDate = `${nextYear}${MARCH_END}`; // March end of next year
    let result = moment(todayDate).isBetween(startDate, endDate); // checking whether current date is between start and end date
    // if result success returning current year and next year
    if (result) {
      return {
        currentYear,
        nextYear
      }
    }
    // otherwise returing last year and current year
    else
      return {
        currentYear: currentYear - 1,
        nextYear: nextYear - 1
      }
  }
  return { currentYear: 0, nextYear: 0 }
}


/**
 * @description Function return common message pattern based on the current and next year...
 * @name getITRInstructions
 * @returns {String}
 */
export const getITRInstructions = () => {
  let year = getCurrentAndPreviousAssessmentYear();
  let { currentYear, nextYear } = year;
  return `Please provide correct latest 2 years ITR files:\n
If latest ITR filed, then please upload = ${currentYear}-${nextYear} AND ${currentYear - 1}-${currentYear}\n
If latest ITR not filed, then please upload = ${currentYear - 2}-${currentYear - 1} and ${currentYear - 1}-${currentYear}`
}

