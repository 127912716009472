import React, { Component } from 'react';

import { withRouter } from "react-router";

import { utils } from '../../../../utils/utils';

import { utilFunctions } from '../../../../utils';

import { generateODPayload } from '../FormPayload/index';

import Loader from '../../../../utils/loader';

import { updateMsme, skipSaveProceed } from '../../Component/service/index';

import Snackbar from '../../../../utils/alert';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import { isValid } from '../Validation';

import {
    fetchUniqueKey, fetchBankDetails, fetchBankNames
} from './service';

import Modal from 'react-awesome-modal';

import _ from 'lodash';

import BankNameSelect from '../../../../components/AutocompleteForm'

import { isValidBankStatement } from './service/utils';

import InstructionCard from './views';

const { getDataFromLocalStorage, storeDataFromDatabase, getItemByName } = utils;

const { isValidAccountInfo, validateBankDataForOdLimit } = utilFunctions;

const ODLIMIT = `ODLimit`;

const SCANNED_STATEMENT_FETCH_TYPE = 2;

const {
    languageStrings: {
        BANK_STATEMENT_VERIFICATION: {
            SAVE_AND_PROCEED, BANK_DETAILS, PROCEED, ACCOUNT_TYPE, BANK_SUMMARY_INFORMATION,
            ISSUE_IN_FETCHING_BANK_NAMES, SELECT_THE_BANK_NAME, PLEASE_SELECT_ACCOUNT_TYPE, PLEASE_ENTER_VALID_OD_CC_LIMIT,
            BANK_STATEMENT_PROCESSING_IN_PROGRESS, PROVIDE_VALID_OD_CC_LIMIT, PROVIDE_VALID_OD_CC_STATEMENT, PLEASE_SELECT_VALID_BANK_NAME
        }
    }
} = require('../../../../utils/enum/languageStrings');

const {
    localStorageStrings: {
        BANK_STATEMENT_FETCH,
        OD_LIMIT,
        OD_ACCOUNT,
        BANK_DATA_FETCH_TYPE,
        STEP_INFO_LABEL,
        BANK_NAMES
    }, localStorageStrings
} = require('../../../../utils/enum/localStorageStrings');

class BankForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientTxId: "",
            statementFetch: getDataFromLocalStorage(BANK_STATEMENT_FETCH),
            ODLimit: getDataFromLocalStorage(OD_LIMIT),
            ODaccount: getDataFromLocalStorage(OD_ACCOUNT),
            loader: false,
            fetchType: getDataFromLocalStorage(BANK_DATA_FETCH_TYPE),
            visible: false,
            bankNameFetched: false,
            popLoader: false,
            perfiosScannedStatement: getDataFromLocalStorage(STEP_INFO_LABEL) && getDataFromLocalStorage(STEP_INFO_LABEL).perfiosScannedStatement,
            isVerifiedExceptStatement: getDataFromLocalStorage(STEP_INFO_LABEL) && getDataFromLocalStorage(STEP_INFO_LABEL).isVerifiedExceptStatement,
            bankData: getDataFromLocalStorage(STEP_INFO_LABEL) && getDataFromLocalStorage(STEP_INFO_LABEL).bankData
        }
        Mixpanel.track(trackString.BANK_DETAILS_FORM_PAGE);
    }

    scannedFile = ''

    bankDataFetchTypes = ['Net Banking', 'Digital PDF', 'Scanned PDF'];

    validateODDetails = () => {
        const { ODaccount, ODLimit } = this.state;
        const isValidODLimit = isValid(ODLIMIT, ODLimit);
        return isValidAccountInfo(ODaccount, isValidODLimit)
    }

    getBankNames = async (institutionData) => {
        if (!institutionData) {
            this.setState({ loader: true })
            const { success = false, result = '' } = await fetchBankNames({
                statementType: "STATEMENT"
            });
            const institutions = result && result.institutions && result.institutions.institution;
            if (success && !_.isEmpty(institutions)) {
                localStorage.setItem(BANK_NAMES, JSON.stringify(institutions));
                this.setState({ bankNameFetched: true, loader: false })
            } else {
                this.setState({ bankNameFetched: false, loader: false })
                this.renderErrorGrowl(ISSUE_IN_FETCHING_BANK_NAMES);
            }
        }
    }

    componentDidMount = async () => {
        const { ODaccount = "", perfiosScannedStatement = false } = this.state;
        const institutionData = JSON.parse(localStorage.getItem(BANK_NAMES));
        this.getBankNames(institutionData);

        let redirectedURL = window.location.pathname.split("/");
        redirectedURL = redirectedURL.filter(Boolean);

        // avoid hitting fetch bank details in case of unnecessary reload occurs.
        if (redirectedURL.length >= 3) {
            // In case of both positive & negative cases
            // gets handled by calling the fetchBankDetails again by passing unique client id.
            // get status as true / false for the last transaction that is made.

            this.setState({ clientTxId: fetchUniqueKey() });

            if (!isValidBankStatement(this.state) && ODaccount) {

                fetchBankDetails(fetchUniqueKey(), this);
            }
        }

        !isValidBankStatement(this.state) && perfiosScannedStatement && this.renderSuccessGrowl(BANK_STATEMENT_PROCESSING_IN_PROGRESS);
    }

    getStepInfo = () => {
        const result = JSON.parse(localStorage.getItem(STEP_INFO_LABEL));
        const isValidStatement = isValidBankStatement(this.state);
        const stepInfo = {
            ...result,
            bankVerificationForm: isValidStatement,
            isVerifiedExceptStatement: true
        }
        localStorage.setItem(STEP_INFO_LABEL, JSON.stringify(stepInfo))
        return stepInfo;
    }

    updateBankDetails = async () => {

        // close the growl if tried to save and proceed, this removes and shows new growl
        this.handleClose()
        
        const { fetchType } = this.state;

        const stepInfo = JSON.parse(getItemByName(STEP_INFO_LABEL));

        const { bankVerificationForm = false, bankData = {} } = stepInfo;

        const { statementFetch, ODaccount, ODLimit } = this.state;

        if (!ODaccount) return this.renderErrorGrowl(PLEASE_SELECT_ACCOUNT_TYPE);

        if (!this.validateODDetails()) return this.renderErrorGrowl(PLEASE_ENTER_VALID_OD_CC_LIMIT);

        if (skipSaveProceed({ stepCompleted: bankVerificationForm, props: this.props })) return;

        if ((this.bankDataFetchTypes[fetchType] === 'Scanned PDF') && _.isEmpty(bankData)) return this.renderErrorGrowl(PLEASE_SELECT_VALID_BANK_NAME);

        // validating statement for OD/CC limits
        // OD/CC statements should have -ve avg balance
        // savings statement should have +ve avg balance
        if (isValidBankStatement(this.state) && !this.verifyBankODLimitsFromStatement(this)) return;

        const stepInformation = this.getStepInfo();

        const formInfo = {
            bankStatement: statementFetch,
            odDetails: {
                ODaccount,
                ODLimit
            },
            stepInfo: stepInformation
        }

        await updateMsme({ formInfo, props: this.props, updateState: this.updateState });

    }

    updateState = (data) => { this.setState(data); }

    renderTitle = () => {
        return <div className="textcenter">
            <p className="subtitle formtitleMargin">{BANK_DETAILS}</p>
        </div>
    }

    renderErrorGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
    }

    renderSuccessGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
    }

    handleClose = () => { this.setState({ showGrowl: false }); };

    renderRadioButton = (option, name, label) => {
        const stepInfo = JSON.parse(localStorage.getItem(STEP_INFO_LABEL));
        return (
            <div className="accountTypeContainer" key={option}>
                <input className="checkboxInput" type="radio" name={name} value={option} onClick={this.onValueChangeRadio} checked={option === this.state[name]}
                    disabled={stepInfo.bankVerificationForm} />
                <label className='flexlabel'>{label}</label>
            </div>
        )
    }

    renderODAccount = () => {
        const options = ['Yes', 'No'];
        const fieldName = ['Overdraft / Cash Credit Account', 'Savings / Current Account']
        const disabled = (this.state.isVerifiedExceptStatement);
        return <div className='BusinessFormField'>
            <label className='flexlabel'>{ACCOUNT_TYPE}<div className="mandatory">*</div></label>
            <div className="flex halfWidth columnRadio">
                {options.map((field, index) => this.renderRadioButton(field, 'ODaccount', fieldName[index], disabled))}
            </div>
        </div>
    }

    renderSingleBox = (inputFields) => {
        const stepInfo = JSON.parse(localStorage.getItem(STEP_INFO_LABEL));
        const { type, name, onChange, placeholder, value } = inputFields;
        return <input className={`msmeInput filepadding`} placeholder={placeholder} type={type} name={name} value={value} onChange={onChange} defaultValue=""
            disabled={stepInfo.bankVerificationForm} />
    }

    renderInputFields = (inputFields) => {
        const { label } = inputFields;
        return <div className='BusinessFormField'>
            <label className='flexlabel textLeft'>{label} <div className="mandatory">*</div></label>
            <div className="halfWidth">
                {this.renderSingleBox(inputFields)}
            </div>
        </div>
    }

    renderODDetails = () => {

        const ODDetails = generateODPayload({ state: this.state, onValueChange: this.onValueChange });

        return ODDetails.map(field => this.renderInputFields(field))
    }

    onValueChange = event => {

        const { name, value } = event.target;

        this.setState({ [name]: value });

        storeDataFromDatabase(name, value);
    }

    onValueChangeRadio = event => {
        const { name, value } = event.target;

        this.setState({ [name]: value, clientTxId: "", ODLimit: "" });

        storeDataFromDatabase(name, value);

        // on switching between account type removing od limit from local storage
        (name === 'ODaccount' && storeDataFromDatabase(OD_LIMIT, ""));

        storeDataFromDatabase(BANK_STATEMENT_FETCH, [])

    }

    handleProceed = () => {
        const { proceedConfirmation, fetchType } = this.state;
        if (proceedConfirmation === 'Yes' && fetchType < SCANNED_STATEMENT_FETCH_TYPE) {
            // removing the token & status from URL
            this.props.history.push('/dashboard')
            this.setState({ fetchType: fetchType + 1, visible: false });
            localStorage.setItem(BANK_DATA_FETCH_TYPE, fetchType + 1);
        } else {
            // removing the token & status from URL
            this.props.history.push('/dashboard')
            this.setState({ visible: false });
        }
    }

    renderModal = () => {
        const { visible } = this.state;
        return (
            <Modal visible={visible} effect="fadeInUp">
                <div className="padding50">
                    {this.renderConfirmationModal()}
                    <div className="textcenter">
                        <p className={"combutton"}
                            onClick={this.handleProceed}>{PROCEED}</p>
                    </div>
                </div>
            </Modal >
        )
    }

    renderConfirmationModal = () => {
        const { fetchType } = this.state;
        const options = ['Yes', 'No'];
        return (
            <>
                <label className='flexlabel'>{`Do you want to proceed with ${this.bankDataFetchTypes[fetchType + 1]} option ?`}<div className="mandatory">*</div></label>
                <div className="flex halfWidth columnRadio">
                    {options.map((field, index) => this.renderRadioButton(field, 'proceedConfirmation', options[index]))}
                </div>
            </>
        )
    }

    renderPopLoader = () => {
        return <Modal visible={this.state.popLoader} effect="fadeInUp">
            <div className="padding50">
                <div className="textcenter">
                    <Loader />
                </div>
            </div>
        </Modal>
    }

    renderBankNames = () => {
        const { isVerifiedExceptStatement = false } = this.state;
        return <div className='BusinessFormField'>
            <label className='flexlabel'>{SELECT_THE_BANK_NAME}<div className="mandatory">*</div></label>
            <div className="flex halfWidth">
                <BankNameSelect context={this} isVerifiedExceptStatement={isVerifiedExceptStatement} />
            </div>
        </div>
    }

    /**
     * Function for validating type of statement uploaded against account type selected
     * @returns {boolean}
     */
    verifyBankODLimitsFromStatement = () => {
        const result = validateBankDataForOdLimit(this.state);
        const { valid = false, removeBankStatementData = false } = result;
        if (!valid) {
            if (removeBankStatementData) {
                Mixpanel.track(trackString.UPLOADED_SAVINGS_STATEMENTS_FOR_OD_ACCOUNT_TYPE);
                localStorage.removeItem(localStorageStrings.BANK_STATEMENT_FETCH);
                this.renderErrorGrowl(PROVIDE_VALID_OD_CC_STATEMENT);
                this.setState({ statementFetch: {} });
            } else {
                Mixpanel.track(trackString.UPLOADED_OD_STATEMENT_FOR_SAVINGS_ACCOUNT_TYPE);
                this.renderErrorGrowl(PROVIDE_VALID_OD_CC_LIMIT);
            }
            return false;
        }
        return true;
    }

    displayBankName = () => {
        const { loader = false, ODaccount = "", perfiosScannedStatement = false, fetchType } = this.state;
        if (!loader && !isValidBankStatement(this.state) && !perfiosScannedStatement && ODaccount && (this.bankDataFetchTypes[fetchType] === 'Scanned PDF')) {
            return true;
        } return false;
    }

    render() {

        const { showGrowl, growlStatus, growlMsg, loader = false, ODaccount = "", perfiosScannedStatement = false, fetchType } = this.state;

        return (
            <div className={"msmeOnboardingContainer"} id="formStepper">
                {this.renderTitle()}
                <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
                <div className={'businessDetails'}>

                    {this.renderPopLoader()}

                    <div id="Perfios"></div>

                    {this.renderODAccount()}

                    {this.renderModal()}

                    {ODaccount === 'Yes' && this.renderODDetails()}

                    <br></br>

                    {this.displayBankName() && this.renderBankNames()}

                    {/* 
                    loader should be disabled &  bank name list should nt be empty & 
                    perfiosScannedStatement should be false && it should not contain valid bank statement
                     */}
                    {
                        !loader && !perfiosScannedStatement
                        && !isValidBankStatement(this.state) &&
                        <InstructionCard
                            state={this.state}
                            renderErrorGrowl={this.renderErrorGrowl}
                            updateState={this.updateState}
                            scannedFile={this.scannedFile}
                            renderSuccessGrowl={this.renderSuccessGrowl}
                            bankStatementType={this.bankDataFetchTypes[fetchType]}
                        />
                    }


                    {!loader && isValidBankStatement(this.state) &&
                        <div className="bankBox">
                            <h3>{BANK_SUMMARY_INFORMATION}</h3>
                            <div className="bankText"> {this.state.statementFetch.summaryInfo && this.state.statementFetch.summaryInfo.accNo}
                            </div> <br></br>
                            <div className="bankText">
                                {this.state.statementFetch.summaryInfo && this.state.statementFetch.summaryInfo.instName}
                            </div> <br></br>
                            <div className="bankText">
                                {this.state.statementFetch.summaryInfo && this.state.statementFetch.summaryInfo.accType}
                            </div>
                        </div>
                    }

                    {loader ? <Loader /> :
                        <p className={"combutton formDashboardMargin"} onClick={() => { this.updateBankDetails() }} >{SAVE_AND_PROCEED}</p>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(BankForm);