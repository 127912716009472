import React, { Component } from 'react';

import { utils } from '../../../../utils/utils';

import { sendOTP, loginOTP, loginWithPassword, resetPassword } from '../../../../utils/apiService';

import { validationFunctions } from '../../validation/index';

import Snackbar from '../../../../utils/alert';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks/index';

import { ActyvPassword } from '../../../../components/EditText/ActyvPassword';

import { ActyvTextBox } from '../../../../components/EditText/ActyvTextBox';

import { DISTRIBUTOR_GUIDE_PDF_URL } from '../../../../utils/enum/index';

import { languageStrings } from '../../../../utils/enum/languageStrings';

const { validation, onSubmitValidation, onLoginValidation } = validationFunctions;

const { storeToken, storeUserData, getOrganizationID, setIsPasswordConfigured } = utils;

const { LOGIN_SCREEN } = languageStrings;

export class Login extends Component {

  state = {
    mobileNumber: "",
    email: '',
    otp: '',
    confirmOtp: "",
    loginType: "OTP",
    password: "",
    termsandcondition: false,
    isOTPSent: false,
    otpVerificationStatus: false,
    isForgotPassword: false,
    loader: false,
    showPassword: false,
    errors: {
      mobileNumber: false,
      email: false,
      password: false,
      otp: false
    }
  }

  /**
   * @description function for validating the form input nad updating it in state value
   * @name handleChange
   * @param {Object} event 
   */
  handleChange = async (event) => {
    const { name, value } = event.target;
    let { errors } = this.state;
    let validationResult = validation({ name, value })
    await this.setState({ errors: { ...errors, [name]: validationResult }, [name]: value })
  } 

  onLoginHandleChange = async (event) => {
    const { name, value } = event.target;
    let { errors } = this.state;
    let validationResult = validation({ name, value })
    await this.setState({
      errors: { ...errors, [name]: validationResult },
      [name]: value,
      otp: "",
      confirmOtp: "",
      password: "",
      termsandcondition: false,
      isOTPSent: false,
      otpVerificationStatus: false,
      loader: false
    })
  }

  onValueChange = () => this.setState({ termsandcondition: !this.state.termsandcondition });

  handleForgotPassword = async () => {

    Mixpanel.track(trackString.VISITED_FORGOT_PASSWORD)

    const { mobileNumber, email } = this.state;

    let result = onSubmitValidation({ state: this.state })

    if (result === false) return this.renderErrorGrowl(LOGIN_SCREEN.KINDLY_ENTER_ALL_FIELDS);

    const distributorInfo = {
      phone: `+91${mobileNumber}`,
      email,
      organizationId: getOrganizationID(),
    }

    this.setState({ loader: true });

    Mixpanel.identify(distributorInfo.phone && distributorInfo.phone);

    try {

      const response = await resetPassword({ distributorInfo });

      let { status = false } = response || {};

      if (status) {

        this.renderSuccessGrowl(LOGIN_SCREEN.OTP_SENT_SUCCESSFULLY);

        Mixpanel.track(trackString.OTP_SEND_SUCCESSFULLY)

        this.setState({ isOTPSent: true, otpVerificationStatus: true })
      } else {

        Mixpanel.track(trackString.OTP_SEND_FAILED)

        this.setState({ isOTPSent: false })

        this.renderErrorGrowl(response.message);

      }
    } catch (error) {

      Mixpanel.track(trackString.FACING_ISSUE_WHILE_SENDING_OTP)

      this.setState({ errMsg: 'There is an issue with reset password. Please try again after some time', loader: false });

    } finally {
      this.setState({ loader: false, isForgotPassword: false });
    }
  }

  /**
   * @description function for validating the form inputs
   * and then sending otp to verify login process
   * @name handleSubmit
   */
  handleSubmit = async () => {

    const { mobileNumber, email } = this.state;

    let result = onSubmitValidation({ state: this.state })

    if (result === false) return this.renderErrorGrowl(LOGIN_SCREEN.KINDLY_ENTER_ALL_FIELDS);

    const distributorInfo = {
      phone: `+91${mobileNumber}`,
      email: email.trim(),
      organizationId: getOrganizationID(),
    }

    this.setState({ loader: true, errMsg: "", otp: "" });

    Mixpanel.identify(distributorInfo.phone);

    try {

      const response = await sendOTP({ distributorInfo });

      if (response.message === `OTP sent successful!`) {
        
        this.renderSuccessGrowl(LOGIN_SCREEN.OTP_SENT_SUCCESSFULLY);

        Mixpanel.track(trackString.OTP_SEND_SUCCESSFULLY)

        this.setState({ otpVerificationStatus: true, loader: false })
      } else {

        Mixpanel.track(trackString.OTP_SEND_FAILED)

        this.setState({ errMsg: response.message, loader: false });
      }
    } catch (error) {

      Mixpanel.track(trackString.OTP_SEND_FAILED)

      this.setState({ errMsg: 'There is an issue in send OTP. Please try again after some time', loader: false });
    } finally {
      this.setState({ loader: false, isForgotPassword: false });
    }
  }

  handleRedirect = (payload) => {
    const { redirectTo } = payload;
    this.props.history.push({
      pathname: redirectTo,
      loginInfo: { ...payload }
    });
  }

  /**
   * @description Function for performing login with user details with password
   * @name handleLogin
   */
  handleLogin = async () => {

    const { mobileNumber, email, password } = this.state;

    let result = onLoginValidation({ state: this.state })

    if (result === false) return this.renderErrorGrowl(LOGIN_SCREEN.KINDLY_ENTER_ALL_FIELDS);

    const distributorInfo = {
      phone: `+91${mobileNumber}`,
      emailId: email,
      password,
      organizationId: getOrganizationID(),
    }

    this.setState({ loader: true, errMsg: "" });

    Mixpanel.identify(distributorInfo.phone && distributorInfo.phone);

    try {

      const response = await loginWithPassword({ distributorInfo });

      const { message = "", token = "", status = false, result = {} } = response || {};

      if (status) {

        const { isPasswordConfigured = false } = result || {};

        await storeToken(token);

        await setIsPasswordConfigured(isPasswordConfigured)

        await storeUserData(result);

        Mixpanel.track(trackString.LOGGED_IN_SUCCESSFULLY_WITH_PASSWORD)

        return this.handleRedirect({ redirectTo: `/dashboard`, message: `Successful login` })

      } else {

        this.renderErrorGrowl(message);

        Mixpanel.track(trackString.FAILED_LOGGED_IN_WITH_PASSWORD)

      }
    } catch (error) {

      Mixpanel.track(trackString.FACING_ISSUE_WHILE_LOGGED_IN_WITH_PASSWORD)

      this.renderErrorGrowl(LOGIN_SCREEN.ISSUE_WHILE_LOGIN);

    }
    finally {
      this.setState({ loader: false });
    }
  }

  /**
 * @description function for validating the form inputs
 * and to validate the OTP if it is  valid user or not
 * @name handleOtpVerify
 */
  handleOtpVerify = async (payload) => {

    const { fromForgotPassword = false } = payload || {};

    const { mobileNumber, otp } = this.state;

    let result = onSubmitValidation({ state: this.state })

    if (result === false) return this.renderErrorGrowl(LOGIN_SCREEN.KINDLY_ENTER_ALL_FIELDS);

    const distributorInfo = {
      phone: `+91${mobileNumber}`,
      otp
    }

    this.setState({ loader: true, errMsg: "" });

    try {
      const response = await loginOTP({ distributorInfo });

      const { message = "", token = "", result = {} } = response || {};

      if (message === `SUCCESS`) {

        const { isPasswordConfigured = false } = result || {};

        this.setState({ otpVerificationStatus: true, loader: false })

        storeToken(token);

        setIsPasswordConfigured(fromForgotPassword ? false : isPasswordConfigured);

        storeUserData(result);

        // is password not configured we are asking them to set password
        // for setting password we are checking for user having token & isPasswordConfigure is false
        if (token && (fromForgotPassword === true || isPasswordConfigured === false)) {

          return this.handleRedirect({
            redirectTo: `/setPassword`, message: `OTP verified successful!`,
            isPasswordConfigured
          })

        }

        Mixpanel.track(trackString.LOGGED_IN_SUCCESSFULLY_WITH_OTP)
        
        return this.handleRedirect({ redirectTo: `/dashboard`, message: `Successful login` })

      } else {

       this.renderErrorGrowl(LOGIN_SCREEN.INCORRECT_OTP)

        Mixpanel.track(trackString.LOGGING_IN_FAILED_WITH_OTP)

        this.setState({ errMsg: message, loader: false });
      }
    } catch (error) {

      Mixpanel.track(trackString.FACING_ISSUE_WHILE_LOGGING_IN_WITH_OTP)

      this.setState({ errMsg: 'There is an issue in verifying OTP number. Please try again after some time', loader: false });
    }

  }

  renderLoginOption = (option, name) => {
    return (
      <div className="spaceBetween verticalText">
        <input className="checkboxInput" type="radio" name={name} value={option} checked={option === this.state.loginType} onClick={this.onLoginHandleChange} />
        <label className='backgroundWhite flexlabel'>{option}</label>
      </div>
    )
  }

  renderLoginType = (payload) => {
    let { name, options, label } = payload;
    return (
      <>
        <label className="loginWith butMar">{label} </label>
          <div className="flex halfWidth">
            {options.map(field => this.renderLoginOption(field, name))}
          </div>
      </>
    )
  }

  renderErrorGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
  }
  renderSuccessGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
  }
  handleClose = () => { this.setState({ showGrowl: false }); };

  handleClick = (event) => {
    let pathName = "/terms-of-use";

    switch(event) {
      case "TermsofUse":
        pathName = "/terms-of-use";
        break;
      case "PrivacyPolicy":
        pathName = "/privacy-policy";
        break;
      case "ConsentPage":
        pathName = "/consent";
        break;
      case "TermsAndConditions":
        pathName = "/terms-and-condition";
        break;
      default:
        pathName = "/terms-of-use";
    }

    this.props.history.push({ pathname: pathName });
}

  renderRadioButton = (option) => {
    return (
      <div className="verticalText termscondition">
         <input className="radioInput" type="checkbox" name={option} onClick={this.onValueChange} />
        <div className='backgroundWhite termsconditiontext'>
          I accept the <span className="ahrefLink" onClick={() => { this.handleClick('TermsofUse')}}>Terms of Use</span>, <span  className="ahrefLink" onClick={() => { this.handleClick('PrivacyPolicy')}}>Privacy Policy</span> and  I provide my <span  className="ahrefLink" onClick={() => { this.handleClick('ConsentPage')}}>Consent</span> to access my credit information & agree to the <span className="ahrefLink" onClick={() => { this.handleClick('TermsAndConditions')}}>Terms and Conditions</span> for availing the services on Actyv platform.</div>
      </div>
    )
  }

  renderFinfitLogo = () => {
    return <div className="flex1">
      <img className='cananraLogo' src={require('../../../assets/images/Logo.svg')} alt='logo' />
    </div>
  }


  handleClickShowPassword = (label) => {
    this.setState({ [label]: !this.state[`${label}`] });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    let { email, otp, mobileNumber, otpVerificationStatus, isOTPSent, errors, showGrowl,
      growlStatus, growlMsg, loginType, password, isForgotPassword, showPassword } = this.state

    let isLoginWithOTP = loginType === "OTP";

    return (
      <div className='contentContainer'>
        <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
        <div className='container'>
          {this.renderFinfitLogo()}
          <div className='headingContainer'>
            <div className='heading'>
              <h2 className="purewhite">{LOGIN_SCREEN.LOGIN}</h2>
            </div>
          </div>
          {/* login form UI screen */}
          <div className='form'>

            <ActyvTextBox
              style={{ width: '100%', }}
              label={`Email ID`}
              type={`email`}
              name={`email`}
              value={email}
              placeholder={`Enter your Email ID`}
              errorStatus={errors.email}
              errorMessage={errors.email && `Please enter a valid email ID`}
              handleChange={this.handleChange}
              inputProps={{
                maxLength: 100
              }}
              handleMouseDownPassword={this.handleMouseDownPassword}
            />

            <ActyvTextBox
              label={`Mobile Number`}
              type={"number"}
              name={`mobileNumber`}
              value={mobileNumber}
              placeholder={`Enter 10 digit mobile number`}
              errorStatus={errors.mobileNumber}
              errorMessage={errors.mobileNumber && `Please enter a valid Mobile No`}
              handleChange={this.handleChange}
              handleMouseDownPassword={this.handleMouseDownPassword}
            />

            <>

              {this.renderLoginType({
                name: "loginType",
                options: ["OTP", "Password"],
                label: "Login with"
              })}

              {(isOTPSent || otpVerificationStatus) && <ActyvTextBox
                label={`OTP`}
                type={"number"}
                name={`otp`}
                value={otp}
                placeholder={`Enter OTP number`}
                errorStatus={errors.otp}
                errorMessage={errors.otp && `Please enter the valid OTP`}
                handleChange={this.handleChange}
                handleMouseDownPassword={this.handleMouseDownPassword}
              />

              }
            </>

            {isLoginWithOTP ? <>
              {this.renderRadioButton("termsandcondition")}

              {
                <div className="textcenter ahrefLink FONT13"
                  onClick={() => window.open(DISTRIBUTOR_GUIDE_PDF_URL)}>
                  Distributor Guide
               </div>
              }

              <div className="textcenter purewhite">For any support-related queries -<a href="http://support.actyv.ai/"  rel="noopener noreferrer"  target="_blank" className='ahrefLink'> click here </a></div>
              
              {otpVerificationStatus && <div className="marginTopForm">
                <button className="resendOTP" onClick={() => { this.handleSubmit() }}>Resend OTP </button>
              </div>}

              {/* <div className="purewhite">{this.state.termsandcondition}</div> */}
              {this.state.loader ? <div class="loader"></div> :
                <button disabled={!this.state.termsandcondition}
                  className={!this.state.termsandcondition ? 'submitButton cursorNot' : 'submitButton'}
                  onClick={() => { otpVerificationStatus ? this.handleOtpVerify() : this.handleSubmit() }} type='submit'>
                  {otpVerificationStatus ? `VERIFY` : `SEND OTP`}
                </button>}

            </>
              :
              <>

                {isForgotPassword === false && otpVerificationStatus === false && <ActyvPassword
                  label={`Password`}
                  name={`password`}
                  showPassword={showPassword}
                  showPasswordLabel={`showPassword`}
                  value={password}
                  placeholder={`Enter your password`}
                  errorStatus={errors.password}
                  errorMessage={errors.password && `Please enter the valid Password`}
                  handleChange={this.handleChange}
                  handleClickShowPassword={this.handleClickShowPassword}
                  handleMouseDownPassword={this.handleMouseDownPassword}
                />
                }

                {otpVerificationStatus && <div className="marginTopForm">
                  <button className="resendOTP" onClick={() => { this.handleSubmit() }}>{LOGIN_SCREEN.RESEND_OTP}</button>
                </div>}

                {isOTPSent === false && <div className="textcenter ahrefLink FONT13"
                  onClick={() => this.handleForgotPassword()}>
                  {LOGIN_SCREEN.FORGOT_PASSWORD}
               </div>}

                {this.state.loader && <div class="loader"></div>}

                <div className="marginTopForm">
                  <button className="submitButton"
                    onClick={() => otpVerificationStatus ? this.handleOtpVerify({ fromForgotPassword: true }) : this.handleLogin()}>
                    {otpVerificationStatus ? `VERIFY OTP` : `LOGIN`}
                  </button>
                </div>
              </>


            }

            {this.state.errMsg && <p className="loginErrorMsg"> {this.state.errMsg} </p>}
            <p></p>
          </div>
          <div className="textcenter fieldMargin signupMargin">
            <div className=''>
              <h5 className='purewhite poweredBy'>{LOGIN_SCREEN.POWERED_BY}</h5>
              <img src={require('../../../assets/images/actyv-logo.png')} alt='logo' height='100' />
              <h5 className='purewhite'>{LOGIN_SCREEN.COPYRIGHT_STRING}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Login
