const { utils } = require('../../../utils/utils');

const { validatingFormFields, validatingEmptyFormFields } = utils;

const validator = require('validator');

/**
 * @description function for validating the form input while insertion
 * @name validation
 * @param {Object} payload 
 */
const validation = (payload) => {
    // getting field name, value and error state before performing validation
    const { name, value } = payload;

    let validationResult = true;

    switch (name) {
        // form 1
        case 'email':
            validationResult = validator.isEmail(value.trim())
            break;
        case 'mobileNumber':
            validationResult = validator.isMobilePhone(value, ['en-IN'], false)
            break;

        case 'password':
            validationResult = validator.isAlphanumeric(`${value}`) && validator.isLength(value, { min: 7, max: 15 })
            break;

        case `otp`:
            validationResult = validator.isNumeric(`${value}`, { no_symbols: true }) && validator.isLength(`${value}`, { min: 6, max: 6 })
            break;

        default: validationResult = true
            break;
    }

    // returning opposite of validation result
    return validationResult ? false : true
}


/**
 * @description function for validating the form information before verifying
 * @name onSubmitValidation
 * @param {Object} state 
 * @returns {Boolean} true/false
 */
const onSubmitValidation = (payload) => {

    const { state } = payload;
    const { mobileNumber, email } = state;
    const { errors } = state;

    let nonEmptyFields = validatingFormFields([mobileNumber, email]);

    let validationResponse = validatingEmptyFormFields([errors.mobileNumber, errors.email])

    return nonEmptyFields && validationResponse

}


/**
 * @description function for validating the form information before verifying
 * @name onSubmitValidation
 * @param {Object} state 
 * @returns {Boolean} true/false
 */
const onLoginValidation = (payload) => {

    const { state } = payload;
    const { mobileNumber, email, password } = state;
    const { errors } = state;

    let nonEmptyFields = validatingFormFields([mobileNumber, email, password]);

    let validationResponse = validatingEmptyFormFields([errors.mobileNumber, errors.email, errors.password])

    return nonEmptyFields && validationResponse

}

export const validationFunctions = {
    validation, onSubmitValidation, onLoginValidation
}