import { NIVEA_FINFIT } from './enum';

const { config: {
  KYC_SERVICES, DOC_FETCH_SERVICES, NIVEA_SERVICES, GSP_SERVICES, PERFIOS_SERVICES, DOC_SERVICES, PERFIOS_SCANNED_SERVICES
} } = require('../config/config');

const axios = require('axios');

axios.defaults.headers.common['Access-Control-Request-Methods'] = '*'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

const { utils } = require('./utils.js');

const { getToken } = utils;

const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IjQ5YTkwNzI5IiwiaWF0IjoxNjE3NjI5NjIxLCJleHAiOjE3NzkzOTI1ODM0fQ.YZ8S3Zotn8fb3ogjzpeIS81A_M_WqqVM0VQeFH4OPdU`;


const perfiosToken ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlN2EyMTJjMzFkNmFjMWJhODAwNDA2ZCIsImlhdCI6MTYwMzg2ODg4N30.bZcWO-62oFUWO1QG_PLPaCirHLEvT_ZjIn8A8hHbXCM";


const perfiosConfig = {
  headers: {
    Authorization: `Bearer ${perfiosToken}`,
    enterpriseName: NIVEA_FINFIT,
    originHost: window.location.origin
  }
}

const config = {
  headers: { Authorization: `Bearer ${token}` }
};

const userConfig = () => {
  return {
    headers: { Authorization: `Bearer ${getToken()}` }
  }
}

export const sendAadharOTP = async (payload) => {

  const URL = `${KYC_SERVICES}/v1/aadhar/verify`;


  const response = await axios.post(URL, payload, config);

  return response.data;
};

export const verifyAadharOtpMsg = async (payload) => {

  const URL = `${KYC_SERVICES}/v1/aadhar/verify-otp`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};

export const verifyAadharCaptcha = async (payload) => {

  const URL = `${KYC_SERVICES}/v1/aadhar/verify-captcha`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};


export const verifyGst = async (payload) => {

  const URL = `${KYC_SERVICES}/gstin/fetch`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};

export const verifyFssai = async (payload) => {

  const URL = `${KYC_SERVICES}/fssai-certificate/verify`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};

export const verifyPanNumber = async (payload) => {

  const URL = `${KYC_SERVICES}/pancard/verify`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};

export const fetchITR = async (payload) => {

  const URL = `${DOC_FETCH_SERVICES}/itr/verify`;

  const response = await axios.post(URL, payload);

  return response.data;
};

export const sendCaptcha = async (payload) => {

  const URL = `${DOC_FETCH_SERVICES}/gstr/send-captcha`;

  const response = await axios.post(URL, payload);

  return response.data;
};

export const fetchGSTR = async (payload) => {

  const URL = `${DOC_FETCH_SERVICES}/gstr/verify`;

  const response = await axios.post(URL, payload);

  return response.data;
};


export const sendOTP = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/sendotp`, payload);

  return response.data;
};

export const resetPassword = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/resetPassword`, payload);

  return response.data;
}

export const loginOTP = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/validateOtp`, payload);

  return response.data;
};


export const loginWithPassword = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/login`, payload);

  return response.data;
};


export const updatePassword = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/updatePassword`, payload, userConfig());
  return response.data;
};

export const createInfrastructure = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/infrastructure/insert`, payload, userConfig());

  return response.data;
};

export const createROICalcultor = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/roicalculation/insert`, payload, userConfig());

  return response.data;
};


export const uploadFile = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/uploadfiles`, payload, userConfig());

  return response.data;
};


export const getSignedURL = async (file) => {

  const filePayload = {
    fileName: file.name,
    type: file.type,
  }
  const response = await axios.post(`${NIVEA_SERVICES}/getSignedUrl`, filePayload , userConfig());

  return response.data;
};

export const uploads3Files = async (url, file) => {

  const config = {
    header: {
      'Content-Type': file.type
    }
  }
  const response = await axios.put(url, file, config);

  return response.status;
};


export const createMSME = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/msme/create`, payload, userConfig());

  return response.data;
};



export const verifyGstr = async (payload) => {

  const URL = `${KYC_SERVICES}/gstr/send-captcha`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};


export const verifyGstrCaptcha = async (payload) => {

  const URL = `${KYC_SERVICES}/gstr/verify`;

  const response = await axios.post(URL, payload, config);

  return response.data;
};


export const fetchCreditScore = async (payload) => {

  const URL = `${NIVEA_SERVICES}/msme/getcreditScore`;

  const response = await axios.post(URL, payload, userConfig());

  return response.data;
};


export const fetchInfra = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/infrastructure/fetch`, payload, userConfig());

  return response.data;
};

export const fetchRoi = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/roicalculation/fetch`, payload, userConfig());

  return response.data;
};


export const fetchGSTR3B = async (payload) => {
  
  const response = await axios.get(`${GSP_SERVICES}/gsp/gstr3b/getReturns`, payload);

  return response.data;
};

export const fetchGSTR3Btoken = async () => {
  
  const response = await axios.get(`${GSP_SERVICES}/gsp/auth`);

  return response.data;
};


export const fetchDataDiscrepancies = async (payload) => {
  
  const response = await axios.post(`${NIVEA_SERVICES}/msme/dataDiscrepancies` , payload, userConfig());

  return response.data;
};

export const fetchDistributor = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/fetch`, payload, userConfig());

  return response.data;
};

export const updateSignedDocument = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/distributor/updateSignedDocument`, payload, userConfig());

  return response.data;
};


export const updateRTMMargin = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/roicalculation/rtmmargin/insert`, payload, userConfig());

  return response.data;
};

export const fetchRoleWiseStatus = async (payload) => {
  
  const response = await axios.post(`${NIVEA_SERVICES}/application/status` , payload, userConfig());

  return response.data;
};


export const perfiosFetch = async (payload) => {

  const response = await axios.post(`${PERFIOS_SERVICES}/startPerfious`, payload, perfiosConfig);

  return response.data;
}

export const fetchBankStatement = async(clientTxId) => {

  const response = await axios.get(`${PERFIOS_SERVICES}/fetchNetbanking/${clientTxId}/json`, perfiosConfig);

  return response.data;
}


export const fetchDocumentOCR = async (formData) => {

  const response = await axios.post(`${DOC_SERVICES}/get/document/number`,
    formData, { headers: { 'Content-Type': 'multipart/form-data' } });

  return response.data;
}


export const fetchGSTNumberFromPDF = async(payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/parse/gstCertificate`, payload, userConfig());

  return response.data;
}

export const aadharSkipOtpMsg = async(payload) => {

  const response = await axios.post(`${KYC_SERVICES}/v1/aadhar/skip-otp`, payload, config);

  return response.data;
}

export const validateIndividualGSTRDocuments = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/parse/gstr3b`, payload, userConfig());

  return response.data;
}

export const fetchMsmeDetails = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/msme/fetch`, payload, userConfig());

  return response.data;
}

export const createCrifReport = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/msme/create/CrifReport`, payload, userConfig());

  return response.data;
}

export const resolveCrifQuestion = async (payload) => {
  
  const response = await axios.post(`${NIVEA_SERVICES}/msme/generate/CrifReportData`, payload, userConfig());

  return response.data;
}

export const validateIndividualITRDocuments = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/parse/individual/itr`, payload, userConfig());

  return response.data;
}

export const initiateScannedTransaction = async (payload) => {

  const response = await axios.post(`${PERFIOS_SCANNED_SERVICES}/initiateTransaction`, payload);

  return response.data;
}

export const uploadScannedTransactionStatement = async (formData) => {

  const response = await axios.post(
    `${PERFIOS_SCANNED_SERVICES}/uploadFile`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return response.data;
}

export const cancelPerfiosScannedTransaction = async (payload) => {

  const response = await axios.post(`${PERFIOS_SCANNED_SERVICES}/cancelTransaction`, payload);

  return response.data;
}

export const generateScannedStatementReport = async (payload) => {

  const response = await axios.post(`${PERFIOS_SCANNED_SERVICES}/generateReport`, payload);

  return response.data;
}

export const processPerfiosScannedStatement = async (payload) => {

  const response = await axios.post(`${PERFIOS_SCANNED_SERVICES}/processStatement`, payload);

  return response.data;
}

export const getPerfiosScannedTransactionStatus = async (payload) => {

  const response = await axios.post(`${PERFIOS_SCANNED_SERVICES}/transactionStatus`, payload);

  return response.data;
}

export const retrieveScannedStatementReport = async (payload) => {

  const response = await axios.post(`${PERFIOS_SCANNED_SERVICES}/retrieveReport`, payload);

  return response.data;
}

export const getZipCodeAddress = async pincode => {

  const response = await axios.post(`${KYC_SERVICES}/pincode/verify`, {
    pincode
  });

  return response.data.data;
}

export const fetchMsmeParsedDocuments = async (payload) => {

  const response = await axios.post(`${NIVEA_SERVICES}/fetch/parsedDocuments`, payload, userConfig());

  return response.data;
}