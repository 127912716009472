import React, { Component } from 'react';

export default class TermsandCondition extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileURL : "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/TermsOfUse.pdf"
    }
  }

  handleClick = (event) => {
    this.props.history.push({ pathname: "/login" });
  }

  render() {
    return (
      <div className="fulldocument">
      <div className="backtoLogin" onClick={() => { this.handleClick()}} >Back to Login Page</div>
        <iframe className="fulldocument" src={this.state.fileURL} frameborder="0" height="100%" width="100%" title="Instructions">
        </iframe>
        <div className="backtoLogin" onClick={() => { this.handleClick()}}>Back to Login Page</div>
    </div>
    )
  }

  componentDidMount() {

    let fileURL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/TermsOfUse.pdf";
    let currentPath = this.props.history && this.props.history.location ? this.props.history.location.pathname : "/terms-of-use";

    switch(currentPath) {
      case "/terms-of-use":
        fileURL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/TermsOfUse.pdf"
        break;
      case "/privacy-policy":
        fileURL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/PrivacyPolicy.pdf"
        break;
      case "/consent":
        fileURL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/ConsentPage.pdf"
        break;
      case "/terms-and-condition":
        fileURL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/TermsAndConditions.pdf"
        break;
        default:
          fileURL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/terms-and-conditions/TermsAndConditions.pdf";
    }

    if (fileURL) this.setState({ fileURL : fileURL });
  }
}
