const { utils } = require('../../../utils/utils');

const { validatingFormFields, validatingEmptyFormFields } = utils

const validator = require('validator');


const getPasswordStrength = (value) => {

    let isWeekPassword = validator.isAlphanumeric(`${value}`) && validator.isLength(value, { min: 1, max: 7 })
    let isMediumPassword = validator.isAlphanumeric(`${value}`) && validator.isLength(value, { min: 7, max: 9 })
    let isStrongPassword = validator.isAlphanumeric(`${value}`) && validator.isLength(value, { min: 10, max: 15 })
    let invalidPassword = validator.isLength(value, { min: 1, max: 15 })

    return isWeekPassword ? 'WEEK' : isMediumPassword ? 'MEDIUM' : isStrongPassword ? 'STRONG' : invalidPassword ? "INVALID" : "";

}

/**
 * @description function for validating the form input while insertion
 * @name validation
 * @param {Object} payload 
 */
export const validation = (payload) => {
    // getting field name, value and error state before performing validation
    const { name, value } = payload;

    let validationResult = "";

    switch (name) {

        case 'password': validationResult = getPasswordStrength(`${value}`)
            break

        case 'confirmPassword': validationResult = getPasswordStrength(`${value}`)
            break

        case `isEmpty`: validationResult = validator.isEmpty(value)
            break;

        default: validationResult = ""
            break;
    }
    return validationResult
}


/**
 * @description function for validating the form information before verifying
 * @name onSubmitValidation
 * @param {Object} state 
 * @returns {Boolean} true/false
 */
export const onSubmitValidation = (payload) => {

    const { state } = payload;
    const { mobileNumber, email } = state;
    const { errors } = state;

    let nonEmptyFields = validatingFormFields([mobileNumber, email]);

    let validationResponse = validatingEmptyFormFields([errors.mobileNumber, errors.email])

    return nonEmptyFields && validationResponse

}


/**
 * @description function for validating the form information before verifying
 * @name onSubmitValidation
 * @param {Object} state 
 * @returns {Boolean} true/false
 */
export const onLoginValidation = (payload) => {

    const { state } = payload;
    const { mobileNumber, email, password } = state;
    const { errors } = state;

    let nonEmptyFields = validatingFormFields([mobileNumber, email, password]);

    let validationResponse = validatingEmptyFormFields([errors.mobileNumber, errors.email, errors.password])

    return nonEmptyFields && validationResponse

}