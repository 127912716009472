const _ = require('lodash');

var stringSimilarity = require("string-similarity");

const { fixedValues } = require('../../../../../utils/enum/currency');

const { NAME_COMPARISON_PERCENT, DEFAULT_VALUE } = fixedValues;

const EMPTY = "EMPTY";

const DEFAULT_ACCOUNT_HOLDER_INFO = "";
/**
 * @description Function that validate that array is empty or not
 * @name checkIsObjectEmpty
 * @param {Array} property 
 * @returns {Boolean}
 */
const checkIsObjectEmpty = (property = []) => _.isEmpty(property)

/**
 * @description Function that validated bank statement information
 * whether all the properties were present for processing
 * @name isValidBankStatement
 * @param {Object} state
 * @returns {Boolean}
 */
const isValidBankStatement = (state = {}) => {

    const { statementFetch = [] } = state;

    // extracting bank statement information for validation
    const {
        accountXns = [], customerInfo = {},
        monthlyDetails = [], summaryInfo = {},
        top5FundsReceived = [], top5FundsTransferred = []
    } = statementFetch || {};

    // checking all the properties were not empty before processing
    return [
        accountXns, customerInfo,
        monthlyDetails, summaryInfo,
        top5FundsReceived, top5FundsTransferred
    ].every(property => checkIsObjectEmpty(property) ? false : true)
}


const isValidInstitutionList = (result = {}) => {

    const { institutions: { institution = {} } } = result;

    return checkIsObjectEmpty(institution) ? false : true;

}

const getInstitutionList = (result = {}) => {

    const { institutions: { institution = {} } } = result;

    return institution
}


const isFileSizeTooLarge = (size) => {

    const MB = (size / 1024) / 1000;

    return MB >= 10

}


const isValidAPIResponse = (result = {}) => {

    const { success = false } = result;

    return success === true

}

const parseInitiationResponse = (rawInfo = {}) => {

    const { result: { transaction = {} } } = rawInfo;

    const { perfiosTransactionId = "" } = transaction;

    return perfiosTransactionId

}

const parseFileUploadResponse = (rawInfo = {}) => {

    const { result: { file = {} } } = rawInfo;

    const { fileId = "" } = file;

    return fileId
}

const generatePerfiosProcessStatement = ({ perfiosTransactionId = "", fileId = "" }) => {
    return {
        perfiosTransactionId,
        payload: {
            payload: {
                fileId
            }
        }
    }
}

const parseScannedAPIResponse = (rawInfo = {}) => {
    const {
        message = "",
        perfiosTransactionId = ''
    } = rawInfo;
    return {
        message,
        perfiosTransactionId
    }
}

/**
 * @description Function that performs convert account holder names to lowercase alphabets and 
 * split with `,` incase of joint account (with multiple users).
 * @name getAccountHolderNames
 * @param {String} accountHolderName 
 * @returns {Array} list of account holder names
 */
const getAccountHolderNames = (accountHolderName) => {
    return `${accountHolderName}`.toLocaleLowerCase().split(',');
}

/**
 * @description Function that returns best match found in account holder name list
 * @name getBestMatchForAccountHolderName
 * @param {String} mainString 
 * @param {Array} targetString 
 * @returns {Object}
 */
const getBestMatchForAccountHolderName = (mainString = "", targetString = []) => {
    return stringSimilarity.findBestMatch(mainString, targetString)
}

/**
 * @description Function that checks score of percentage name match with minimum limit check.
 * @name  isValidAccountHolderNameMatch
 * @param {Number} score 
 * @returns {Boolean}
 */
const isValidAccountHolderNameMatch = (score = DEFAULT_VALUE) => {
    return score >= NAME_COMPARISON_PERCENT
}

/**
 * @description Function that accepts two different lames and returns - best match result
 * @name getBestNameMatch
 * @param {String} nameAsPerDocument 
 * @param {String} accountHolderName 
 * @returns {Object}
 */
const getNameComparisonResult = (nameAsPerDocument = EMPTY, accountHolderName = DEFAULT_ACCOUNT_HOLDER_INFO) => {

    // splitting account holder name with `,` incase of joint account.
    const listOfAccountHolders = getAccountHolderNames(accountHolderName);

    // extracting best match from list of account holder name.
    const comparisonResult = getBestMatchForAccountHolderName(
        nameAsPerDocument.toLocaleLowerCase(),
        listOfAccountHolders
    );

    return comparisonResult;

}

/**
 * @description Function that two different name strings & returns percentage matches
 * @name isValidNameMatch
 * @param {String} nameAsAadhaar 
 * @param {String} nameFromDocument 
 * @returns {Boolean}
 */
const isValidNameMatch = (nameAsAadhaar = EMPTY, nameFromDocument = DEFAULT_ACCOUNT_HOLDER_INFO, businessName = EMPTY) => {

    // extracting best match from aadhaar & accountHolders
    const personalComparisonResult = getNameComparisonResult(nameAsAadhaar, nameFromDocument);

    // extracting best match from GST document & accountHolder Information
    const businessComparisonResult = getNameComparisonResult(businessName, nameFromDocument);

    // extracting score / rating from best match.  
    const { bestMatch: { rating: personalInfoResult = 0 } } = personalComparisonResult;

    const { bestMatch: { rating: businessInfoResult = 0 } } = businessComparisonResult;
    
    // getting best match percentage for final comparison
    const rating = Math.max(personalInfoResult, businessInfoResult);

    // returning final comparison check
    return isValidAccountHolderNameMatch(rating);

}

const parsePerfiosTransactionStatusResponse = (rawInfo = {}) => {

    const { result = {} } = rawInfo;

    const { transactions: { transaction = [] } } = result;

    const {
        clientTransactionId = "",
        errorCode = "",
        perfiosTransactionId = "",
        reason = "",
        reportAvailable = false,
        status = ""
    } = (transaction && transaction[0]) || {};

    return {
        clientTransactionId,
        errorCode,
        perfiosTransactionId,
        reason,
        reportAvailable,
        status
    }

}

/**
* Function for checking if perfios scanned statement transaction is true, status is 'COMPLETED' and report is available
* @constant
* @function
* @name isScannedStatementProcessed
* @param {object} transaction
* @returns {boolean}
*/
const isScannedStatementProcessed = (transaction = {}) => {

    const { status = "", reportAvailable = false } = transaction;

    return status === 'COMPLETED' && reportAvailable === true
}

/**
 * Function for checking failure cases for perfios scanned statement transaction
 * @constant
 * @function
 * @name unsuccessfulScannedStatementTransaction
 * @param {object} transaction 
 * @returns {boolean}
 */
const unsuccessfulScannedStatementTransaction = (transaction) => {
    const { status } = transaction;
    return (status !== 'COMPLETED' && status !== 'INITIATED' && status !== 'PENDING_MANUAL_PROCESSING');
}

module.exports = {
    isValidBankStatement, checkIsObjectEmpty,
    isValidInstitutionList, getInstitutionList,
    isFileSizeTooLarge, isValidAPIResponse, parseInitiationResponse,
    parseFileUploadResponse, generatePerfiosProcessStatement,
    parseScannedAPIResponse, getAccountHolderNames,
    getBestMatchForAccountHolderName,
    isValidAccountHolderNameMatch, isValidNameMatch, parsePerfiosTransactionStatusResponse,
    isScannedStatementProcessed, unsuccessfulScannedStatementTransaction
}