const moment = require('moment');

const { trim } = require('lodash');

const { IS_QUARTERLY_FILING } = require('./validationLabel');

const isValid = (field, stringValue) => {

    let value = trim(stringValue);

    let validationResult = false;

    switch (field) {

        case IS_QUARTERLY_FILING: validationResult = moment(value, `MMM-MMM YYYY-YY`, true).isValid()
            break;

        default: validationResult = false
            break;

    }

    return validationResult;
}

module.exports = { isValid }