import React, { Component } from 'react'
import '../../screens/styles/style.css'
export class FooterComponent extends Component {
  render() {
    return (
      <div className='footerContainer'>
      </div>
    )
  }
}

export default FooterComponent
