import React, { Component } from 'react';

import Modal from 'react-awesome-modal';

import Loader from '../../../../utils/loader';

import { utils } from '../../../../utils/utils';

import { utilFunctions } from '../../../../utils';

import { updateMsme } from '../../Component/service/index';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Snackbar from '../../../../utils/alert';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import VerifyIcon from '../../../../components/Indicators/VerifyIcon';

import { onFileUpload, updateStepInfo } from './service/index';

const { getBusinessPan, getDistributorType, storeDataFromDatabase, getDataFromLocalStorage } = utils;

const { getITRFilingInfo, getDefaultITRFilingInfo, getITRInfo, getValidITRFilingInstruction } = utilFunctions;

const { localStorageStrings } = require('../../../../utils/enum/localStorageStrings');

const { ITR_INFO_LABEL, PARSED_ITR_LABEL, STEP_INFO_LABEL } = localStorageStrings;

const { languageStrings } = require('../../../../utils/enum/languageStrings')

const { ITR_DISTRIBUTOR_GUIDE_PDF_URL } = require('../../../../utils/enum/index')

const {
    ITR_RETURNS: {
        SAVE_AND_PROCEED,
        SAVED_SUCCESSFULLY_MESSAGE,
        FACING_ISSUE_WHILE_SAVE_AND_PROCEED,
        INCOME_TAX_RETURNS,
        PROVIDE_LATEST_YEAR_ITR_FILES_REQUIRED,
        SUCCESSFULLY_UPLOADED,
        INSTRUCTION_FOR_DOWNLOADING_ITR_FILE
    }
} = languageStrings

export default class ITRExpForm extends Component {
    constructor(props){
        super(props);
        Mixpanel.track(trackString.VISITED_ITR_VERIFICATION_SCREEN);
    }
    state = {
        visible: false,
        loader: false,
        errorMsg: '',
        isExistingDistributor: false,
        fileUploadInfo: [],
        latestFilingInfo: [],
        isNotEligible: false,
        popLoader: false,
        businessPan: "",
        parsedITR: getDataFromLocalStorage(PARSED_ITR_LABEL),
        itrInfo: getDataFromLocalStorage(ITR_INFO_LABEL)
    }

    fileUploadRef = {}

    componentDidMount = async () => {
        
        const { parsedITR, itrInfo } = this.state;

        this.setState({ loader: true });

        const businessPan = await getBusinessPan();

        const isExistingDistributor = await getDistributorType();

        // getting filing information with mandatory & other properties
        const filingResponse = getITRFilingInfo();

        const { latestFilingInfo, warningStatus, warningMessage } = filingResponse;

        // getting response initialized for rendering
        const { initializedFilingInfo } = getDefaultITRFilingInfo({
            latestFilingInfo, isExistingDistributor,
            businessPan, parsedITR, itrInfo
        })

        const panInfo = businessPan;

        this.setState({ panNo: panInfo });

        this.setState({
            businessPan,
            loader: false,
            latestFilingInfo,
            fileUploadInfo: initializedFilingInfo,
            isNotEligible: warningStatus,
            message: warningMessage
        });
    }

    updateLocalStorage = async (payload) => {
        const { itrInfo, parsedITR } = payload;
        await storeDataFromDatabase(ITR_INFO_LABEL, itrInfo);
        await storeDataFromDatabase(PARSED_ITR_LABEL, parsedITR);
    }

    IncExpFormSubmit = async () => {

        try {

            const { fileUploadInfo = [] } = this.state;

            this.setState({ popLoader: true })

            let responsePayload = getITRInfo(fileUploadInfo);

            // updating local storage data with local information
            await this.updateLocalStorage(responsePayload);

            // updating step information with status
            await updateStepInfo(fileUploadInfo);

            let updatedStepInfo = getDataFromLocalStorage(STEP_INFO_LABEL)

            // updating information in DB
            await this.updateIncomeDetails({ ...responsePayload, stepInfo: updatedStepInfo });

            this.setState({ popLoader: false })

            this.renderSuccessGrowl(SAVED_SUCCESSFULLY_MESSAGE)

        } catch (err) {
            this.renderErrorGrowl(FACING_ISSUE_WHILE_SAVE_AND_PROCEED);
        } finally {
            this.setState({ popLoader: false })
        }
    }

    updateIncomeDetails = async (responsePayload) => {
        await updateMsme({ formInfo: responsePayload, props: this.props, updateState: this.updateState });
    }

    updateState = (data) => {
        this.setState(data);
    }

    closePopModal = () => {
        this.updateState({ popLoader: false })
    }

    renderPopLoader = () => {
        return <Modal visible={this.state.popLoader} effect="fadeInUp">
            <div className="padding50">
                <div className="textcenter">
                    <Loader />
                </div>
            </div>
        </Modal>
    }

    truncateFileUpload = (name) =>{
        this.fileUploadRef[`${name}`].value = null;
    }


    renderInputFields = (inputFields) => {
        const { businessPan, fileUploadInfo } = this.state;
        const { label, name, verifiedStatus, accept, filedYear, isMandatory = false } = inputFields;
        return <div className='IncExpFormField'>
            <label className='flexlabel'>{label}
                {isMandatory && <div className="mandatory">*</div>}</label>
            <div className="halfWidth">
                <div className="flex">
                    <input className='msmeInput paddingtopFile ITRfileMargin filepadding inputLayout'
                        name={name} type='file' style={verifiedStatus ? { display: 'none', width: "80%" } : {}}
                        ref={reference => this.fileUploadRef[`${name}`] = reference}
                        onChange={(event => onFileUpload(event, this.updateState, {
                            businessPan, filedYear,
                            showSuccessGrowl: this.renderSuccessGrowl,
                            showErrorGrowl: this.renderErrorGrowl,
                            fileUploadInfo,
                            truncateFileUpload: this.truncateFileUpload,
                            acceptType: accept
                        }))}
                        accept={accept}
                        disabled={verifiedStatus}
                    />
                    {verifiedStatus && <label>{SUCCESSFULLY_UPLOADED} &nbsp;</label>}
                    <VerifyIcon isVerify={verifiedStatus} />
                </div>
            </div>
        </div>
    }

    renderUserGuide = () => (
        <label className="ahrefLink"
            onClick={() => window.open(ITR_DISTRIBUTOR_GUIDE_PDF_URL)}>
            {INSTRUCTION_FOR_DOWNLOADING_ITR_FILE}
        </label>
    )

    instructionCard = () => {
        return (
            <div class="BusinessFormField">
                <span className='flexlabel'>
                    <label className='flexlabel textLeft'><strong>{`Note : `}&nbsp;</strong></label>
                    {this.renderUserGuide()}
                </span>
            </div>
        )
    }

    renderTitle = () => {
        return <div className="textcenter">
            <h3 className="subtitle formtitleMargin">{INCOME_TAX_RETURNS}</h3>
        </div>
    }

    renderErrorGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
    }

    renderSuccessGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
    }

    handleClose = () => { this.setState({ showGrowl: false }); };

    renderITRInstruction = () => {

        const { isNotEligible = false, message, fileUploadInfo } = this.state;

        let instructionMessage = PROVIDE_LATEST_YEAR_ITR_FILES_REQUIRED;

        const updatedLabel = instructionMessage.replace(`{}`, getValidITRFilingInstruction({ fileUploadInfo }));

        return <div className='IncExpFormField'>
            {isNotEligible ? <label className='flexlabel'>{message}</label>
                :
                <span><label className='flexlabel'>{updatedLabel} <div className="mandatory">*</div></label></span>
            }
        </div>
    }

    render() {

        const { showGrowl, growlStatus, growlMsg, fileUploadInfo = [] } = this.state;

        const defaultFileUploadOptions = { accept: "application/pdf,", fileType: 'PDF', icon: faFilePdf }

        return (
            <div className={"msmeOnboardingContainer"} id="formStepper">

                {this.renderTitle()}

                <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />

                <div className={'businessDetails'}>

                    {this.renderPopLoader()}

                    {this.instructionCard()}

                    {this.renderITRInstruction()}

                    {fileUploadInfo.map(field => this.renderInputFields({ ...field, ...defaultFileUploadOptions }))}

                    {this.state.loader ? <Loader /> : <p className={"combutton"} onClick={() => this.IncExpFormSubmit()}>
                        {SAVE_AND_PROCEED}
                    </p>}

                </div>
            </div>

        );
    }

}
