import React from 'react';

import './App.css';

import {
    FormControl,
    makeStyles,
    OutlinedInput,
} from '@material-ui/core';

/**
 * @description Function to creating password input field as functional component.
 * @name ActyvTextBox
 * @param {Object} payload 
 */
export const ActyvTextBox = (payload) => {
    const useStyles = makeStyles({
        root: {
            width: "200%"
        },
        input: {
            fontSize: 15,
            lineHeight: 1.5,
            color: "#495057",
            backgroundColor: '#fff',
            height: 8,
            borderRadius: 5,
            alignItems: 'center',
            alignContent : 'center',
        },
        formMargin : {
            marginLeft: "-50%",
        }
    });
    const {
        label,  placeholder = "", name, type = "text",
        value, errorMessage = "",
        handleChange, errorStatus = false
    } = payload || {};
    const classes = useStyles();
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <FormControl classes={{ root: classes.formMargin }}>
            <label className="customLabelStyle">{label}</label>
            <OutlinedInput
                name={name}
                classes={{ input: classes.input, root: classes.root }}
                type={type}
                value={value}
                onChange={(data) => handleChange(data)}
                placeholder={placeholder}
                error={errorStatus}
                inputProps={{ 'aria-label': 'description'  }}
            />
            { (errorStatus || errorMessage) && <label className="backgroundWhite">{errorMessage}</label>}
            </FormControl>
        </div>
    )
}