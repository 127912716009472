import React, { Component } from 'react';

import { utils } from '../../utils/utils'

import { fetchDistributor } from '../../utils/apiService';

import BlockUI from '../../utils/blockUI';

import Snackbar from '../../utils/alert';

const { getUserData, getOrganizationID, getUserPhone, getUserEmailID, storeUserData } = utils;

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: '',
      activeStep: 0,
      loader: false,
    }
    this.myRef = React.createRef();
  }

  componentDidMount = async () => {
    await this.fetchDistributorByEmailID();
  }

  stopLoader = () => {
    this.setState({ loader: false, isSubmitting: false });
  }

  formatUserPayload = () => {
    return {
      phone: getUserPhone(),
      organizationId: getOrganizationID(),
      emailId: getUserEmailID()
    }
  }

  fetchDistributorByEmailID = async () => {
    try {
      this.setState({ loader: true });
      const payload = this.formatUserPayload();
      const response = await fetchDistributor(payload);
      if (response && response.status === 'SUCCESS') {
        storeUserData(response && response.result);
        this.stopLoader();
        return;
      }
      this.stopLoader();
    } catch (error) {
      this.stopLoader();
    }
  }

  renderErrorGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
  }

  renderSuccessGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
  }

  handleClose = () => { this.setState({ showGrowl: false }); };

  /**
   * @name renderFormTitle
   * @description - Render Form Title
   */
  renderFormTitle = (title) => {
    return <div className="normalText formTitlePadding">
      {title}
    </div>
  }

  /**
   * @name renderFormFields
   * @description - Rendering Form Fields
   */
  renderFormFields = (fields) => {
    return <div className="FONT15">
      {fields}
    </div>
  }

  /**
   * @name renderBoldTitle
   * @description Rendering Bold Headings
   */
  renderBoldTitle = (title) => {
    return <div className="boldText">
      {title}:
  </div>
  }

  renderOwnerFields = (inputFields) => {
    const { name, value } = inputFields;
    return <div>
      {this.renderFormTitle(name)}
      {this.renderFormFields(value)}
    </div>
  }

  fetchOwnerDetails = (userDetails) => {
    return [
      { name: 'Owner', value: userDetails && userDetails.ownerName },
      { name: 'Address', value: userDetails && userDetails.address },
      { name: 'Phone', value: userDetails && userDetails.phone },
      { name: 'Email ID', value: userDetails && userDetails.emailId }]
  }
  
  renderOwnerDetails = (userDetails) => {
    const userDetailsPayload = this.fetchOwnerDetails(userDetails);
    return <div className="marginTopForm formPadding">
      {this.renderOwnerFields({ name: 'Business Name', value: userDetails && userDetails.outletName })}
      <div className="marginTopForm spaceBetween">
        {userDetailsPayload.map(field => this.renderOwnerFields(field))}
      </div>
    </div>
  }

  render() {

    const userDetails = getUserData();

    const { showGrowl, growlStatus, growlMsg } = this.state;
    return (
      <div className="flexbox flexboxSpaceBox">
        <div className="FormLayout formDashboardMargin">
          <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
          {this.state.loader ? <BlockUI /> : <div>
            <div className="spaceBetween">
              {this.renderBoldTitle('Application Details')}
              <div className="spaceBetween">
                {this.renderBoldTitle('Status')}
                <div className="error statusText">
                  {userDetails && userDetails.onboardingStatus === 'Data Discrepancies' ? 'Data Discrepancies Found' : userDetails.onboardingStatus}
                </div>
              </div>
            </div>

            {this.renderOwnerDetails(userDetails)}
          </div>}</div>
      </div>
    )
  }
}