import React, { Component } from 'react';

import { resolveCrifQuestion } from '../../../../utils/apiService';

import Snackbar from '../../../../utils/alert';

import { utils } from '../../../../utils/utils';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import Loader from '../../../../utils/loader';

const { localStorageStrings } = require('../../../../utils/enum/localStorageStrings');

const { languageStrings } = require('../../../../utils/enum/languageStrings');

const { getItemByName, getUserPhone } = utils;

const {
  CRIF_FORM
} = languageStrings;

const {
  CRIF_OPTION_LIST, CRIF_QUESTION, BUTTON_BEHAVIOR
} = localStorageStrings;

export default class CrifQuestionnaire extends Component {
  constructor(props) {
    super(props);
    Mixpanel.track(trackString.VISITED_CRIF_QUESTIONNAIRE_SCREEN)
  }

  state = {
    loader: false,
    showGrowl: false,
    growlMsg: "",
    crifAnswer: "",
    options: JSON.parse(getItemByName(CRIF_OPTION_LIST)) || "",
    question: getItemByName(CRIF_QUESTION) || "",
    buttonbehaviour: getItemByName(BUTTON_BEHAVIOR) || ""
  }

  showErrorMsg = (ErrorMsg) => {
    this.renderErrorGrowl(ErrorMsg);
    this.setState({ loader: false });
  }

  isQuestionnaire = result => {
    const { status = false, questionnaire = "", question = "", optionsList = "", buttonbehaviour = "" } = result;
    if (status === false && questionnaire) {
      localStorage.setItem(CRIF_OPTION_LIST, JSON.stringify(optionsList));
      localStorage.setItem(CRIF_QUESTION, question);
      localStorage.setItem(BUTTON_BEHAVIOR, buttonbehaviour);
      this.setState({ question, options: optionsList, buttonbehaviour });
      Mixpanel.track(trackString.WRONG_ANSWER_SUBMIT_FOR_CRIF_QUESTION)
      this.showErrorMsg(CRIF_FORM.PLEASE_SELECT_RIGHT_OPTION);
      return true;
    }
    return false
  }

  isAuthenticationError = result => {
    const { status = false, authError = false } = result;
    if (status === false || authError === true) {
      this.showErrorMsg(CRIF_FORM.SECURITY_QUESTION_FAILED);
      localStorage.removeItem(CRIF_OPTION_LIST);
      localStorage.removeItem(CRIF_QUESTION);
      localStorage.removeItem(BUTTON_BEHAVIOR);
      Mixpanel.track(trackString.CRIF_AUTH_FAILURE)
      setTimeout(() => { this.props.handleNext(); }, 500);
      return true;
    } return false;
  }

  verifyAnswer = async () => {
    try {

      this.setState({ loader: true });

      const result = await resolveCrifQuestion({
        phone: getUserPhone(),
        userAns: this.state.crifAnswer
      });

      if(this.isQuestionnaire(result) || this.isAuthenticationError(result)){
        return;
      }

      this.renderSuccessGrowl(CRIF_FORM.SECURITY_QUESTION_RESOLVED);
      localStorage.removeItem(CRIF_OPTION_LIST);
      localStorage.removeItem(CRIF_QUESTION);
      localStorage.removeItem(BUTTON_BEHAVIOR);
      Mixpanel.track(trackString.CRIF_RESOLVED_SUCCESSFULLY)
      setTimeout(() => { this.props.handleNext(); }, 500);
    } catch (error) {
      Mixpanel.track(trackString.FACING_ISSUE_WHILE_RESOLVING_CRIF)
      return this.showErrorMsg(CRIF_FORM.FACING_ISSUE_WHILE_RESOLVING_CRIF);
    } finally {
      this.setState({ loader: false });
    }
  }

  renderTitle = () => {
    return (
      <>
        <div className="textcenter">
          <h3 className="subtitle formtitleMargin">{CRIF_FORM.SECURITY_QUESTIONS}</h3>
        </div>
        <div class="BusinessFormField">
          <label className='flexlabel'>
            <strong>{CRIF_FORM.NOTE1}</strong>
          </label>
        </div>
      </>
    )
  }

  renderErrorGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
  }
  renderSuccessGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
  }
  handleClose = () => { this.setState({ showGrowl: false }); };

  onSelectInput = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  renderRadioButton = (option, name) => {
    return (
      <div className="verticalText">
        <input className="checkboxInput" type="radio" name={name} value={option} onClick={(event) => this.onSelectInput(event)} checked={option === this.state.crifAnswer} />
        <label className='flexlabel'>{option}</label>
      </div>
    )
  }

  onValueChange = event => {

    const { name, value } = event.target;

    this.setState({ [name]: value })
  }

  questionnaireCard = () => {
    const { options, question, buttonbehaviour } = this.state;
    if (question) {
      return (
        <div className='BusinessFormField columnRadio'>
          <label className='flexlabel textLeft'>{question}<div className="mandatory">*</div></label>
          {(buttonbehaviour === 'R') ?
            <div className="flex halfWidth columnRadio">
              {options.map((field) => this.renderRadioButton(field, 'crifAnswer'))}
            </div> :
            <div className="flex halfWidth">
              <input className={'msmeInput filepadding'} placeholder={CRIF_FORM.ENTER_CRIF_ANSWER} type="string" name="crifAnswer" value={this.state.crifAnswer} onChange={this.onValueChange} defaultValue="" />
            </div>
          }
        </div>
      )
    }
  }

  noteCard = () => {
    return (
      <div class="BusinessFormField">
        <label className='flexlabel textLeft'><strong>{CRIF_FORM.NOTE2}</strong></label>
      </div>
    )
  }

  render() {
    const { showGrowl, growlStatus, growlMsg } = this.state;
    return (
      <div className={"msmeOnboardingContainer"} id="formStepper">
        {this.renderTitle()}
        <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
        {this.questionnaireCard()}
        {this.noteCard()}
        {this.state.loader ? <Loader /> :
          <div className="startAssessmentButton">
            {(this.state.crifAnswer !== "") && <p className={"combutton"} onClick={() => { this.verifyAnswer() }} >{CRIF_FORM.SUBMIT_ANSWER}</p>}
          </div>
        }
      </div>
    );
  }
}
