import axios from 'axios';

import { updateMsme } from '../../service/index';

import { Mixpanel } from '../../../../../utils/mixPanel';

import { trackString } from '../../../../../utils/mixpanelTracks/index';

import { utils } from '../../../../../utils/utils'

import { createMSME } from '../../../../../utils/apiService';

import { utilFunctions } from '../../../../../utils';

import {
    isValidNameMatch, isValidAPIResponse, parsePerfiosTransactionStatusResponse, isScannedStatementProcessed, isValidBankStatement,
    unsuccessfulScannedStatementTransaction
} from '../../BankForm/service/utils'

const { validateBankDataForOdLimit } = utilFunctions;

const { getUserPhone, getOrganizationID, getDataFromLocalStorage, getDetailsByName } = utils;

const { config: {
    PERFIOS_SCANNED_SERVICES
} } = require('../../../../../config/config');

const { languageStrings } = require('../../../../../utils/enum/languageStrings');

const { localStorageStrings } = require('../../../../../utils/enum/localStorageStrings');

const { OD_ACCOUNT, BANK_STATEMENT_FETCH, STEP_INFO_LABEL, AADHAAR_DETAILS, TAXPAYER_INFO } = localStorageStrings;

const {
    SUMMARY_SCREEN: {
        ASSESSMENT_COMPLETED_SUCCESSFULLY,
        WE_ARE_FACING_ISSUE_TRY_AFTER_SOMETIME,
        ERROR_IN_FETCHING_BANK_TRANSACTION_STATUS,
        ERROR_IN_FETCHING_SCANNED_STATEMENT_DATA,
        PROVIDE_VALID_OD_CC_LIMIT,
        PROVIDE_VALID_OD_CC_STATEMENT,
        SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_DATA,
        INVALID_BANK_STATEMENT_DATA
    }
} = languageStrings;

const DEFAULT_ONBOARDING_STATUS = "Under Review";

/**
 * @description Function to update MSME information.
 * @name completeAssessment
 * @param {Object} payload 
 * @returns {Object}
 */
export const completeAssessment = async (payload) => {

    const { props, updateState } = payload;

    try {

        const formInfo = { onBoarding: DEFAULT_ONBOARDING_STATUS }

        Mixpanel.track(trackString.DISTRIBUTOR_COMPLETED_ASSESSMENT)

        await updateMsme({ formInfo, props, updateState })

        return { status: true, message: ASSESSMENT_COMPLETED_SUCCESSFULLY }
    }
    catch (err) {

        Mixpanel.track(trackString.COMPLETING_ASSESSMENT_FAILED)

        return { status: false, message: WE_ARE_FACING_ISSUE_TRY_AFTER_SOMETIME }
    }
}

/**
 * @name getScannedStatementStatus
 * @param {*} payload 
 * @returns 
 * API call for getting the scanned statement transaction status
 */
const getScannedStatementStatus = async (payload) => {
    try {
        Mixpanel.track(trackString.SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_STATUS)
        const result = await axios.post(`${PERFIOS_SCANNED_SERVICES}/transactionStatus`, payload);
        return result.data;
    } catch (error) {
        Mixpanel.track(trackString.FAILED_TO_FETCH_SCANNED_STATEMENT_STATUS)
        return { status: false, message: ERROR_IN_FETCHING_BANK_TRANSACTION_STATUS }
    }
}

/**
 * @name getScannedStatementData
 * @param {object} payload 
 * API call for getting the scanned statement processed data
 */
const getScannedStatementData = async (payload) => {
    try {
        const result = await axios.post(`${PERFIOS_SCANNED_SERVICES}/retrieveReport`, payload);
        return result.data;
    } catch (error) {
        return { status: false, message: ERROR_IN_FETCHING_SCANNED_STATEMENT_DATA }
    }
}

/**
 * @name fetchScannedStatementData
 * @param {string} perfiosTransactionId 
 * @description Fetches the scanned statement data
 */
const fetchScannedStatementData = async (perfiosTransactionId) => {
    const scannedStatementData = await getScannedStatementData({ perfiosTransactionId, reportFormat: "json" });
    if (scannedStatementData.success) {
        Mixpanel.track(trackString.SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_DATA);
        return scannedStatementData.result;
    }
    Mixpanel.track(trackString.FAILED_TO_FETCH_SCANNED_STATEMENT_DATA);
}

/**
 * @constant
 * @function
 * @name getPerfiosTransactionStatus
 * @param {object} payload 
 * @description - API for checking the scanned statement status. If completed calls another function to get the data and store in database.
 */
export const getPerfiosTransactionStatus = async (payload) => {
    const { perfiosTransactionId = '', updateState, renderErrorGrowl, renderSuccessGrowl, state } = payload;
    const transactionStatusResponse = await getScannedStatementStatus({
        perfiosTransactionId
    });
    const stepInfo = getDataFromLocalStorage(STEP_INFO_LABEL);

    // validating whether the API response is having success status as true
    if (!isValidAPIResponse(transactionStatusResponse)) {

        Mixpanel.track(trackString.FAILED_TO_FETCH_SCANNED_STATEMENT_STATUS);

        return renderErrorGrowl(ERROR_IN_FETCHING_BANK_TRANSACTION_STATUS)

    }

    const transaction = parsePerfiosTransactionStatusResponse(transactionStatusResponse);

    if (isScannedStatementProcessed(transaction)) {
        const statementFetch = await fetchScannedStatementData(perfiosTransactionId);
        const isValidStatement = isValidBankStatement({ statementFetch });
        if (!isValidStatement) return renderErrorGrowl(INVALID_BANK_STATEMENT_DATA)
        const ODaccount = localStorage.getItem(OD_ACCOUNT);
        const result = validateBankDataForOdLimit({ statementFetch, ODaccount });
        await handleOdCcValidation({ statementFetch, result, stepInfo, state, renderErrorGrowl, renderSuccessGrowl, updateState });
        return;
    }

    updateState({
        perfiosScannedDocumentStatus: transaction
    });

    if (unsuccessfulScannedStatementTransaction(transaction)) {
        localStorage.setItem(STEP_INFO_LABEL, JSON.stringify({
            ...stepInfo,
            perfiosScannedStatement: false
        }));
    }
}

/**
 * @constant
 * @function
 * @name handleOdCcValidation
 * @param {object} payload
 * @returns 
 */
const handleOdCcValidation = async (payload) => {
    const { statementFetch, result, stepInfo, state, renderErrorGrowl, renderSuccessGrowl, updateState } = payload;
    const { valid = false, removeBankStatementData = false } = result;

    const bankNameVerificationResult = handleBankNameVerificationForScannedStatement(statementFetch);

    // set local storage with bank statement data
    await localStorage.setItem(BANK_STATEMENT_FETCH, JSON.stringify(statementFetch));

    // Failure in OD/CC Validation, handle the failure case in handleOdValidationFailure function
    if (!valid) {
        await handleOdValidationFailure({ removeBankStatementData, stepInfo, renderErrorGrowl, updateState })
        return;
    }

    // store scanned statement and track the status
    await storeScannedStatementData({ statementFetch, bankNameVerificationResult, stepInfo, updateState, state })

    renderSuccessGrowl(SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_DATA);
}

/**
 * @constant
 * @name handleBankNameVerificationForScannedStatement
 * @param {object} statementFetch
 * @returns 
 */
const handleBankNameVerificationForScannedStatement = (statementFetch) => {

    // extracting account holder name
    const { customerInfo = {} } = statementFetch;

    const { name: accountHolderName = "" } = customerInfo;

    // getting personal details from aadhaar document.
    const { name = "" } = getDataFromLocalStorage(AADHAAR_DETAILS);

    // getting business details from GST certificate
    const { gst = {} } = getDetailsByName(TAXPAYER_INFO);

    const { tradeNam: businessName = "" } = gst || {};

    const bankNameVerificationResult = isValidNameMatch(name, accountHolderName, businessName);

    if (bankNameVerificationResult) {
        Mixpanel.track(trackString.VERIFIED_BANK_ACCOUNT_HOLDER_CHECK_SUCCESSFULLY);
    } else {
        Mixpanel.track(trackString.DISCREPANCY_FOUND_IN_BANK_ACCOUNT_HOLDER_CHECK);
    }

    return bankNameVerificationResult;
}

/**
 * Function for storing scanned statement data in database and adding mixpanel tracks
 * @constant
 * @function
 * @name storeScannedStatementData
 * @param {object} payload 
 */
const storeScannedStatementData = async (payload) => {
    const { statementFetch, bankNameVerificationResult, stepInfo, updateState, state } = payload;
    // Successful OD/CC validation,  store the statement in database
    const msmeInfo = {
        phone: getUserPhone(),
        organizationId: getOrganizationID(),
        bankStatement: statementFetch
    }
    try {
        const msmeResult = await createMSME({ msmeInfo });
        const { status = false } = msmeResult || {};
        if (status) {
            Mixpanel.track(trackString.SUCCESSFULLY_STORED_SCANNED_STATEMENT_DATA);
            Mixpanel.track(trackString.VERIFIED_BANK_STATEMENT_SUCCESSFULLY);
            // update the state
            await updateState({
                documentStatus: {
                    ...state.documentStatus,
                    bankVerificationForm: true
                }
            });

            // update the local storage
            await localStorage.setItem(STEP_INFO_LABEL, JSON.stringify({
                ...stepInfo,
                bankVerificationForm: true,
                bankStatementNameMatching: bankNameVerificationResult
            }));
        } else {
            Mixpanel.track(trackString.FAILED_TO_STORE_SCANNED_STATEMENT_DATA);
        }
    } catch (error) {
        Mixpanel.track(trackString.FAILED_TO_STORE_SCANNED_STATEMENT_DATA);
    }
}

/**
 * Function that handles the re upload of statement or change in account type based on type of failure in OD/CC validation
 * @constant
 * @name handleOdValidationFailure
 * @param {object} payload
 */
const handleOdValidationFailure = async (payload) => {
    const { removeBankStatementData, stepInfo, renderErrorGrowl, updateState } = payload;
    if (removeBankStatementData) {
        Mixpanel.track(trackString.UPLOADED_SAVINGS_STATEMENTS_FOR_OD_ACCOUNT_TYPE);
        await localStorage.removeItem(BANK_STATEMENT_FETCH);
        await localStorage.setItem(STEP_INFO_LABEL, JSON.stringify({
            ...stepInfo,
            perfiosScannedStatement: false
        }));
        renderErrorGrowl(PROVIDE_VALID_OD_CC_STATEMENT);
        updateState({ statementFetch: {} });
    } else {
        Mixpanel.track(trackString.UPLOADED_OD_STATEMENT_FOR_SAVINGS_ACCOUNT_TYPE);
        renderErrorGrowl(PROVIDE_VALID_OD_CC_LIMIT);
    }
}