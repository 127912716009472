export const currencies = [
  { value: "INR", label: "India Rupees" },
  { value: "USD", label: "United States Dollars" },
  { value: "EUR", label: "Euro" },
  { value: "GBP", label: "United Kingdom Pounds" },
  { value: "DZD", label: "Algeria Dinars" },
  { value: "ARP", label: "Argentina Pesos" },
  { value: "AUD", label: "Australia Dollars" },
  { value: "ATS", label: "Austria Schillings" },
  { value: "BSD", label: "Bahamas Dollars" },
  { value: "BBD", label: "Barbados Dollars" },
  { value: "BEF", label: "Belgium Francs" },
  { value: "BMD", label: "Bermuda Dollars" },
  { value: "BRR", label: "Brazil Real" },
  { value: "BGL", label: "Bulgaria Lev" },
  { value: "CAD", label: "Canada Dollars" },
  { value: "CLP", label: "Chile Pesos" },
  { value: "CNY", label: "China Yuan Renmimbi" },
  { value: "CYP", label: "Cyprus Pounds" },
  { value: "CSK", label: "Czech Republic Koruna" },
  { value: "DKK", label: "Denmark Kroner" },
  { value: "NLG", label: "Dutch Guilders" },
  { value: "XCD", label: "Eastern Caribbean Dollars" },
  { value: "EGP", label: "Egypt Pounds" },
  { value: "FJD", label: "Fiji Dollars" },
  { value: "FIM", label: "Finland Markka" },
  { value: "FRF", label: "France Francs" },
  { value: "DEM", label: "Germany Deutsche Marks" },
  { value: "XAU", label: "Gold Ounces" },
  { value: "GRD", label: "Greece Drachmas" },
  { value: "HKD", label: "Hong Kong Dollars" },
  { value: "HUF", label: "Hungary Forint" },
  { value: "ISK", label: "Iceland Krona" },
  { value: "IDR", label: "Indonesia Rupiah" },
  { value: "IEP", label: "Ireland Punt" },
  { value: "ILS", label: "Israel New Shekels" },
  { value: "ITL", label: "Italy Lira" },
  { value: "JMD", label: "Jamaica Dollars" },
  { value: "JPY", label: "Japan Yen" },
  { value: "JOD", label: "Jordan Dinar" },
  { value: "KRW", label: "Korea (South) Won" },
  { value: "LBP", label: "Lebanon Pounds" },
  { value: "LUF", label: "Luxembourg Francs" },
  { value: "MYR", label: "Malaysia Ringgit" },
  { value: "MXP", label: "Mexico Pesos" },
  { value: "NLG", label: "Netherlands Guilders" },
  { value: "NZD", label: "New Zealand Dollars" },
  { value: "NOK", label: "Norway Kroner" },
  { value: "PKR", label: "Pakistan Rupees" },
  { value: "XPD", label: "Palladium Ounces" },
  { value: "PHP", label: "Philippines Pesos" },
  { value: "XPT", label: "Platinum Ounces" },
  { value: "PLZ", label: "Poland Zloty" },
  { value: "PTE", label: "Portugal Escudo" },
  { value: "ROL", label: "Romania Leu" },
  { value: "RUR", label: "Russia Rubles" },
  { value: "SAR", label: "Saudi Arabia Riyal" },
  { value: "XAG", label: "Silver Ounces" },
  { value: "SGD", label: "Singapore Dollars" },
  { value: "SKK", label: "Slovakia Koruna" },
  { value: "ZAR", label: "South Africa Rand" },
  { value: "KRW", label: "South Korea Won" },
  { value: "ESP", label: "Spain Pesetas" },
  { value: "XDR", label: "Special Drawing Right (IMF" },
  { value: "SDD", label: "Sudan Dinar" },
  { value: "SEK", label: "Sweden Krona" },
  { value: "CHF", label: "Switzerland Francs" },
  { value: "TWD", label: "Taiwan Dollars" },
  { value: "THB", label: "Thailand Baht" },
  { value: "TTD", label: "Trinidad and Tobago Dollars" },
  { value: "TRL", label: "Turkey Lira" },
  { value: "VEB", label: "Venezuela Bolivar" },
  { value: "ZMK", label: "Zambia Kwacha" },
  { value: "EUR", label: "Euro" },
  { value: "XCD", label: "Eastern Caribbean Dollars" },
  { value: "XDR", label: "Special Drawing Right (IMF" },
  { value: "XAG", label: "Silver Ounces" },
  { value: "XAU", label: "Gold Ounces" },
  { value: "XPD", label: "Palladium Ounces" },
  { value: "XPT", label: "Platinum Ounces" }
];

export const fixedValues = {
  GO_DOWN_ESTIMATED_VALUE: 66,
  MIN_OD_LIMIT: 100000,
  GSTIN_LENGTH: 15,
  PAN_NUMBER_LENGTH: 10,
  PAN_TYPE: {
    INDIVIDUAL: "P",
    COMPANY: "C",
    HINDU_UNDIVIDED_FAMILY: "H",
    FIRM_LIMITED_LIABILITY_PARTNERSHIP: "F",
    ASSOCIATION_OF_PERSON: "A"
  },
  MINIMUM_REQUIRED_GSTR_FILE: 1,
  MANDATORY_GSTR_COUNT: 9,
  MAXIMUM_REQUIRED_GSTR_FILE: 12,
  GSTR_FILING_DEAD_LINE: 25,
  OPTIONAL_GSTR_FILING_COUNT_1: 1,
  OPTIONAL_GSTR_FILING_COUNT_2: 2,
  DEFAULT_VALUE: 0,
  DEFAULT_INDEX_ZERO: 0,
  DEFAULT_FIRST_INDEX: 1,
  MARCH_END: '-03-31',
  APRIL_START: '-04-01',
  NEW_BORN_DISTRIBUTOR: {
    WITH_1_MONTH_COUNT: 1,
    WITH_0_MONTH_COUNT: 0
  },
  MANDATORY_ITR_FILING_COUNT: 2,
  OPTION_1_YEAR_ITR_FILING: 1,
  MONTH_COUNT: {
    ONE: 1,
    TWO: 2
  },
  MAXIMUM_BANK_STATEMENT_MONTH: 12,
  DEFAULT_ONBOARDING_STATUS: "InProgress",
  DEFAULT_GSTR_MONTHS_BEFORE_DEADLINE: 14,
  DEFAULT_GSTR_MONTHS_AFTER_DEADLINE: 13,
  MIN_MONTHS_GSTR_WITHIN_DEADLINE: 11,
  MIN_MONTHS_GSTR_AFTER_DEADLINE: 10,
  NAME_COMPARISON_PERCENT: 0.60,
  PERFIOS_STATEMENT_PROCESSING_TYPE: {
    NET_BANKING: 'Net Banking',
    DIGITAL_PDF: 'Digital PDF',
    SCANNED_PDF: 'Scanned PDF'
  },
  BANK_STATEMENT_PROCESSING_TYPE: ['Net Banking', 'Digital PDF', 'Scanned PDF'],
  YES: "Yes",
  NO: "No",
  MIN_AGE_LIMIT: 18,
  MAX_AGE_LIMIT: 100,
  SCREEN_NAME: {
    GSTR_3B_FORM: "GSTR3b_FORM"
  },
  DOCUMENT_TYPE: {
    GSTR_3B: 'GSTR_3B'
  },
  DEFAULT_ITR_FILING_GAP: 12,
  MINIMAL_GSTR_FILING_PULL_EXPIRY_DAYS : '10',
  GSTR3B_FILING_LABEL: 'GSTr-3B {}',
  GSTR3B_INDIVIDUAL_FILE_UPLOAD_NAME: 'file{}',
  JAN_MARCH: 'Jan-Mar',
  APRIL_JUNE: 'Apr-Jun',
  JULY_SEPTEMBER: 'Jul-Sep',
  OCTOBER_DECEMBER: 'Oct-Dec',
  DECEMBER: 'December',
  DEFAULT_YEAR: '2020',
  DIFFERENCE_BETWEEN_MONTHLY_FILING: 1,
  INDIVIDUAL_GSTR_FILING_PERIOD : 1,
  QUARTERLY_GSTR_FILING_PERIOD: 3,
  DEFAULT_ITR_DEADLINE_DAY_MONTH : '01/01',
  MINIMUM_BUSINESS_START_YEAR: 1,

  quarterlyToMonthlyMapping: {
    "Jan-Mar": 'January',
    "Apr-Jun": 'April',
    "Jul-Sep": 'July',
    "Oct-Dec": 'October'
  },

  monthlyToQuarterlyMapping: {

    'January': 'Jan-Mar',
    'February': 'Jan-Mar',
    'March': 'Jan-Mar',

    "April": "Apr-Jun",
    "May": "Apr-Jun",
    "June": "Apr-Jun",

    "July": "Jul-Sep",
    "August": "Jul-Sep",
    "September": "Jul-Sep",

    "October": "Oct-Dec",
    "November": "Oct-Dec",
    "December": "Oct-Dec",
  },

  APRIL: 'April',
  MAY: 'May',
  JAN: 'January',
  FEB: 'February',
  MAR: 'March',
  MANDATORY_DOCUMENT_FOR_EXISTING_BUSINESS: 12,
  MONTHLY_FILING_GAP: 1,
  QUARTERLY_FILING_GAP: 3,
  DEFAULT_FILING_COUNT: 0,
  MAX_FILING_RANGE: 2,
  STEP_0_AND_1: ['0', '1'],
}