export const PDF_URL = 'https://actyv-assets.s3.ap-south-1.amazonaws.com/test-images/msme/1609853920575Document.pdf';

export const INCOME_TAX_FILING_URL = 'https://www.incometaxindiaefiling.gov.in/home';

export const GOV_IN_URL = 'https://www.gst.gov.in/';

export const PLAY_STORE_ADOBE_SCANNER = `https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=en_IN&gl=US`;

export const DISTRIBUTOR_GUIDE_PDF_URL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/test-images/msme/1607089715600FINFIT - Distributor Guide.pdf"

export const ITR_DISTRIBUTOR_GUIDE_PDF_URL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/test-images/msme/1625467917868ITRInstructions.pdf"

export const GSTR_DISTRIBUTOR_GUIDE_PDF_URL = "https://actyv-assets.s3.ap-south-1.amazonaws.com/test-images/msme/GSTr+User+Guide.pdf"

export const localStorageLabel = {
    STEPPER_INDEX: "activeStep"
}

export const NIVEA_FINFIT = 'nivea-finfit'