const {
  utils
} = require('../../../../../utils/utils');

const {
  getDetailsByName,getBusinessPan,getPersonalPanNumber
} = utils;

const { fixedValues } = require('../../../../../utils/enum/currency');

const { regexExp } = require('../../../../../utils/validationRegex/index');

const { utilFunctions } = require('../../../../../utils');

const { extractPanType } = utilFunctions;

const { localStorageStrings } = require('../../../../../utils/enum/localStorageStrings');

const { PAN_REG_EXP } = regexExp;

const {
  PAN_TYPE: {
    INDIVIDUAL,
  },
} = fixedValues;

const {
  FSSAI, TAXPAYER_INFO, BUSINESS_CODE, AGREEMENT_NUMBER
} = localStorageStrings;

const taxPayerInfo = getDetailsByName(TAXPAYER_INFO);

const fssaiDetails = getDetailsByName(FSSAI);

const getTaxPayerNo = () => taxPayerInfo && taxPayerInfo.gst && taxPayerInfo.gst.gstin;

const getPanNo = () => getBusinessPan();

const getPersonalPanNo = () => getPersonalPanNumber();

const getFssaiDetails = () => fssaiDetails && fssaiDetails.length;

const getBusinessCode = () => localStorage.getItem(BUSINESS_CODE);

const getAgreementNumber = () => localStorage.getItem(AGREEMENT_NUMBER);

const validatePanNumber = (panNumber) => {
  return PAN_REG_EXP.test(panNumber) && extractPanType(panNumber) === INDIVIDUAL
}

export const businessUtilFunctions = {
  getTaxPayerNo, getPanNo, getPersonalPanNo, getFssaiDetails, getBusinessCode, getAgreementNumber, validatePanNumber
}
