
import { fetchMsmeDetails, createMSME } from '../../../../src/utils/apiService';

import { utils } from '../../../utils/utils';

const { formatMsmePayload } = require('../payload/index')

const { getUserPhone, getOrganizationID } = utils;

/**
 * @description Function for fetching onboarding active step.
 * @name fetchOnboardingIndex
 * @returns {object}
 */
export const fetchMsmeData = async () => {
    try {
        const response = await fetchMsmeDetails(formatMsmePayload());
        return response;
    } catch (error) {
        return { status: false, message: error.message }
    }
}

/**
 * @description Function returns default payload structure that need to be updated.
 * @name getDefaultInfo
 * @returns {object}
 */
const getDefaultInfo = () => {
    return {
        parsedGSTR: [],
        gstReturns: [],
        bankStatement: [],
        parsedITR: [],
        itrInfo: [],
        stepInfo: {
            businessForm: false,
            isVerifiedExceptGSTCertificate: false,
            itrReturnsForm: false,
            gstrReturnsForm: false,
            bankVerificationForm: false,
            summaryForm: false,
            perfiosScannedStatement: false,
            bankData: {}
        },
        pancard: "",
        panInfo: {},
        gstInfo: {},
        dateOfIncorporation: "",
        companyDetails: {},
        aadhaarInfo: {}
    }
}

/**
 * @description Function updated information of existing msme information with empty values in case of
 * msme not having step information.
 * @name updateExistingDistributor
 * @returns {Object}
 */
export const updateExistingDistributor = async () => {
    try {

        const msmePayload = {
            phone: getUserPhone(),
            organizationId: getOrganizationID(),
        }

        const payload = getDefaultInfo();

        const msmeInfo = { ...msmePayload, ...payload };

        await createMSME({ msmeInfo });

        return { isUpdated: true, message: 'updated successful!' };

    } catch (error) {
        return { isUpdated: false, message: error.message }
    }
}