export const localStorageStrings = {
  ITR_INFO_LABEL: "itrInfo",
  PARSED_ITR_LABEL: "parsedITR",
  STEP_INFO_LABEL: "stepInfo",
  PARSED_GSTR_LABEL: "parsedGSTR",
  GSTR_RETURNS_LABEL: "gstReturns",
  AADHAAR_DETAILS: 'aadharDetails',
  AADHAAR_FRONT_DETAILS: 'AADHAR_FRONT',
  FSSAI: 'fssai',
  TAXPAYER_INFO: 'taxpayerInfo',
  BUSINESS_CODE: 'businessCode',
  AGREEMENT_NUMBER: 'agreementDeed',
  IS_VERIFIED_EXCEPT_GST: 'getIsVerifiedExceptGST',
  SALE_DEED: 'saleDeed',
  GST_CERTIFICATE: 'gstCertificate',
  PAN: "PAN",
  NOC_DOCUMENT: 'nocDocument',
  MOM_CERTIFICATE: 'momCertificate',
  CRIF_OPTION_LIST: 'crifOptionList',
  CRIF_QUESTION: 'crifQuestion',
  BUTTON_BEHAVIOR: 'buttonbehaviour',
  NO_OF_DISTRIBUTORS: "noofDirectors",
  NO_OF_OFFICERS: "noofOfficers",
  INCORPORATION: "incorporation",
  COMPANY_DETAILS: "companyDetails",
  PAN_TYPE: "panType",
  ADDRESS_OF_HINDU_UNDIVIDED_FAMILY: "addresssofHinduUndivedFamily",
  AGE_OF_HINDU_UNDIVIDED_FAMILY: "ageofHinduUndivedFamily",
  NAME_OF_HINDU_UNDIVIDED_FAMILY: "nameofHinduUndivedFamily",
  BANK_STATEMENT_FETCH :"statementFetch",
  OD_LIMIT: "ODLimit",
  OD_ACCOUNT: "ODaccount",
  BANK_DATA_FETCH_TYPE: "bankDataFetchType",
  BANK_NAMES: 'bankNames',
  CRIF_STEP: 'crifStep',
}