import React from 'react'
import Form from './components/Form/index';

import Footer from '../../components/Footer/index';
import { withRouter } from 'react-router-dom'
import '../styles/style.css'

function LoginContainer (props) {
  return (
    <div className='mainContainer'>
      <Form history={props.history} />
      <Footer/>
    </div>
  )
}

export default withRouter(LoginContainer)
