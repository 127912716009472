import React from 'react';

import { getOnboardingContent } from '../../../payload/index'

const renderPreOnboardingImage = () => {
    return <img src={require('../../../../../screens/assets/images/onboarding.png')}
        alt='logo' className='FormLayout' />
}

const renderCommonBox = (fields) => {
    const { value, className } = fields;
    return <div className={className}>{value}</div>
}

const OnboardingInviteCard = () => {

    let preOnboardingText = getOnboardingContent();

    return <div>
        {renderPreOnboardingImage()}
        <div className="welcomecontent">
            {preOnboardingText.map((text) => renderCommonBox(text))}
        </div>
    </div>
}

export default OnboardingInviteCard;