import React from 'react';

import { withRouter } from 'react-router-dom';

import Onboarding from './views/components';

function DistributorOnboardingContainer (props) {
  return (
    <div className=''>
      <Onboarding history={props.history} />
    </div>
  )
}

export default withRouter(DistributorOnboardingContainer)
