import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Snackbars(props) {
  
  const [state] = React.useState({
    open: props.showBar ? props.showBar : false,
    status: props.status ? props.status : 'success',
    vertical: props.vertical ? props.vertical :  'top',
    horizontal: props.horizontal ? props.horizontal : 'right',
  });
  const { vertical, horizontal} = state;

  return (
    <div>
      <Snackbar 
        open={props.showBar} 
        autoHideDuration={5000} 
        onClose={props.handleClose}
        anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={props.handleClose} severity={props.status}>
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}