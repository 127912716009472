import React, { Component } from 'react';

import '../../../styles/style.css';

import { validation } from '../../validation/index';

import Snackbar from '../../../../utils/alert';

import { updatePassword } from '../../../../utils/apiService'

import { utils } from '../../../../utils/utils'

import { ActyvPassword } from '../../../../components/EditText/ActyvPassword';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks/index';

import _ from 'lodash';

import { languageStrings } from '../../../../utils/enum/languageStrings';

const { getUserData, setIsPasswordConfigured } = utils;

const { SET_PASSWORD_SCREEN } = languageStrings;

export class SetPassword extends Component {

  componentDidMount() {
    Mixpanel.track(trackString.VISITED_FORGOT_PASSWORD)
    const { history } = this.props;
    let { loginInfo = "" } = history.location;
    if (loginInfo) {
      const { message = "", isPasswordConfigured = false } = loginInfo;
      this.setState({ growlMsg: message, showGrowl: true, isPasswordConfigured })
    }
  }

  /**
   * Initializing state values
   */
  state = {
    password: "",
    confirmPassword: "",
    loader: false,
    amount: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    showConfirmPassword: false,
    showGrowl: false,
    growlMsg: "",
    growlStatus: 'success',
    isPasswordConfigured: false,
    errors: {
      confirmPassword: "",
      password: ""
    }
  }

  handlePasswordChange = async (event) => {
    const { name, value } = event.target;
    let { errors } = this.state;
    let validationResult = validation({ name, value })
    await this.setState({ errors: { ...errors, [name]: validationResult }, [name]: value })
  };

  handleClickShowPassword = (label) => {
    this.setState({ [label]: !this.state[`${label}`] });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  getValidErrorString = (label) => {
    let errorMessage = "";
    switch (label) {
      case `WEEK`: errorMessage = `Password Strength : ${label}, Required min 8 characters`
        break
      case `MEDIUM`: errorMessage = `Password Strength : ${label}`
        break
      case `STRONG`: errorMessage = `Password Strength : ${label}`
        break
      case `INVALID`: errorMessage = `Note : Password must contains only alphanumeric`
        break
      default: errorMessage = ""
        break
    }
    return errorMessage
  }

  getIsValidPassword = (value) =>{
    return value === `WEEK` || value === `INVALID`
  }

  renderErrorGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
  }

  renderSuccessGrowl = (msg) => {
    this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
  }

  handleClose = () => { this.setState({ showGrowl: false }); };


  handleRedirect = (payload) => {
    const { redirectTo } = payload;
    this.props.history.push({
      pathname: redirectTo,
      loginInfo: { ...payload }
    });
  }

  onSubmit = async () => {
    try {
      this.setState({ loader: true });
      const userInfo = getUserData();
      const { emailId, phone } = userInfo || {};
      const { password, isPasswordConfigured } = this.state;
      const distributorInfo = {
        organizationId: "JKSNIVEA",
        emailId,
        phone,
        password
      }
      let result = await updatePassword({ distributorInfo })
      const { status = false, message = "" } = result || {};
      if (status) {
        await setIsPasswordConfigured(true);

        Mixpanel.track(trackString.UPDATED_PASSWORD_SUCCESSFULLY)

        this.renderSuccessGrowl(SET_PASSWORD_SCREEN.PASSWORD_CREATED_SUCCESSFULLY)

        return this.handleRedirect({
          redirectTo: `/dashboard`,
          message: isPasswordConfigured ? `Password changed successfully!` : `Password created successfully!`,
          status: true
        })

      }
      else {
        this.renderErrorGrowl(message)
        Mixpanel.track(trackString.FAILED_WHILE_UPDATING_PASSWORD)
      }
    }
    catch (err) {
      Mixpanel.track(trackString.FACING_ISSUE_WHILE_UPDATING_PASSWORD)
      this.renderErrorGrowl(SET_PASSWORD_SCREEN.ERROR_WHILE_CREATING_PASSWORD)
    } finally {
      this.setState({ loader: false });
    }
  }


  renderFinfitLogo = () => {
    return <div className="flex1">
      <img className='cananraLogo' src={require('../../../assets/images/Logo.svg')} alt='logo' />
    </div>
  }

  render() {
    let { errors, showGrowl, growlStatus, growlMsg, 
      password, showPassword, confirmPassword, showConfirmPassword } = this.state

    let isValidPassword = password === confirmPassword;

    let isValidNewPassword = this.getIsValidPassword(errors.password);

    let validErrorString = this.getValidErrorString(errors.password);

    let validComparisonErrorString = isValidPassword ? `Password Matched` : `Password Mismatch`;

    let showConfirmButton = (isValidNewPassword === false && isValidPassword && !_.isEmpty(password) && !_.isEmpty(confirmPassword)) || false

    return (
      <div className='contentContainer'>
        <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />
        <div className='container'>
          {this.renderFinfitLogo()}
          <div className='headingContainer'>
            <div className='heading'>
              <h2 className="purewhite">{SET_PASSWORD_SCREEN.SET_PASSWORD}</h2>
            </div>
          </div>
          {/* login form UI screen */}
          <div className='form'>
            <>
              <ActyvPassword
                label={`Password`}
                name={`password`}
                showPassword={showPassword}
                showPasswordLabel={`showPassword`}
                value={password}
                placeholder={`Enter new password`}
                errorStatus={isValidNewPassword}
                errorMessage={validErrorString}
                handleChange={this.handlePasswordChange}
                handleClickShowPassword={this.handleClickShowPassword}
                handleMouseDownPassword={this.handleMouseDownPassword}
              />

              <ActyvPassword
                label={`Confirm Password`}
                name={`confirmPassword`}
                showPassword={showConfirmPassword}
                showPasswordLabel={`showConfirmPassword`}
                placeholder={`Enter confirm password`}
                value={confirmPassword}
                errorStatus={confirmPassword && !isValidPassword}
                errorMessage={confirmPassword && validComparisonErrorString}
                handleChange={this.handlePasswordChange}
                handleClickShowPassword={this.handleClickShowPassword}
                handleMouseDownPassword={this.handleMouseDownPassword}
              />

              {<label className="backgroundWhite">{`Note : Maximum password limit 15 characters`}</label>}

              {this.state.loader && <div class="loader" />}

              {
                <div className="marginTopForm">
                  <button disabled={!showConfirmButton} className={!showConfirmButton ? 'submitButton cursorNot' : 'submitButton'}
                    onClick={() => this.onSubmit()}>
                    {SET_PASSWORD_SCREEN.CONFIRM}
                </button>
                </div>
              }

            </>
           { this.state.errMsg && <p className="loginErrorMsg"> {this.state.errMsg} </p>}
            <p></p>
          </div>
          <div className="textcenter fieldMargin signupMargin">
            <div className=''>
              <h5 className='purewhite poweredBy'>{SET_PASSWORD_SCREEN.POWERED_BY}</h5>
              <img src={require('../../../assets/images/actyv-logo.png')} alt='logo' height='100'/>
              <h5 className='purewhite'>{SET_PASSWORD_SCREEN.COPYRIGHT_STRING}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SetPassword