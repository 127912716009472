const growl = require('@crystallize/react-growl');

const { Mixpanel } = require('./mixPanel');

const { trackString } = require('./mixpanelTracks');

const stringSimilarity = require('string-similarity');

const _ = require('lodash');

const FileType = require('file-type');

const TOKEN = 'TOKEN';

const USER = "DISTRIBUTOR";

const TAX_PAYER_INFO = "taxpayerInfo";

const PAN_NUMBER = "panNumber";

const PERSONAL_PAN_NUMBER = "personalPanNumber";

const OD_CC_ACCOUNT = "Yes"

const SAVING_CURRENT_ACCOUNT = "No"

const IS_PASSWORD_CONFIGURED = "IS_PASSWORD_CONFIGURED";

const DOCUMENT_INFO = 'documentInfo';

const DATE_OF_INCORPORATION = 'dateOfIncorporation';

const storeToken = (token) => {
  localStorage.setItem(TOKEN, token)
}

const getToken = () => {
  return localStorage.getItem(TOKEN)
}

const logOut = () => {
  localStorage.clear();

  Mixpanel.track(trackString.LOGOUT);

  window.location.href = `${window.location.origin}/login`;
}

const getOrganizationID = () => {
  return 'JKSNIVEA';
}
const removeToken = () => {
  return localStorage.removeItem(TOKEN)
}

const getItem = (key) => {
  return localStorage.getItem(key)
}

const storeUserData = (userDetails) => {
  return localStorage.setItem(USER, JSON.stringify(userDetails))
}

const getUserData = () => {
  return localStorage.getItem(USER) && JSON.parse(localStorage.getItem(USER))
}

const getDetailsByName = (name) => {
  return localStorage.getItem(name) && JSON.parse(localStorage.getItem(name))
}

const getItemByName = (name) => {
  return localStorage.getItem(name);
}

const getUserEmailID = () => {
  const userDetails = JSON.parse(localStorage.getItem(USER));
  return userDetails.emailId;
}

const getUserPhone = () => {
  const userDetails = localStorage.getItem(USER) && JSON.parse(localStorage.getItem(USER));
  return userDetails && userDetails.phone;
}

const getTaxPayerInfo = () => {
  const taxPayerInfo = localStorage.getItem(TAX_PAYER_INFO) ? JSON.parse(localStorage.getItem(TAX_PAYER_INFO)) : {};
  return taxPayerInfo
}

const setBusinessPan = (panNumber) => {
  return localStorage.setItem(PAN_NUMBER, panNumber)
}

const setPersonalPanNumber = (panNumber) => {
  return localStorage.setItem(PERSONAL_PAN_NUMBER, panNumber)
}

const getBusinessPan = () => {
  return localStorage.getItem(PAN_NUMBER) ? localStorage.getItem(PAN_NUMBER) : ""
}

const getDistributorType = () => {
  let result = false;
  if (localStorage.getItem(TAX_PAYER_INFO)) {
    let { isExistingDistributor = false } = JSON.parse(localStorage.getItem(TAX_PAYER_INFO))
    result = isExistingDistributor
  }
  return result
}

const getPersonalPanNumber = () => {
  return localStorage.getItem(PERSONAL_PAN_NUMBER) ? localStorage.getItem(PERSONAL_PAN_NUMBER) : ""
}

const getDocumentInfo = () => {
  return localStorage.getItem(DOCUMENT_INFO) ? JSON.parse(localStorage.getItem(DOCUMENT_INFO)) : {}
}

const removeUserData = () => {
  return localStorage.removeItem(USER);
}

const setIsPasswordConfigured = (flag) => {
  return localStorage.setItem(IS_PASSWORD_CONFIGURED, flag)
}

const getIsPasswordConfigured = () => {
  return localStorage.getItem(IS_PASSWORD_CONFIGURED) ? JSON.parse(localStorage.getItem(IS_PASSWORD_CONFIGURED)) : false;
}

const setBusinessCode = (businessCode) => {
  localStorage.setItem("businessCode", businessCode);
}

const setDateOfIncorporation = (date) => {
  return localStorage.setItem(DATE_OF_INCORPORATION, date)
}

const getDateOfIncorporation = () => {
  return localStorage.getItem(DATE_OF_INCORPORATION) ? localStorage.getItem(DATE_OF_INCORPORATION) : ""
}

const growlMsg = async (title, type) => {
  await growl({
    title: title,
    timeout: 5000,
    type: type
  });
}

/**
 * Function for validating Each Form Fields.
 * @constant
 * @name validatingEachFields
 * @returns - true if condition satisfied / false if condition not-satisfied.
 */
const validatingEachFields = (currentValue) => currentValue !== "";

/**
 * Function for validating Entire Form results
 * @constant
 * @name validatingFormFields
 * @returns - true if every Fields are valid / false if any field is Invalid
 */
const validatingFormFields = (arrayValues) => {
  return arrayValues.every(validatingEachFields);
}


/**
 * Function for validating Each Form Fields.
 * @constant
 * @name validatingEmptyFields
 */
const validatingEmptyFields = (currentValue) => currentValue === false;


const validatingBusinessFormFields = (arrayValues) => {
  return arrayValues.every(validatingEmptyField);
}

const validatingEmptyField = (currentValue) => {
  return currentValue !== null && (currentValue !== "" || (currentValue && currentValue.length >= 0) || currentValue);
}

/**
 * Function for validating Entire Form results
 * @constant
 * @name validatingEmptyFormFields
 */
const validatingEmptyFormFields = (arrayValues) => {
  return arrayValues.every(validatingEmptyFields);
}

/**
 * @description Function for returns the strings comparison result in percentage...
 * @name stringCompare
 * @param {String} string1 
 * @param {String} string2 
 * @returns {Number} Returns 0 - 1.
 */
const stringCompare = (string1, string2) => {
  return stringSimilarity.compareTwoStrings(string1, string2);
}

/**
 * @description Function for validating error status of each fields in a form component
 * @name validateFormErrorStatus
 * @param {Array} arrayValues 
 * @returns {Boolean}
 */
const validateFormErrorStatus = (arrayValues) => {
  return arrayValues.every(item => item === false)
}

const validateIsDocumentVerified = (arrayValues) => {
  return arrayValues.every(item => item === true)
}

/**
 * @description Function for validating account type & Od limit
 * @param {String} accountType 
 * @param {Boolean} isValidODLimit 
 * @returns {Boolean}
 */
const isValidAccountInfo = (accountType, isValidODLimit) => {
  return ((accountType === OD_CC_ACCOUNT && isValidODLimit) || accountType === SAVING_CURRENT_ACCOUNT)
}

/**
 * @description Function for extracting Pan from GSTIN
 * @name extractPanNumber
 * @param {String} gst 
 * @returns {String}
 */
const extractPanNumber = (gst = "") => {
  // extracting pan from GSTIN 
  return gst.substr(2, 10)
}

/**
 * @description Function for extracting Pan type from GSTIN
 * @name extractPanTypeFromGSTIN
 * @param {String} gst 
 * @returns {String}
 */
const extractPanTypeFromGSTIN = (gst) => {
  return gst.charAt(5)
}

/**
 * @description Function for extracting Pan type
 * @name extractPanType
 * @param {String} pan 
 * @returns {String}
 */
const extractPanType = (pan) => {
  return pan.charAt(3)
}


/**
 * @description - default payload to avoid the app crash for existing customers due to non availability of new data from Database
 */
const stepInfoDefaultPayload = {
  businessForm: false,
  isVerifiedExceptGSTCertificate: false,
  itrReturnsForm: false,
  gstrReturnsForm: false,
  bankVerificationForm: false,
  summaryForm: false,
  perfiosScannedStatement: false,
  isVerifiedExceptStatement: false,
  bankData: {}
}
const storeDataFromDatabase = (key, payload) => {
  switch (key) {
    case "aadharDetails": if (!_.isEmpty(payload)) {
      localStorage.setItem("aadharDetails", JSON.stringify(payload));
    }
      break;

    case "taxpayerInfo": if (!_.isEmpty(payload)) {
      localStorage.setItem("taxpayerInfo", JSON.stringify(payload));
    }
      break;

    case "saleDeed": if (payload) {
      localStorage.setItem("saleDeed", payload);
    }
      break;

    case "gstCertificate":
      localStorage.setItem("gstCertificate", payload);
      break;

    case "PAN": if (payload) {
      localStorage.setItem("PAN", payload);
    }
      break;

    case "nocDocument": if (payload) {
      localStorage.setItem("nocDocument", payload);
    }
      break;

    case "stepInfo": if (!_.isEmpty(payload)) {
      localStorage.setItem("stepInfo", JSON.stringify({
        ...stepInfoDefaultPayload,
        ...payload
      }));
    }
      break;

    case "panNumber": if (payload && payload.panNo) {
      localStorage.setItem("panNumber", payload.panNo);
    }
      break;

    case "personalPanNumber": if (payload && payload.personalPanNumber) {
      localStorage.setItem("personalPanNumber", payload.personalPanNumber);
    }
      break;

    case "agreementDeed": if (payload) {
      localStorage.setItem("agreementDeed", payload);
    }
      break;

    case "partnershipCertificate": if (payload && payload.partnershipCertificate) {
      localStorage.setItem("partnershipCertificate", payload.partnershipCertificate);
    }
      break;

    case "itrInfo": if (payload && payload.length !== 0) {
      localStorage.setItem("itrInfo", JSON.stringify(payload));
    }
      break;

    case "gstReturns": localStorage.setItem("gstReturns", JSON.stringify(payload));
      break;

    case "parsedITR": if (payload && payload.length !== 0) {
      localStorage.setItem("parsedITR", JSON.stringify(payload));
    }
      break;

    case "parsedGSTR": if (payload && payload.length !== 0) {
      localStorage.setItem("parsedGSTR", JSON.stringify(payload));
    }
      break;

    case "statementFetch": localStorage.setItem("statementFetch", JSON.stringify(payload));
      break;

    case "ODaccount": localStorage.setItem("ODaccount", payload);
      break;

    case "ODLimit": localStorage.setItem("ODLimit", payload);
      break;

    case "companyDetails": localStorage.setItem("companyDetails", JSON.stringify(payload));
      break;

    case "dateOfIncorporation": localStorage.setItem("dateOfIncorporation", payload);
      break;

    case 'businessCode': localStorage.setItem("businessCode", payload);
      break;

    default: return;
  }
}


const getDataFromLocalStorage = (key) => {
  let result = [];
  switch (key) {
    case 'itrInfo':
      result = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
      break;
    case 'parsedITR':
      result = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
      break;
    case 'stepInfo':
      result = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {};
      break;
    case 'gstReturns':
      result = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
      break;
    case 'parsedGSTR':
      result = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
      break;
    case 'gstCertificate':
      result = localStorage.getItem(key) ? localStorage.getItem(key) : "";
      break;
    case 'getIsVerifiedExceptGST':
      let {
        isVerifiedExceptGSTCertificate = false
      } = localStorage.getItem(`stepInfo`) ? JSON.parse(localStorage.getItem(`stepInfo`)) : {};
      result = isVerifiedExceptGSTCertificate;
      break

    case 'noofDirectors':
      let {
        noofDirectors = []
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = noofDirectors;
      break

    case 'noofOfficers':
      let {
        noofOfficers = []
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = noofOfficers;
      break

    case 'incorporation':
      let {
        incorporation = []
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = incorporation;
      break
    
    case 'addresssofHinduUndivedFamily':
      let {
        addresssofHinduUndivedFamily = []
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = addresssofHinduUndivedFamily;
      break

    case 'ageofHinduUndivedFamily':
      let {
        ageofHinduUndivedFamily = []
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = ageofHinduUndivedFamily;
      break

    case 'nameofHinduUndivedFamily':
      let {
        nameofHinduUndivedFamily = []
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = nameofHinduUndivedFamily;
      break

    case 'panType':
      let {
        type = ""
      } = localStorage.getItem(`companyDetails`) ? JSON.parse(localStorage.getItem(`companyDetails`)) : {};
      result = type;
      break

    case 'statementFetch':
      result = localStorage.getItem('statementFetch') ? JSON.parse(localStorage.getItem('statementFetch')) : [];
      break;

    case 'ODaccount':
      result = localStorage.getItem(`ODaccount`) ? localStorage.getItem(`ODaccount`) : "";
      break;

    case 'ODLimit':
      result = localStorage.getItem(`ODLimit`) ? localStorage.getItem(`ODLimit`) : "";
      break;

    case 'bankDataFetchType':
      result = localStorage.getItem('bankDataFetchType') ? JSON.parse(localStorage.getItem('bankDataFetchType')) : 0;
      break;
    
    case 'perfiosScannedStatement' :
      result = localStorage.getItem('perfiosScannedStatement') ? localStorage.getItem('perfiosScannedStatement') : false;
      break;

    case 'bankData':
      result = localStorage.getItem('bankData') ? JSON.parse(localStorage.getItem('bankData')) : {}
      break;

    case 'businessCode':
      result = localStorage.getItem(key) ? localStorage.getItem(key) : "";
      break;

    default: result = "";
      break
  }
  return result
}

export const checkFileType = async (file, fileType) => {

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = async function (e) {

      const data = reader.result;

      const imageBase64 = data && data.split(',')[1];

      const imageBuffer = Buffer.from(imageBase64, 'base64')

      const result = await FileType.fromBuffer(imageBuffer);

      return (resolve(!result || !fileType.includes(result.mime)));

    }
    reader.readAsDataURL(file);
  });

}

/**
 * @description Function that validated uploaded document is in expected format type before processing it
 * @name acceptValidFileType
 * @param {Array} files 
 * @param {String} expectedFile 
 * @returns {Boolean} 
 */
export const acceptValidFileType = async (files, expectedFile) => {

  let isValid = true;

  for (let index = 0; index < files.length; index++) {

    const file = files[index];

    if (!file.type) return isValid = false;

    if (!(expectedFile && expectedFile.includes(file.type))) return isValid = false

    // const doubleExtensionCheck = await checkFileType(file, expectedFile);

    // if (doubleExtensionCheck) isValid = false;

  }

  return isValid;
}

export const utils =  {
  storeToken, getToken, logOut, getOrganizationID, removeToken, getItem, storeUserData, getUserData, getDetailsByName, getItemByName, getUserEmailID, getUserPhone, getTaxPayerInfo, setBusinessPan, setPersonalPanNumber, getBusinessPan, getDistributorType, getPersonalPanNumber, getDocumentInfo, removeUserData, setIsPasswordConfigured, getIsPasswordConfigured, setBusinessCode, setDateOfIncorporation, getDateOfIncorporation, growlMsg, validatingFormFields, validatingBusinessFormFields, validatingEmptyFormFields, stringCompare, validateFormErrorStatus, validateIsDocumentVerified, isValidAccountInfo, extractPanNumber, 
  extractPanTypeFromGSTIN, extractPanType, storeDataFromDatabase, getDataFromLocalStorage, acceptValidFileType
}