import React, { Fragment } from 'react'
import Divider from '@material-ui/core/Divider';

// FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTachometerAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


// Fetch and Delete Token
import { utils } from '../../utils/utils';

const { logOut, getUserData } = utils;

export default function SideBar() {

  /**
   * @const 
   * @name menuItem
   * @description - Menu Items
   */
  let menuItem = [
    { name: 'Dashboard', path: '/dashboard', icon: faTachometerAlt }
  ];

  /**
   * @const 
   * @name navigatetoURL
   * @param {pathName}  
   */
  const navigatetoURL = (pathName) => {
    window.location.href = `${window.location.origin}${pathName}`;
  }

  /**
   * @const user
   * @description - Fetch User Details
   */
  const user = getUserData();

  /**
   * @const 
   * @name logout
   */
  const logout = () => {
    window.confirm('Are you sure you want to log out?') &&
      logOut() && navigatetoURL('/login')
  }

  const fetchNameFromEmail = (emailID) => {
    if (emailID && emailID.includes("@")) {
      return emailID.split("@")[0]
    }
  }

  return (
    <Fragment>

      {/* Side Bar */}
      <div className="sidebar">
        <div className="textcenter">
          <img className='sidebarlogo' src={require('../../screens/assets/images/Logo.svg')} alt='logo' />
        </div>
        <Divider />
        <ul>
          {menuItem.map((field, index) =>
            <li name={field.path} key={index} className={field.path === window.location.pathname ? "menulink menuactiveLink" : "menulink"} onClick={() => navigatetoURL(field.path)}>
              <FontAwesomeIcon className="MenuIcon" icon={field.icon} /> {field.name}</li>
          )}
        </ul>

        <div className="FooterImage">
          <div className='textcenter'>
            <h5 className='commontext poweredBy'>Powered by</h5>
            <img src={require('../../screens/assets/images/actyv-logo.png')} alt='logo' height='100' />
          </div>
        </div>

      </div>

      {/* Main Header */}
      <div className="headbar flexRow">
        <div className="verticalAlign">
          <FontAwesomeIcon className="MenuIcon" icon={faUser} />
          <p className='nameText'>{user && user.ownerName ? user.ownerName : fetchNameFromEmail(user && user.emailId)}</p>
        </div>
        <div className="verticalAlign marginRightIcom pointer" onClick={() => logout()}>
          <FontAwesomeIcon className="MenuIcon" icon={faSignOutAlt} />
          <div>LOGOUT</div>
        </div>
      </div>
    </Fragment>
  )
}
