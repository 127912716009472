import _ from 'lodash';
import { utils } from '../../../../../utils/utils';
const { getDetailsByName } = utils;

const {
    languageStrings: {
        SUMMARY_SCREEN: {
            AADHAAR_CARD_COPY,
            PERSONAL_DETAILS,
            SUCCESSFUL_MESSAGE,
            BUSINESS_DETAILS,
            NIVEA_CUSTOMER_CODE,
            GSTIN,
            GST_CERTIFICATE,
            PERSONAL_PAN_NUMBER,
            PAN_CARD_COPY,
            LATEST_GSTR_FILES,
            LATEST_ITR_FILES,
            OD_CC_BANK_ACCOUNT,
            LATEST_BANK_STATEMENT,
            PLEASE_PROVIDE_VALID_GSTR_CERTIFICATE,
            PLEASE_PROVIDE_VALID_N_MONTH_BANK_STATEMENT,
            PROPERTY_DOCUMENT,
            PLEASE_PROVIDE_VALID_ITR_FILES,
            PLEASE_PROVIDE_VALID_GSTR_FILES,
            SCANNED_STATEMENT_PROCESSING_IS_IN_PROGRESS,
            PLEASE_COMPLETE_BANK_STATEMENT_VERIFICATION
        }
    }
} = require('../../../../../utils/enum/languageStrings');

const {
    localStorageStrings: {
        BANK_DATA_FETCH_TYPE
    }
} = require('../../../../../utils/enum/localStorageStrings');

const {
    fixedValues: {
        MAXIMUM_BANK_STATEMENT_MONTH
    }
} = require('../../../../../utils/enum/currency');

const DEFAULT_VERIFIED_STATUS = true;

const BUSINESS_SCREEN = 1;

const ITR_SCREEN = 2;

const GSTR_SCREEN = 3;

const BANK_STATEMENT_SCREEN = 4;

/**
 * @description Function for returning personal information document status
 * @name getPersonalInfoPayload
 * @returns {Object}
 */
export const getPersonalInfoPayload = () => {
    return {
        title: PERSONAL_DETAILS,
        documentsList: [{
            label: AADHAAR_CARD_COPY,
            isValid: DEFAULT_VERIFIED_STATUS,
            message: SUCCESSFUL_MESSAGE
        },
        {
            label: PERSONAL_DETAILS,
            isValid: DEFAULT_VERIFIED_STATUS,
            message: SUCCESSFUL_MESSAGE,
        }
        ]
    }
}

const getErrorMessageAndRedirection = (perfiosScannedDocumentStatus, redirectSpecificStep) => {
    const {
        status = '',
        reason = ''
    } = perfiosScannedDocumentStatus;

    let result;

    switch(status) {
        case 'INITIATED':
        case 'PENDING_MANUAL_PROCESSING': result =  {
            bankStatementErrorMessage: SCANNED_STATEMENT_PROCESSING_IS_IN_PROGRESS
        }
            break;

        case 'ERROR':
        case 'ERROR_SESSION_EXPIRED':
        case 'REJECTED':
        case 'ERROR_SITE': result = {
            bankStatementErrorMessage : reason ? `${reason}. ${PLEASE_COMPLETE_BANK_STATEMENT_VERIFICATION}` : PLEASE_COMPLETE_BANK_STATEMENT_VERIFICATION,
            onBankRedirect : () => {
                localStorage.setItem(BANK_DATA_FETCH_TYPE, 0);
                redirectSpecificStep(BANK_STATEMENT_SCREEN);
            }
        }
            break;
        default : result = {
            bankStatementErrorMessage: PLEASE_PROVIDE_VALID_N_MONTH_BANK_STATEMENT.replace(`{}`, MAXIMUM_BANK_STATEMENT_MONTH),
            onBankRedirect : () => {
                localStorage.setItem(BANK_DATA_FETCH_TYPE, 0);
                redirectSpecificStep(BANK_STATEMENT_SCREEN);
            }
        }
    }
    return result;
}

const getBankStatementPayload = (payload) => {
    const { perfiosScannedDocumentStatus={}, redirectSpecificStep } = payload;
    let result;
    if(_.isEmpty(perfiosScannedDocumentStatus)){
        result = {
            bankStatementErrorMessage : PLEASE_PROVIDE_VALID_N_MONTH_BANK_STATEMENT.replace(`{}`, MAXIMUM_BANK_STATEMENT_MONTH),
            onBankRedirect: () => {
                localStorage.setItem(BANK_DATA_FETCH_TYPE,0);
                redirectSpecificStep(BANK_STATEMENT_SCREEN);
            }
        }
    } else{
        result = getErrorMessageAndRedirection(perfiosScannedDocumentStatus, redirectSpecificStep);
    }

    return result;
}


/**
 * @description Function for returning business information document verification status
 * @name getBusinessInfoPayload
 * @returns {Object}
 */
export const getBusinessInfoPayload = (payload) => {

    const { redirectSpecificStep } = payload;

    const {
        businessForm,
        itrReturnsForm,
        gstrReturnsForm,
        bankVerificationForm
    } = getDetailsByName('stepInfo');

    const { bankStatementErrorMessage='', onBankRedirect='' } = getBankStatementPayload(payload);

    return {
        title: BUSINESS_DETAILS,
        documentsList: [
            {
                label: NIVEA_CUSTOMER_CODE,
                isValid: DEFAULT_VERIFIED_STATUS,
                message: SUCCESSFUL_MESSAGE,
                onRedirect: () => redirectSpecificStep(BUSINESS_SCREEN)
            },
            {
                label: GSTIN,
                isValid: DEFAULT_VERIFIED_STATUS,
                message: SUCCESSFUL_MESSAGE
            },
            {
                label: GST_CERTIFICATE,
                isValid: businessForm,
                message: businessForm ? SUCCESSFUL_MESSAGE : PLEASE_PROVIDE_VALID_GSTR_CERTIFICATE,
                onRedirect: () => redirectSpecificStep(BUSINESS_SCREEN)
            },
            {
                label: PERSONAL_PAN_NUMBER,
                isValid: DEFAULT_VERIFIED_STATUS,
                message: SUCCESSFUL_MESSAGE,
            },
            {
                label: PAN_CARD_COPY,
                isValid: DEFAULT_VERIFIED_STATUS,
                message: SUCCESSFUL_MESSAGE,
            },
            {
                label: PROPERTY_DOCUMENT,
                isValid: DEFAULT_VERIFIED_STATUS,
                message: SUCCESSFUL_MESSAGE
            },
            {
                label: LATEST_ITR_FILES,
                isValid: itrReturnsForm,
                message: itrReturnsForm ? SUCCESSFUL_MESSAGE : PLEASE_PROVIDE_VALID_ITR_FILES,
                onRedirect: () => redirectSpecificStep(ITR_SCREEN)
            },
            {
                label: LATEST_GSTR_FILES,
                isValid: gstrReturnsForm,
                message: gstrReturnsForm ? SUCCESSFUL_MESSAGE : PLEASE_PROVIDE_VALID_GSTR_FILES,
                onRedirect: () => redirectSpecificStep(GSTR_SCREEN)
            },
            {
                label: OD_CC_BANK_ACCOUNT,
                isValid: DEFAULT_VERIFIED_STATUS,
                message: SUCCESSFUL_MESSAGE,
            },
            {
                label: LATEST_BANK_STATEMENT,
                isValid: bankVerificationForm,
                message: bankVerificationForm ? SUCCESSFUL_MESSAGE : bankStatementErrorMessage,
                onRedirect : onBankRedirect
            }
        ]
    }
}


