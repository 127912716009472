import React, { Component } from 'react';

import Modal from 'react-awesome-modal';

import Loader from '../../../../utils/loader';

import { utils } from '../../../../utils/utils';

import { updateMsme } from '../../Component/service/index';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Snackbar from '../../../../utils/alert';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import moment from 'moment';

import {
    updateStepInfo, getDocumentsVerifiedGSTR3bDistributor,
    getUpdatedInputPayload, verifyCaptcha, checkBusinessIsMoreThanAYear,
    generateListOfPreviousMonthlyFiling, checkLatestFilingVerified
} from './service/index'

import { utilFunctions } from '../../../../utils'

import _ from 'lodash';

const { getTaxPayerInfo, getDataFromLocalStorage, storeDataFromDatabase, getDateOfIncorporation } = utils;

const {
    getDefaultFilingInfo,
    getGSTRMandatoryDocuments, getGSTRFiles,
    getAssessmentYearForGSTRValidation, isMandatoryDocumentsVerified,
    isGSTRFilingExpired, getMandatoryDocumentRange
} = utilFunctions;

const { languageStrings } = require('../../../../utils/enum/languageStrings')

const { fixedValues: { MANDATORY_GSTR_COUNT, DEFAULT_VALUE, SCREEN_NAME } } = require('../../../../utils/enum/currency')

const {
    GSTR_RETURNS: {
        SAVE_AND_PROCEED,
        SAVED_SUCCESSFULLY_MESSAGE,
        PROVIDE_LATEST_GSTR_3B_REQUIRED,
        FACING_ISSUE_WHILE_SAVE_AND_PROCEED,
        GST_RETURNS,
        INSTRUCTION_FOR_DOWNLOADING_GSTR_FILE,
        NOT_HAVING_GSTR_FOR_ELIGIBILITY
    }
} = languageStrings;

const {
    localStorageStrings: {
        GSTR_RETURNS_LABEL,
        PARSED_GSTR_LABEL,
        STEP_INFO_LABEL
    }
} = require('../../../../utils/enum/localStorageStrings');

const { GSTR_DISTRIBUTOR_GUIDE_PDF_URL } = require('../../../../utils/enum/index')

const { RenderModel, renderInputFields, renderGSTRVerification } = require('./views/components/index')

const { generateVerifyGSTRPayload } = require('../FormPayload/index')

export default class GSTrExpForm extends Component {

    constructor(props) {
        super(props);
        Mixpanel.track(trackString.VISITED_GSTR_VERIFICATION_SCREEN);
    }

    state = {
        popLoader: false,
        visible: false,
        loader: false,
        dateOfIncorporation: "",
        latestFilingMonths: [],
        isNotEligible: false,
        mandatoryFilingCount: MANDATORY_GSTR_COUNT,
        gstrOptionalMonthCount: DEFAULT_VALUE,
        message: "",
        fileUploadInfo: [],
        gstrNumber: "",
        parsedGSTR: getDataFromLocalStorage(PARSED_GSTR_LABEL),
        gstReturns: getDataFromLocalStorage(GSTR_RETURNS_LABEL),
        isGSTRFilingUpToDate : true,
        isGSTINVerified: false,
        isVisible: false,
        captchaCode: null,
        captchaImage: null,
        enableCaptchaVerification: false,
        isGstVerify: false,
        assessmentYear: {}
    }

    fileUploadRef = {}

    formatValidDate = (date) => moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');

    componentDidMount = async () => {

        const dateOfIncorporation = getDateOfIncorporation();

        const formattedBusinessStartDate = dateOfIncorporation ? new Date(dateOfIncorporation) : new Date();

        const gstInfo = await getTaxPayerInfo();

        const { gst = {}, filingData = [], verifiedOn = "" } = gstInfo || {};

        const isValidFilingData = isGSTRFilingExpired(verifiedOn);

        const { gstin = "", rgdt = "" } = gst || {};

        this.updateState({ gstrNumber: gstin });

        if (!isValidFilingData) {
            this.setState({ isGSTRFilingUpToDate: false })
            return false;
        }

        // fetching filing information from GSTR information
        const filingInfo = _.head(filingData) || [];

        const filteredGSTR3bFiling = filingInfo.filter((item) => item.rtntype === "GSTR3B");

        // INVALID GSTR 3B FILLING DATA
        if (_.isEmpty(filteredGSTR3bFiling)) return;

        this.setState({ popLoader: true });

        const verifiedGSTR3bDocuments = await getDocumentsVerifiedGSTR3bDistributor({
            showErrorGrowl: this.renderErrorGrowl
        });

        // initializing latestFilingMonths
        let updatedFilingInfo = [];

        updatedFilingInfo = getDefaultFilingInfo(filteredGSTR3bFiling);

        const isBusinessMoreThanAYear = checkBusinessIsMoreThanAYear({
            dateOfIncorporation: this.formatValidDate(formattedBusinessStartDate),
            registrationDate: this.formatValidDate(rgdt)
        });

        /**
         * 
         * 1. If business is less than one year then collect filing information received from the GST portal
         * 
         * 2. Else populate the first 3 month filing dynamically.
         * 
         * 3. In the submit flow validate the 12 month filing is verified successfully then allow the 
         *    distributor to complete the assessment.
         * 
         */
        if (isBusinessMoreThanAYear) {

            // need to update filing months for collecting the latest 12 months filing.
            let newFilingInfo = generateListOfPreviousMonthlyFiling(updatedFilingInfo);
            updatedFilingInfo = [...newFilingInfo, ...updatedFilingInfo].map((item, position) => {
                return { ...item, name: 'file{}'.replace(`{}`, position) }
            })

            // check the first month is monthly or quarterly filling??

            // if monthly then need to get previous 2 month filing
            // And check those are quarterly or monthly
            // if quarterly then have monthly & quarterly filing & mark it non-mandatory
            // else collect only monthly filling

            // else in case of quarterly collect the necessary 2 months previous filling

        }

        const assessmentYear = getAssessmentYearForGSTRValidation(filteredGSTR3bFiling)

        const updatedFilePayloadInfo = getUpdatedInputPayload(updatedFilingInfo, verifiedGSTR3bDocuments);

        const gstrFileURLs = getGSTRFiles(updatedFilePayloadInfo);

        await storeDataFromDatabase(GSTR_RETURNS_LABEL, gstrFileURLs);

        this.setState({
            popLoader: false,
            fileUploadInfo: updatedFilePayloadInfo,
            gstrNumber: gstin,
            latestFilingMonths: updatedFilingInfo,
            assessmentYear,
            isValidGSTR3bFilingData: !_.isEmpty(filteredGSTR3bFiling),
            isGSTRFilingUpToDate: true,
            isBusinessMoreThanAYear
        });
    }

    IncExpFormSubmit = async () => {

        try {

            let payload = { gstReturns: [] };

            this.setState({ popLoader: true })

            const { fileUploadInfo = [], assessmentYear = {}, isBusinessMoreThanAYear } = this.state;

            const mandatoryDocuments = getGSTRMandatoryDocuments({ fileUploadInfo });

            const isAllMandatoryDocumentsVerified = isMandatoryDocumentsVerified(mandatoryDocuments)

            const isAllGSTRDocumentsVerified = isBusinessMoreThanAYear ?
                checkLatestFilingVerified(fileUploadInfo, isAllMandatoryDocumentsVerified)
                : isAllMandatoryDocumentsVerified;

            const gstrFileURLs = getGSTRFiles(fileUploadInfo);

            payload.gstReturns = gstrFileURLs;

            payload.screenName = SCREEN_NAME.GSTR_3B_FORM;

            payload.isAllGSTRDocVerified = isAllGSTRDocumentsVerified;

            payload.assessmentYear = assessmentYear

            await updateStepInfo(fileUploadInfo, isAllGSTRDocumentsVerified);

            let updatedStepInfo = getDataFromLocalStorage(STEP_INFO_LABEL)

            await updateMsme({
                formInfo: { ...payload, stepInfo: updatedStepInfo },
                props: this.props,
                updateState: this.updateState
            });

            this.renderSuccessGrowl(SAVED_SUCCESSFULLY_MESSAGE)

            this.setState({ popLoader: false })

        } catch (err) {
            this.renderErrorGrowl(FACING_ISSUE_WHILE_SAVE_AND_PROCEED);
        } finally {
            this.setState({ popLoader: false })
        }
    }

    updateState = (data) => {
        this.setState(data);
    }

    closePopModal = () => {
        this.updateState({ popLoader: false })
    }

    renderPopLoader = () => {
        return <Modal visible={this.state.popLoader} effect="fadeInUp">
            <div className="padding50">
                <div className="textcenter">
                    <Loader />
                </div>
            </div>
        </Modal>
    }

    truncateFileUpload = (name) => {
        this.fileUploadRef[`${name}`].value = null;
    }

    renderUserGuide = () => (
        <label className="ahrefLink"
            onClick={() => window.open(GSTR_DISTRIBUTOR_GUIDE_PDF_URL)}>
            {INSTRUCTION_FOR_DOWNLOADING_GSTR_FILE}
        </label>
    )

    instructionCard = () => {
        return (
            <div class="BusinessFormField">
                <span className='flexlabel'>
                    <label className='flexlabel textLeft'><strong>{`Note : `}&nbsp;</strong></label>
                    {this.renderUserGuide()}
                </span>
            </div>
        )
    }

    renderTitle = () => {
        return <div className="textcenter">
            <h3 className="subtitle formtitleMargin">{GST_RETURNS}</h3>
        </div>
    }

    renderErrorGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
    }

    renderSuccessGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
    }

    handleClose = () => { this.setState({ showGrowl: false }); };


    renderGSTRInstruction = (isValidFilingInfo) => {

        const { fileUploadInfo = [] } = this.state;

        let instructionMessage = PROVIDE_LATEST_GSTR_3B_REQUIRED;

        const updatedLabel = instructionMessage.replace(`{}`, getMandatoryDocumentRange(fileUploadInfo));

        return <div className='IncExpFormField'>
            {
                isValidFilingInfo
                    ? <label className='flexlabel'>{updatedLabel} <div className="mandatory">*</div></label>
                    : <label className='flexlabel'>{NOT_HAVING_GSTR_FOR_ELIGIBILITY}</label>
            }
        </div>
    }

    closeModal = () => {
        this.setState({
            loader: false, enableCaptchaVerification: false,
            captchaCode: "", captchaImage: ""
        });
    }

    onValueChange = event => {
        let { name, value } = event.target
        this.setState({ [name]: value })
    }

    onFilingPeriodChange = (event) => {
        const { fileUploadInfo = [] } = this.state;
        let { name: fileInputName = "", value = "" } = event.target;
        const updatedFileUploadInfo = fileUploadInfo.map((item) => {
            const { name = "" } = item;
            if (name === fileInputName) {
                return { ...item, filingMonth: value }
            }
            return item
        })
        this.setState({ fileUploadInfo: updatedFileUploadInfo })
    }

    render() {

        const {
            showGrowl, growlStatus, growlMsg, fileUploadInfo = [],
            isGSTRFilingUpToDate = true, loader = false, enableCaptchaVerification = false
        } = this.state;

        const defaultFileUploadOptions = { accept: "application/pdf,", fileType: 'PDF', icon: faFilePdf }

        const isValidFilingInfo = !_.isEmpty(fileUploadInfo);

        const payload = {
            state: this.state,
            renderErrorGrowl: this.renderErrorGrowl,
            renderSuccessGrowl: this.renderSuccessGrowl,
            updateState: this.updateState
        }

        const gstrPayload = generateVerifyGSTRPayload(payload);

        return (
            <div className={"msmeOnboardingContainer"} id="formStepper">

                <RenderModel
                    state={this.state}
                    isVisible={enableCaptchaVerification}
                    closeModal={this.closeModal}
                    onValueChange={this.onValueChange}
                    initiateVerification={() => verifyCaptcha({
                        state: this.state,
                        renderErrorGrowl: this.renderErrorGrowl,
                        renderSuccessGrowl: this.renderSuccessGrowl,
                        updateState: this.updateState
                    })}
                />

                {this.renderTitle()}

                <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />

                <div className={'businessDetails'}>

                    {this.renderPopLoader()}

                    {this.instructionCard()}

                    {isGSTRFilingUpToDate === false && renderGSTRVerification(gstrPayload)}

                    {isGSTRFilingUpToDate && !loader && this.renderGSTRInstruction(isValidFilingInfo)}

                    {isGSTRFilingUpToDate && fileUploadInfo.map((field, index) => renderInputFields({
                        state: this.state,
                        inputFields: {
                            ...field,
                            ...defaultFileUploadOptions,
                            updateState: this.updateState,
                            renderSuccessGrowl: this.renderSuccessGrowl,
                            renderErrorGrowl: this.renderErrorGrowl,
                            truncateFileUpload: this.truncateFileUpload,
                            fileUploadRef: this.fileUploadRef,
                            onFilingPeriodChange: this.onFilingPeriodChange
                        },
                        index
                    }))}

                    {
                        (loader) ?
                            <Loader /> :
                            isGSTRFilingUpToDate && isValidFilingInfo &&
                            <p className={"combutton"} onClick={() => this.IncExpFormSubmit()} >
                                {SAVE_AND_PROCEED}
                            </p>
                    }

                </div>
            </div>

        );
    }

}
