const {
    fixedValues: {
        MAXIMUM_BANK_STATEMENT_MONTH
    }
} = require('./currency');

export const languageStrings = {

    // onBoarding screen
    ONBOARDING: {
        WELCOME: "Welcome,",
        START_ONBOARDING: "Start Onboarding",
        ONBOARDING_MSG: "to the Nivea Distributor Onboarding Process",
        BEFORE_START_ASSESSMENT: 'Before you start assessment:',
        PLEASE_KEEP_DOCUMENTS_READY_BEFORE_START_ASSESSMENT: "Please keep the following documents / credentials ready, as applicable to you, your Firm/Company",
        SUGGESTED_APP_FOR_SCANNING: "Note : Suggested application for Scanning - Adobe Scan",
        ADOBE_PLAY_STORE_LINK: "Adobe Scan Play store link :",
        DOCUMENT: "Documents",
        DIGITAL_OR_SCANNED_COPY: "Digital/Scanned copy",
        AADHAAR_CARD: "Aadhaar Card",
        AADHAAR_FRONT_COPY: "Scanned Copy of Front Side (png/ jpeg/ jpg)",
        AADHAAR_BACK_COPY: "Scanned Copy of Back Side (png/ jpeg/ jpg)",
        GST_REGISTRATION_CERTIFICATE: "GST Registration Certificate",
        DIGITAL_COPY_FROM_GST_HOME: "Digital Copy (pdf) directly downloaded from the Goods & Services Tax (GST) | Home website",
        PAN_CARD: "Pan Card",
        SCANNED_COPY_PNG_JPEG_JPG: "Scanned copy (png/ jpeg/ jpg)",
        SCANNED_COPY_PDF_PNG_JPEG_JPG: "Scanned copy (pdf/ png/ jpeg/ jpg)",
        SCANNED_COPY_PDF: "Scanned copy (pdf)",
        SHOP_AND_ESTABLISHMENT_CERTIFICATE: "Shop and Establishment Certificate",
        SALES_DEED_DOCUMENT: "Sale Deed document / Property tax paid receipt / Electricity bill copy / Rental Agreement",
        PUBLIC_AND_PRIVATE_COMPANY: "In case of Public limited Company/ Private Limited Company - Memorandum of Association & Articles of Association",
        IN_CASE_OF_PARTNERSHIP_DEED_DOCUMENT: "In case of Partnership Firm - Partnership Deed Document",
        IN_CASE_OF_PARTNERSHIP_REG_CERTIFICATE: "In case of Partnership Firm - Certificate of Registration - Not mandatory",
        LATEST_2_YEAR_ITR: "Latest 2 year ITR with Balance sheet, P & L, with schedules",
        GET_DIGITAL_COPY_FROM: "Digital Copy (pdf) directly downloaded from",
        WEBSITE: "website",
        OR_LABEL: "(or)",
        KEEP_YOUR_NET_BANKING_CREDENTIALS_READY: "Keep your Net banking credentials (username/password) ready to provide in the respective section",
        LATEST_1_YEAR_GST_RETURNS: "Latest 1 year GST Returns (GST3B)",
        LATEST_N_MONTHS_BANK_STATEMENT: `Latest ${MAXIMUM_BANK_STATEMENT_MONTH} months bank statement`,
        DIGITAL_COPY_FROM_NET_BANKING_PORTAL: "Digital Copy (pdf) downloaded from the NetBanking Portal",
        OD_CC_LIMIT_INFORMATION: `Note: If the Overdraft/Cash credit facility is availed, upload the OD/CC statement with the limit, 
        else, upload the statement for the Current/savings account.`,
        NOTES_GSTR_INSTRUCTION: "Note: Minimum 9 months GSTR3B required for onboarding.",
        SCANNED_PDF_INSTRUCTION: 'Scanned copy (pdf) of the statement, which should be a single file with the maximum file size of 10MB',
    },
    ITR_RETURNS: {
        SAVE_AND_PROCEED: "Save & Proceed",
        SUCCESSFULLY_UPLOADED: "Successful Upload!",
        PROVIDE_LATEST_YEAR_ITR_FILES_REQUIRED: "Provide latest year ITR file ({})",
        INCOME_TAX_RETURNS: "Income Tax Returns",
        SAVED_SUCCESSFULLY_MESSAGE: "Saved successfully!",
        FACING_ISSUE_WHILE_SAVE_AND_PROCEED: "Facing issue while saving. Try after sometime!",
        FACING_ISSUE_WHILE_VERIFYING_INDIVIDUAL_ITR: "Facing issue while verifying, Try after sometime!",
        FACING_ISSUE_WHILE_UPLOADING: 'Facing issue while uploading, Try after sometime!',
        INSTRUCTION_FOR_DOWNLOADING_ITR_FILE: "Instructions for downloading the valid ITR file",
        KINDLY_UPLOAD_PROPER_PDF_FILE: 'Kindly upload proper PDF document!'
    },
    GSTR_RETURNS: {
        SAVE_AND_PROCEED: "Save & Proceed",
        SAVED_SUCCESSFULLY_MESSAGE: "Saved successfully!",
        PROVIDE_LATEST_GSTR_3B_REQUIRED: "Provide latest GSTr-3B ({})",
        FACING_ISSUE_WHILE_SAVE_AND_PROCEED: "Facing issue while saving. Try after sometime!",
        GST_RETURNS: "GST Returns",
        FACING_ISSUE_WHILE_VERIFYING_INDIVIDUAL_GSTR: "Facing issue while verifying, Try after sometime!",
        FACING_ISSUE_WHILE_UPLOADING: 'Facing issue while uploading, Try after sometime!',
        SUCCESSFULLY_UPLOADED: "Successful Upload!",
        INSTRUCTION_FOR_DOWNLOADING_GSTR_FILE: "Instructions for downloading the valid GSTR file",
        FACING_ISSUE_WHILE_GETTING_EXISTING_VERIFIED_DOCUMENTS: 'Facing issue while getting existing verified documents',
        VERIFIED: 'Verified',
        KINDLY_UPLOAD_PROPER_PDF_FILE: 'Kindly upload proper PDF document!',
        NOT_HAVING_GSTR_FOR_ELIGIBILITY: "Minimum required age is 1 months from the date of GST registration to proceed the assessment",
        ENTER_CAPTCHA_CODE: 'Enter captcha code',
        SOMETHING_WENT_WRONG: 'Something went wrong,Try after sometime!',
        GST_VERIFY_MSG: "GST Verified Successfully",
        GST_VERIFY_ERROR: "There is an issue in verifying GST Number . Please try again after some time"
    },
    BANK_STATEMENT_VERIFICATION: {
        SAVE_AND_PROCEED: "Save & Proceed",
        BANK_DETAILS: "Bank Details",
        PROCEED: "Proceed",
        SCANNED_PDF_UPLOAD: "Scanned PDF Upload",
        LOG_IN_NET_BANKING: "Login NetBanking",
        DIGITAL_PDF_UPLOAD: "Digital PDF Upload",
        ACCOUNT_TYPE: "Account Type",
        BANK_SUMMARY_INFORMATION: "Bank Summary Information",
        ISSUE_IN_FETCHING_BANK_NAMES: 'Facing issue while fetching list of supported Banks, please try after some time.',
        SELECT_THE_BANK_NAME: 'Select the Bank name',
        PLEASE_SELECT_ACCOUNT_TYPE: 'Please choose the account type',
        PLEASE_ENTER_VALID_OD_CC_LIMIT: 'Please enter the valid OD/CC limit',
        FILE_SIZE_IS_TOO_LARGE: 'File size should be less than 10MB',
        FACING_ISSUE_WHILE_UPLOADING_FILE: 'Facing issue while uploading file, please try again',
        FACING_ISSUE_WHILE_FETCHING_BANK_STATEMENT: 'There is an issue in fetching Bank Statement. Please try again after some time',
        BANK_STATEMENT_FETCHED_SUCCESSFULLY: "Bank Statement Fetched SuccessFully",
        FAILED_TO_INITIATE_SCANNED_STATEMENT_PROCESSING: 'Failed to initiate scanned statement processing',
        FAILED_TO_PROCESS_UPLOADED_FILE: "Failed to process the uploaded file",
        FAILED_TO_CANCEL_SCANNED_STATEMENT_TRANSACTION: "Failed to cancel the scanned statement transaction",
        FAILED_TO_DELETE_SCANNED_STATEMENT_TRANSACTION: "Failed to delete the scanned statement transaction",
        FAILED_TO_INITIATE_STATEMENT_REPORT_GENERATION: "Failed to initiate scanned statement report generation",
        SCANNED_STATEMENT_PROCESSING_IS_INITIATED: "Scanned statement processing is initiated",
        FAILED_TO_UPLOAD_SCANNED_STATEMENT: "Failed to upload scanned statement",
        BANK_STATEMENT_PROCESSING_IN_PROGRESS: 'Bank statement processing is in progress. Please proceed to Summary screen.',
        PROVIDE_VALID_OD_CC_STATEMENT: "Please provide the valid OD/CC statement to proceed with the assessment",
        PROVIDE_VALID_OD_CC_LIMIT: "Please provide the valid OD/CC limit by selecting OD/CC account type",
        SCANNED_STATEMENT_WARNING_MESSAGE: "There was a problem in processing your bank statement. Please upload the file below for a final try.",
        NOTE_FOR_SCANNED_STATEMENT_UPLOAD: "Note: Please upload a single PDF file. You can upload either Digital PDF or Scanned PDF statement.",
        PLEASE_SELECT_VALID_BANK_NAME: 'Please select valid bank name'
    },
    SUMMARY_SCREEN: {
        SUMMARY_DETAILS: "Summary Details",
        COMPLETE_ASSESSMENT: "Complete Assessment",
        SUCCESSFUL_MESSAGE: "SUCCESSFUL",
        AADHAAR_CARD_COPY: "Aadhaar Card Copy",
        PERSONAL_DETAILS: "Personal Details",
        BUSINESS_DETAILS: "Business Details",
        NIVEA_CUSTOMER_CODE: "Nivea Customer Code",
        GSTIN: "GSTIN",
        GST_CERTIFICATE: "GST Certificate",
        PERSONAL_PAN_NUMBER: "Personal PAN No",
        PAN_CARD_COPY: "PAN Copy",
        PROPERTY_DOCUMENT: "Property Document",
        LATEST_ITR_FILES: "Latest ITR files",
        LATEST_GSTR_FILES: "Latest GSTr files",
        OD_CC_BANK_ACCOUNT: "OD/CC Account",
        LATEST_BANK_STATEMENT: "Latest Bank Statement",
        PLEASE_PROVIDE_VALID_GSTR_CERTIFICATE: "Please provide the valid GSTr certificate",
        PLEASE_PROVIDE_VALID_ITR_FILES: "Please provide the valid ITR files",
        PLEASE_PROVIDE_VALID_GSTR_FILES: "Please provide the valid GSTr files",
        PLEASE_PROVIDE_VALID_N_MONTH_BANK_STATEMENT: "Please provide the valid {} month bank statement",
        ASSESSMENT_COMPLETED_SUCCESSFULLY: "Assessment completed successfully!",
        WE_ARE_FACING_ISSUE_TRY_AFTER_SOMETIME: "We are facing issue, Try after sometime",
        ERROR_IN_FETCHING_BANK_TRANSACTION_STATUS: "Error in fetching the transaction status, please retry",
        ERROR_IN_FETCHING_SCANNED_STATEMENT_DATA: "Error in fetching scanned statement data",
        SCANNED_STATEMENT_PROCESSING_IS_IN_PROGRESS: 'Scanned statement processing is in progress',
        PLEASE_COMPLETE_BANK_STATEMENT_VERIFICATION: 'Please complete the bank statement verification',
        PROVIDE_VALID_OD_CC_STATEMENT: "Please provide the valid OD/CC statement to proceed with the assessment",
        PROVIDE_VALID_OD_CC_LIMIT: "Please provide the valid OD/CC limit by selecting OD/CC account type",
        SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_DATA: "Successfully fetched and stored bank statement data",
        INVALID_BANK_STATEMENT_DATA: 'Invalid Bank statement data'
    },
    AADHAAR_SCREEN: {
        REQUIRED_TO_VERIFY_IDENTITY: "This is required to verify your identity",
        ENTER_OTP: "Enter OTP",
        ENTER_AADHAAR_OTP: "Enter Aadhaar OTP",
        ENTER_AADHAAR_NUMBER: "Enter Aadhaar number",
        PLEASE_PROVIDE_YOUR_AADHAAR_NUMBER: 'Please provide your Aadhaar Number',
        NAME: "Name",
        DOB: "D.O.B",
        GENDER: "Gender",
        FATHER_NAME: "Father's Name",
        ADDRESS: "Address",
        SUBMIT: "Submit",
        PERSONAL_DETAILS: "Personal Details",
        RESEND_OTP: "Resend OTP",
        VERIFY_USING_OTP: "Verify Using OTP",
        VERIFY: "Verify",
        SKIP_OTP_STRING: "I don't have access to registered mobile number. Skip Verification",
        ENTER_ALL_MANDATORY_FIELDS: "Kindly enter all the mandatory fields",
        PLEASE_ENTER_THE_VALID_DATA: 'Please enter the valid data',
        PLEASE_UPLOAD_A_VALID_DOCUMENT: 'Please upload a valid document',
        FILE_SIZE_IS_TOO_LARGE: 'File Size Is Too Large, Compress and Upload',
        DOCUMENT_VALIDATED_SUCCESSFULLY: "Document validated successfully"
    },

    BUSINESS_FORM : {
        BUSINESS_DETAILS : 'Business Details',
        COMPANY_TYPE : 'Company Type',
        OWNERSHIP_OF_PROPERTY : 'Ownership of the Property (Office/Godown)',
        DISTRIBUTOR_TYPE : 'Distributor Type',
        SAVE_AND_PROCEED : 'Save & Proceed',
        ERROR_IN_FILE_UPLOAD : 'Error in file upload',
        ERROR_IN_FETCHING_GST_NUMBER : 'Error in fetching GST number from PDF',
        GST_NUMBER_IS_MISMATCHED : 'GST Number is Mismatched',
        GST_CERTIFICATE_VERIFIED_SUCCESSFULLY : 'GST certificate verified successfully',
        GST_VERIFIED_SUCCESSFULLY : 'GST Verified Successfully',
        ENTER_VALID_PAN : 'Enter a valid PAN',
        BUSINESS_PAN_VERIFIED_SUCCESSFUL : `Business Pan verified successfully.`,
        PERSONAL_PAN_VERIFIED_SUCCESSFUL : `Personal Pan verified successfully.`,
        ENTER_CAPTCHA_CODE : 'Enter captcha code',
        SOMETHING_WENT_WRONG : 'Something went wrong',
        KINDLY_VERIFY_GST : 'Kindly verify GST number',
        FACING_ISSUE_WHILE_VERIFYING_CAPTCHA : `Facing issue while verifying captcha!`,
        ENTER_ALL_MANDATORY_FIELDS : "Enter all mandatory fields",
        SECURITY_AUTHENTICATION_FAILED : 'Security Authentication failed due to some technical issue, try again later.',
        PLEASE_ENTER_VALID_PERSONAL_PAN : 'Please Enter a valid personal PAN',
        WE_ARE_FACING_ISSUE_WHILE_UPLOADING_DOCUMENT : 'We are facing issue while uploading documents',
        ENTER_VALID_GSTIN : 'Enter a valid GSTIN',
        PRIVATE_LIMITED_COMPANY : 'Private Limited Company',
        PUBLIC_LIMITED_COMPANY : 'Public Limited Company',
        HINDU_UNDIVIDED_FAMILY : 'Hindu Undivided Family',
        VERIFY : 'Verify',
        VERIFIED : 'Verified',
        SUCCESSFULLY_UPLOADED: "Successful Upload!",
        USER_NOT_ELIGIBLE_DUE_TO_BUSINESS_VINTAGE_BUSINESS_PAN: "You're not eligible for assessment, minimum business vintage required for assessment is 2 years",
        USER_NOT_ELIGIBLE_DUE_TO_BUSINESS_VINTAGE_PERSONAL_PAN: "You're not eligible for assessment, minimum business vintage required for assessment is 10 months"
    },

    CRIF_FORM : {
        PLEASE_SELECT_RIGHT_OPTION : `Please select the right answer.`,
        SECURITY_QUESTION_FAILED : 'Security Question failed',
        SECURITY_QUESTION_RESOLVED : 'Security question resolved',
        FACING_ISSUE_WHILE_RESOLVING_CRIF : 'Facing issue while resolving security question, Please try again later.',
        SECURITY_QUESTIONS : 'Security Questions',
        NOTE1 : 'Note: This is to validate your identity and verify the authenticity of the information you have submitted.',
        ENTER_CRIF_ANSWER : 'Enter CRIF answer',
        NOTE2 : 'Note: Please make sure you choose correct answer. Choosing a wrong answer might cause a significant delay in your FINFIT assessment processing.',
        SUBMIT_ANSWER : 'Submit Answer',
        
    },

    LOGIN_SCREEN: {
        KINDLY_ENTER_ALL_FIELDS: "Kindly All Required Fields",
        OTP_SENT_SUCCESSFULLY: 'OTP sent successfully',
        ISSUE_WHILE_LOGIN: `There is an issue with login. Please try again after some time`,
        INCORRECT_OTP: `Incorrect OTP`,
        LOGIN: 'Login',
        RESEND_OTP: 'Resend OTP',
        FORGOT_PASSWORD: 'Forgot Password',
        POWERED_BY: 'Powered by',
        COPYRIGHT_STRING: 'Copyright © 2021, All rights Reserved.'
    },

    SET_PASSWORD_SCREEN: {
        PASSWORD_CREATED_SUCCESSFULLY: `Password created successfully`,
        ERROR_WHILE_CREATING_PASSWORD: `We are facing issue while creating distributor password, Try after sometime!`,
        SET_PASSWORD: 'Set Password',
        CONFIRM: "Confirm",
        POWERED_BY: 'Powered by',
        COPYRIGHT_STRING: 'Copyright © 2021, All rights Reserved.'
    }

}