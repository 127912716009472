import React from 'react';

import { withRouter } from 'react-router-dom';

import TermsandCondition from './index';

import Footer from '../../components/Footer/index';

function TermsandConditionContainer(props) {
  return (
    <div className="fulldocument">
      <TermsandCondition history={props.history}/>
      <Footer/>
    </div>
  )
}

export default withRouter(TermsandConditionContainer)