import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AdhaarForm from '../../screens/MSMEOnboarding/Component/AdhaarForm/index';
import BusinessForm from '../../screens/MSMEOnboarding/Component/BusinessForm/index';
import BankForm from '../../screens/MSMEOnboarding/Component/BankForm/index';
import CrifQuestionnaire from '../../screens/MSMEOnboarding/Component/CrifQuestionnaire/index';
import SummaryForm from '../../screens/MSMEOnboarding/Component/SummaryForm/index';
import ITRExpForm from '../../screens/MSMEOnboarding/Component/ITRExpForm/index';
import GSTrExpForm from '../../screens/MSMEOnboarding/Component/GSTrExpForm/index';
import StepperLayout from '../../utils/stepper';
import { utils } from '../../utils/utils';
import '../../screens/MSMEOnboarding/styles/index.css';

const { getItemByName } = utils;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  const crifStep = localStorage.getItem("crifStep")
  if (crifStep === "true") {
    return ['Personal Details', 'Business Details', 'Security Questions', 'ITR', 'GSTR', 'Bank Details', 'Summary']
  }
  return ['Personal Details', 'Business Details', 'ITR', 'GSTR', 'Bank Details', 'Summary'];
}


const getActiveStepIndex = () => {
  return getItemByName('activeStep') ? Number(getItemByName('activeStep')) : 0;
}


export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(getActiveStepIndex());

  let crifStep = localStorage.getItem("crifStep");

  React.useEffect(() => {
    localStorage.setItem("crifStep", crifStep)
  })

  const steps = getSteps();

  const handleNext = () => {
    localStorage.setItem('activeStep', activeStep + 1);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const redirectSpecificStep = (index) => {

    localStorage.setItem('activeStep', index);

    setActiveStep(index);
  };

  const handleBack = () => {

    localStorage.setItem('activeStep', activeStep - 1);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleReset = () => { setActiveStep(0); };

  const getStepContent = (stepName) => {
    switch (stepName) {
      case 'Personal Details':
        return <AdhaarForm handleNext={handleNext} />;
      case 'Business Details':
        return <BusinessForm handleNext={handleNext} />;
      case 'Bank Details':
        return <BankForm handleNext={handleNext} />;
      case 'Security Questions':
        return <CrifQuestionnaire handleBack={handleBack} handleNext={handleNext} />
      case 'ITR':
        return <ITRExpForm handleNext={handleNext} />;
      case 'GSTR':
        return <GSTrExpForm handleNext={handleNext} />;
      case 'Summary':
        return <SummaryForm handleNext={handleNext} redirectSpecificStep={redirectSpecificStep} />;
      default:
        return <SummaryForm handleNext={handleNext} redirectSpecificStep={redirectSpecificStep} />;
    }
  }

  return (
    <div className={classes.root}>

      <StepperLayout activeStep={activeStep} steps={steps} />

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(steps[activeStep])}</Typography> </div>
          )}
      </div>
    </div>
  );
}
