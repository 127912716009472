const mixpanel = require('mixpanel-browser');

let actions = {
    // To initialize the mix-panel track
    init: (id) => {
        mixpanel.init(id);
    },
    // To identify each track belongs to which user
    identify: (id) => {
        mixpanel.identify(id);
    },
    // To track the actions / events belongs to different users
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    // resetting mix-panel config while logging out
    reset: () => mixpanel.reset(),
    // can get the UNIQUE ID that has been assigned to the individual user
    getDistinctID: () => mixpanel.get_distinct_id()
};

export const Mixpanel = actions;