import React from 'react';

const VerifyIcon = (props) => {
    let { isVerify = false } = props;
    return <div style={{ alignSelf: "center", marginBottom: 20 }}>
        <img src={(isVerify ?
            require('../../screens/assets/images/right.jpeg') :
            require('../../screens/assets/images/wrong.jpg'))} alt='logo' className='iconImg' />
    </div>
}

export default VerifyIcon;