import React from 'react';

import './App.css';

import {
    IconButton,
    FormControl,
    makeStyles,
    OutlinedInput,
    InputAdornment,
} from '@material-ui/core';

import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons';

/**
 * @description Function to creating password input field as functional component.
 * @name ActyvPassword
 * @param {Object} payload 
 */
export const ActyvPassword = (payload) => {
    const useStyles = makeStyles({
        input: {
            fontSize: 15,
            lineHeight: 1.5,
            backgroundColor: "#fff",
            height: 8,
            borderRadius: 5,
            alignItems: 'center',
            alignContent: 'center',
        },
        background: {
            backgroundColor: "#fff",
            height: "10",
            width: "180%",
            marginLeft: "-40%",
            borderRadius: 5
        },
        labelStyle: {
            color: "#fff",
            marginTop: 10
        }
        
    });
    const {
        label, maxlength = 15, placeholder = "", name,
        showPassword, value, handleClickShowPassword,
        showPasswordLabel, errorMessage = "",
        handleMouseDownPassword, handleChange, errorStatus = false } = payload || {};
    const classes = useStyles();
    return (
        <div>
            <label className="passwordLabelStyle">{label}</label>
            <FormControl classes={{ root: classes.background }} >
                <OutlinedInput
                    name={name}
                    classes={{ input: classes.input }}
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={(value) => handleChange(value)}
                    placeholder={placeholder}
                    error={errorStatus}
                    inputProps={{
                        maxlength: maxlength
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(showPasswordLabel)}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {(errorStatus || errorMessage) && <label className="passwordLabelStyle">{errorMessage}</label>}
        </div>
    )
}