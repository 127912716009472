import React, { Component, Fragment } from 'react';

import Modal from 'react-awesome-modal';

import ChipInput from 'material-ui-chip-input';

import { verifyGstr, verifyGstrCaptcha, fetchGSTNumberFromPDF } from '../../../../utils/apiService';

import {
    generateBusinessPayload, generatePrivateCompanyPayload, generatePublicCompanyPayload,
    generatePartnershipCompanyPayload, generateHinduUndividedFamilyPayload, generateRentalAgreement,
    generateSalesDeed, generateBusinessCodePayload
} from '../FormPayload/index';

import { fileUpload, updateCrifReport } from '../service/index';

import Loader from '../../../../utils/loader';

import Snackbar from '../../../../utils/alert';

import { utils } from '../../../../utils/utils';

import { updateMsme } from '../../Component/service/index';

import { validateBusinessForm } from '../Validation/index'

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import { fixedValues } from '../../../../utils/enum/currency'

import moment from 'moment';

import { languageStrings } from '../../../../utils/enum/languageStrings';

import _ from 'lodash'

import { isValid } from "../Validation/index";

import { businessUtilFunctions } from './utils';

import { utilFunctions } from '../../../../utils/index';

const { BUSINESS_FORM } = languageStrings;

const { inputFieldOptions } = require('../../../../utils/enum/inputFieldStrings')

const { localStorageStrings } = require('../../../../utils/enum/localStorageStrings');

const { extractPanNumber, extractPanTypeFromGSTIN, validateBusinessCriteria } = utilFunctions;

const {
    getTaxPayerNo, getPanNo, getPersonalPanNo, getFssaiDetails, getBusinessCode, getAgreementNumber, validatePanNumber
} = businessUtilFunctions;

const {
    getDetailsByName, getItemByName, setBusinessPan, setPersonalPanNumber, getBusinessPan,
    getPersonalPanNumber, getTaxPayerInfo, setBusinessCode, setDateOfIncorporation,
    getDateOfIncorporation, getDistributorType, getDataFromLocalStorage,
    storeDataFromDatabase
} = utils;

const {
    FSSAI, TAXPAYER_INFO, AGREEMENT_NUMBER, IS_VERIFIED_EXCEPT_GST, SALE_DEED,
    GST_CERTIFICATE, PAN, NOC_DOCUMENT, MOM_CERTIFICATE, STEP_INFO_LABEL, NO_OF_DISTRIBUTORS,
    NO_OF_OFFICERS, INCORPORATION, PAN_TYPE, NAME_OF_HINDU_UNDIVIDED_FAMILY, ADDRESS_OF_HINDU_UNDIVIDED_FAMILY,
    AGE_OF_HINDU_UNDIVIDED_FAMILY, COMPANY_DETAILS
} = localStorageStrings;

const { NEW_USER, EXISTING_USER } = inputFieldOptions;

const {
    GSTIN_LENGTH,
    PAN_NUMBER_LENGTH,
    PAN_TYPE: {
        INDIVIDUAL,
        COMPANY,
        HINDU_UNDIVIDED_FAMILY,
        FIRM_LIMITED_LIABILITY_PARTNERSHIP,
        ASSOCIATION_OF_PERSON
    }
} = fixedValues;

export default class BusinessForm extends Component {
    constructor(props) {
        super(props);
        Mixpanel.track(trackString.BUSINESS_DETAILS_FORM_PAGE)
    }

    state = {
        loader: false,
        popLoader: false,
        showGrowl: false,
        growlMsg: "",
        captachaCode: "",
        captachaImage: "",
        businessCode: getBusinessCode() ? getBusinessCode() : "",
        distributorType: getDistributorType() ? EXISTING_USER : NEW_USER,
        isExistingDistributor: getDistributorType(),
        dateOfIncorporation: getDateOfIncorporation(),
        isOtherInfoVerified: getDataFromLocalStorage(IS_VERIFIED_EXCEPT_GST),
        visible: false,
        gstNo: getTaxPayerNo(),
        panNo: getPanNo(),
        personalPanNumber: getPersonalPanNo(),
        isGstVerify: getItemByName(TAXPAYER_INFO),
        isPanVerify: getBusinessPan(),
        isPersonalPanVerify: validatePanNumber(getPersonalPanNumber()),
        isfssaiVerify: getItemByName(FSSAI),
        saleDeed: getItemByName(SALE_DEED),
        gstForm: getDetailsByName(TAXPAYER_INFO),
        fssaiForm: getFssaiDetails(),
        gstCertificate: getDataFromLocalStorage(GST_CERTIFICATE),
        PAN: getItemByName(PAN) ? getItemByName(PAN) : "",
        nocDocument: getItemByName(NOC_DOCUMENT),
        momCertificate: getDetailsByName(MOM_CERTIFICATE),
        panType: "",
        panSubType: getDataFromLocalStorage(PAN_TYPE),
        noofDirectors: getDataFromLocalStorage(NO_OF_DISTRIBUTORS),
        noofOfficers: getDataFromLocalStorage(NO_OF_OFFICERS),
        nameofHinduUndivedFamily: getDataFromLocalStorage(NAME_OF_HINDU_UNDIVIDED_FAMILY),
        addresssofHinduUndivedFamily: getDataFromLocalStorage(ADDRESS_OF_HINDU_UNDIVIDED_FAMILY),
        ageofHinduUndivedFamily: getDataFromLocalStorage(AGE_OF_HINDU_UNDIVIDED_FAMILY),
        agreementDeed: getAgreementNumber(AGREEMENT_NUMBER) || "",
        partnershipCertificate: "",
        incorporation: _.isEmpty(getDataFromLocalStorage(INCORPORATION)) ? "" : getDataFromLocalStorage(INCORPORATION),
        validGstCertificate: getDataFromLocalStorage(GST_CERTIFICATE) ? true : false,
        errorStatus: {
            dateOfIncorporation: false,
            gstNo: false,
            personalPanNumber: false,
            noofOfficers: false,
            nameofHinduUndivedFamily: false,
            addresssofHinduUndivedFamily: false,
            ageofHinduUndivedFamily: false,
            businessCode: false,
            noofDirectors: false
        }
    }

    gstrUpload = "";

    truncateBusinessInfo = async () => {
        this.gstrUpload.value = null;
        this.setState({
            isGstVerify: false,
            gstCertificate: "",
            PAN: ""
        })
        localStorage.setItem(GST_CERTIFICATE, "")
        localStorage.setItem(PAN, "")
    }

    truncatePersonalInfo = () => {
        this.setState({ isPersonalPanVerify: false })
    }

    onValueChange = async event => {
        const { name, value } = event.target;
        if (name === 'gstNo') await this.truncateBusinessInfo()
        if (name === 'personalPanNumber') this.truncatePersonalInfo()
        if (name === 'gstNo' && value.length === GSTIN_LENGTH) {
            this.setState({
                'panNo': extractPanNumber(value),
                'panType': extractPanTypeFromGSTIN(value),
                isGstVerify: false,
                gstCertificate: "",
            });
        }
        const { errorStatus = {} } = this.state;
        let validationResult = isValid(name, value);
        const updatedValue = (name === 'gstNo' || name === 'personalPanNumber') ? value.toUpperCase() : value;
        this.setState({ errorStatus: { ...errorStatus, [name]: validationResult }, [name]: updatedValue })
    }

    onDistributorTypeChosen = event => {
        const { name, value } = event.target;

        if (name === 'distributorType' && value === NEW_USER) {
            this.setState({ businessCode: "XXXXX", isExistingDistributor: false })
        }
        else {
            this.setState({ businessCode: "", isExistingDistributor: true })
        }

        this.setState({ [name]: value })
    }

    compareGstIncludesPanNo = () => {
        return this.state.panNo && this.state.panNo.length === PAN_NUMBER_LENGTH && this.state.gstNo && this.state.gstNo.includes(this.state.panNo)
    }
    getPanNoFromGst = () => {
        if (this.state.gstNo && this.state.gstNo.length === GSTIN_LENGTH) {
            this.setState({
                'panNo': extractPanNumber(this.state.gstNo),
                'panType': extractPanTypeFromGSTIN(this.state.gstNo)
            }, () => {
                this.checkIsPersonPanCard();
            });
        }
    }

    renderImage = (captchaImage) => {
        return <img src={(captchaImage)} alt='logo' className='captchaImage' />
    }

    openModal = async () => {
        await this.verifyGST();
    }

    closeModal = () => {
        this.setState({
            loader: false, visible: false, captachaCode: "",
            captachaImage: ""
        });
    }


    onFileChange = async (event) => {
        if (event.target.type === 'file' && event.target.files && event.target.files.length) {
            const fileName = event.target.name;
            this.setState({ popLoader: true });
            const file = event.target.files[0];
            await this.fileFileToS3(file, fileName);
        }
    }
    fileFileToS3 = async (file, fileName) => {
        try {
            const response = await fileUpload(file);

            if (response) {
                localStorage.setItem([`${fileName}`], response);

                Mixpanel.track(`UPLOADED_${fileName.toUpperCase()}_DOCUMENT_SUCCESSFULLY`);

                this.setState({ [`${fileName}`]: JSON.stringify(response), popLoader: false });
                return
            }

            Mixpanel.track(`UPLOADING_${fileName.toUpperCase()}_DOCUMENT_FAILED`);

            this.showErrorMsg(BUSINESS_FORM.ERROR_IN_FILE_UPLOAD);
        } catch (error) {

            Mixpanel.track(`UPLOADING_${fileName.toUpperCase()}_DOCUMENT_FAILED`);

            this.showErrorMsg(BUSINESS_FORM.ERROR_IN_FILE_UPLOAD);
        } finally{
            this.setState({ popLoader: false });
        }
    }

    multipleFileUpload = async (files) => {

        const finalURL = [];

        const tempFiles = [];

        Array.from(files, async (file) => {
            tempFiles.push(file)
        });

        for (let index = 0; index < tempFiles.length; index++) {

            const eachFile = tempFiles[index];

            const url = await fileUpload(eachFile);

            finalURL.push(url);
        }
        return finalURL;
    }
    onMultipleUploadChange = async (event) => {

        if (event.target.type === 'file' && event.target.files && event.target.files.length) {

            const fileName = event.target.name;
            const files = event.target.files;
            this.setState({ popLoader: true });

            try {

                let finalURL = [];

                finalURL = await this.multipleFileUpload(files);

                if (finalURL && finalURL.length) {

                    localStorage.setItem([`${fileName}`], JSON.stringify(finalURL));

                    Mixpanel.track(`UPLOADED_${fileName.toUpperCase()}_DOCUMENT_SUCCESSFULLY`);

                    this.setState({ [`${fileName}`]: finalURL, popLoader: false });

                } else {
                    this.setState({ loader: false });
                    this.renderErrorGrowl(BUSINESS_FORM.WE_ARE_FACING_ISSUE_WHILE_UPLOADING_DOCUMENT);

                    Mixpanel.track(`UPLOADING_${fileName.toUpperCase()}_DOCUMENT_FAILED`);

                }
            }
            catch (err) {

                Mixpanel.track(`UPLOADING_${fileName.toUpperCase()}_DOCUMENT_FAILED`);

                this.setState({ loader: false });
                this.renderErrorGrowl(BUSINESS_FORM.WE_ARE_FACING_ISSUE_WHILE_UPLOADING_DOCUMENT);
            } finally{
                this.setState({ popLoader: false });
            }
        }
    }

    showErrorMsg = (ErrorMsg) => {
        this.renderErrorGrowl(ErrorMsg);
        this.setState({ loader: false });
    }
    verifyGST = async () => {

        if (isValid('gstNo', this.state.gstNo)) {
            return this.renderErrorGrowl(BUSINESS_FORM.ENTER_VALID_GSTIN);
        }

        this.getPanNoFromGst()

        this.setState({ popLoader: true });

        const payload = { gstin: this.state.gstNo }

        try {
            const result = await verifyGstr(payload);

            const status_code = result && result['status-code'];

            if (status_code === '101') {
                this.setState({ popLoader: false, visible: true, captachaCode: "", captachaImage: result.data });
                this.renderSuccessGrowl(BUSINESS_FORM.ENTER_CAPTCHA_CODE);
            } else {
                this.showErrorMsg(BUSINESS_FORM.SOMETHING_WENT_WRONG)
            }
        } catch (error) {
            this.showErrorMsg(BUSINESS_FORM.SOMETHING_WENT_WRONG)
        } finally {
            this.setState({ popLoader: false })
        }
    }

    truncateGSTCertificate = async (fileName) => {
        this.gstrUpload.value = null;
        await localStorage.setItem([`${fileName}`], "");
        await this.setState({ [fileName]: "" })
    }

    onGstDocumentValidation = async (event) => {
        const { gstNo, isGstVerify } = this.state;

        const file = event.target.files[0];

        const fileName = event.target.name;

        if (!(gstNo && isGstVerify)) {
            await this.truncateGSTCertificate(fileName)
            return this.renderErrorGrowl(BUSINESS_FORM.KINDLY_VERIFY_GST);
        }

        this.setState({ popLoader: true });

        const response = await fileUpload(file);

        if (response) {
            let isValidGstr = await this.fetchGSTNumber(response, gstNo, fileName);
            //We are resetting the input field value if it is invalid
            if (isValidGstr === false) this.gstrUpload.value = null;
            return;
        }
        this.showErrorMsg(BUSINESS_FORM.ERROR_IN_FILE_UPLOAD);

        Mixpanel.track(trackString.UPLOADING_GST_REGISTRATION_CERTIFICATE_FAILED)

    }

    fetchGSTNumber = async (S3URL, registeredGSTIN, fileName) => {

        const payload = { gstCertificate: S3URL };

        try {
            const result = await fetchGSTNumberFromPDF(payload);

            const { status = false, message = "", gstNumber = "" } = result || {};

            localStorage.setItem([`${fileName}`], S3URL);

            if (status && gstNumber) {
                const gstNo = gstNumber.trim();
                if (registeredGSTIN === gstNo) {
                    Mixpanel.track(trackString.UPLOADED_GST_REGISTRATION_CERTIFICATE_SUCCESSFULLY)
                    this.setState({ [`${fileName}`]: JSON.stringify(S3URL), popLoader: false, validGstCertificate: true });
                    this.renderSuccessGrowl(BUSINESS_FORM.GST_CERTIFICATE_VERIFIED_SUCCESSFULLY);
                    return true;
                }
                Mixpanel.track(trackString.UPLOADING_GST_REGISTRATION_CERTIFICATE_FAILED)
                storeDataFromDatabase(GST_CERTIFICATE, "")
                this.setState({ validGstCertificate: false })
                this.renderErrorGrowl(BUSINESS_FORM.GST_NUMBER_IS_MISMATCHED);
                return true;
            } else {
                Mixpanel.track(trackString.UPLOADING_GST_REGISTRATION_CERTIFICATE_FAILED)
                this.renderErrorGrowl(message);
                this.setState({ validGstCertificate: false })
                storeDataFromDatabase(GST_CERTIFICATE, "")
                return false;
            }
        }
        catch (error) {
            await this.truncateGSTCertificate(fileName)
            Mixpanel.track(trackString.FACING_ISSUE_WHILE_FETCHING_GSTR_NUMBER)
            storeDataFromDatabase(GST_CERTIFICATE, "")
            this.setState({ validGstCertificate: false })
            this.showErrorMsg(BUSINESS_FORM.ERROR_IN_FETCHING_GST_NUMBER);
            return false;
        } finally {
            this.setState({ popLoader: false });
        }
    }

    renderInputFields = (inputFields) => {
        const { label, required, name, errorString = "", iconRequired = false, disabled, type } = inputFields;
        let { errorStatus = {} } = this.state;
        const isInvalid = errorStatus[`${name}`];
        return (
            <div className='BusinessFormField'>
                <label className='flexlabel textLeft'>{label}{required && <div className="mandatory">*</div>}</label>
                <div style={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        { type === 'file' && disabled ? 
                            <label>{BUSINESS_FORM.SUCCESSFULLY_UPLOADED}&nbsp;</label>
                            : this.renderSingleBox(inputFields)
                        }
                        {iconRequired && this.renderVerifyIcon(inputFields)}
                    </div>
                    {isInvalid && <div className="errMsgMarginTop0">{errorString}</div>}
                </div>
            </div>
        )
    }

    renderSingleBox = (inputFields) => {
        const { type, name, onChange, placeholder, value, disabled, accept = "", min="", max = "", ref = "" } = inputFields;
        return <Fragment>
            {type === 'file' && 
            <input className={`paddingtopFile msmeInput fileUploadWidth filepadding`} accept={accept} type={type} name={name} onChange={onChange} disabled={disabled} ref={reference => this[`${ref}`] = reference} />}
            {(type === 'number') && <input className={`msmeInput filepadding`} placeholder={placeholder} type={type} name={name} value={value} onChange={onChange} defaultValue="" disabled={disabled} min={min} max={max} />}
            {(type === 'string') && <input className={`msmeInput filepadding`} placeholder={placeholder} type={type} name={name} value={value} onChange={onChange} defaultValue="" disabled={disabled} min={min} max={max} maxLength={max} 
            onBlur={name === 'gstNo' && this.getPanNoFromGst}/>}
            {type === 'date' && <input className={`msmeInput filepadding businessInput`} placeholder={'DD-MM-YYYY'} type={type} name={name} max={max} value={value} onChange={this.changeCalendar} onClick={this.changeCalendar} defaultValue="" disabled={disabled} />}
        </Fragment>
    }

    changeCalendar = (event) => {
        const { value, name } = event.target;
        const { errorStatus = {} } = this.state;
        // validation check for Date of incorporation
        let validationResult = isValid(name, moment(value, `YYYY-MM-DD`));
        this.setState({ errorStatus: { ...errorStatus, [name]: validationResult }, [name]: value })
    }

    renderInputOptionalFields = (inputFields) => {
        const { label, type, name, onChange, isVerify, placeholder, disabled, required, accept = "", multipleUpload = false, errorString = '' } = inputFields;
        let { errorStatus = {} } = this.state;
        const isInvalid = errorStatus[`${name}`];

        return (
            <div className='BusinessFormField'>
                <label className='flexlabel textLeft'>{label} {required && <div className="mandatory">*</div>}</label>
                <div style={{ display: 'flex', width: '50%', flexDirection: 'column' }} id="chips">
                    <div style={{ display: 'flex' }}>
                        {type === 'string' && this.renderSingleBox(inputFields)}
                        {type === 'file' && (disabled ? <label>{BUSINESS_FORM.SUCCESSFULLY_UPLOADED}&nbsp;</label> : <input className={`paddingtopFile msmeInput fileUploadWidth filepadding`}
                                    accept={accept} placeholder={placeholder} type={type} name={name} onChange={onChange}
                                    disabled={disabled} multiple={multipleUpload} />)}
                        {type === 'chip' && this.renderChips(inputFields, disabled)}
                        {type === 'file' && <img src={(isVerify ? require('../../../assets/images/right.jpeg') : require('../../../assets/images/wrong.jpg'))} alt='logo' className='iconImg' />
                        }
                    </div>
                    {isInvalid && <div className={(type==='string')? "errMsgMarginTop0"  : "errMsgMarginTop10"}>{errorString}</div>}
                </div>
            </div>
        )
    }

    generateCrifReport = async () => {
        const panNo = this.state.personalPanNumber ? this.state.personalPanNumber : this.state.panNo;
        const result = await updateCrifReport({ props: this.props, updateState: this.updateState, panNo })
        return result;
    }

    formatCompanyDetails = () => {
        let result = {};
        if (this.checkPanSubType(BUSINESS_FORM.PRIVATE_LIMITED_COMPANY) && this.checkPanType(COMPANY)) {
            result = {
                type: BUSINESS_FORM.PRIVATE_LIMITED_COMPANY,
                momCertificate: this.state.momCertificate,
                noofDirectors: this.state.noofDirectors,
                noofOfficers: this.state.noofOfficers,
                incorporation: this.state.incorporation
            }
        }
        if (this.checkPanSubType(BUSINESS_FORM.PUBLIC_LIMITED_COMPANY) && this.checkPanType(COMPANY)) {
            result = {
                type: BUSINESS_FORM.PUBLIC_LIMITED_COMPANY,
                momCertificate: this.state.momCertificate,
                noofDirectors: this.state.noofDirectors,
                noofOfficers: this.state.noofOfficers,
                incorporation: this.state.incorporation
            }
        }
        if (this.checkPanType(HINDU_UNDIVIDED_FAMILY)) {
            result = {
                type: BUSINESS_FORM.HINDU_UNDIVIDED_FAMILY,
                nameofHinduUndivedFamily: this.state.nameofHinduUndivedFamily,
                addresssofHinduUndivedFamily: this.state.addresssofHinduUndivedFamily,
                ageofHinduUndivedFamily: this.state.ageofHinduUndivedFamily,
            }
        }
        if ((this.checkPanType(FIRM_LIMITED_LIABILITY_PARTNERSHIP) || this.checkPanType(ASSOCIATION_OF_PERSON))) {
            result = {
                type: 'Partnership',
                agreementDeed: this.state.agreementDeed,
                partnershipCertificate: this.state.partnershipCertificate,
            }
        }

        return result;

    }

    renderChips = (inputFields, isOtherInfoVerified) => {
        const { value, name, max } = inputFields;
        return <ChipInput className="fullWidthChips"
            maxLength={max}
            value={value}
            name={name}
            disabled={isOtherInfoVerified}
            onChange={(chips) => this.handleAddChip(chips, name)}
            onDelete={(chip, index) => { this.handleDeleteChip(chip, index, name) }}
        />
    }

    handleAddChip = (chips, name) => {
        const { errorStatus = {} } = this.state;
        let validationResult = isValid(name, chips);
        this.setState({ errorStatus: { ...errorStatus, [name]: validationResult }, [name]: chips })
    }
    handleDeleteChip = (chip, index, name) => {
        let temporaryValue = this.state[name];
        temporaryValue.splice(index, 1);
        this.setState({ [name]: temporaryValue });
    }

    getStepInfo = () => {
        const result = JSON.parse(getItemByName(STEP_INFO_LABEL));
        const isGSTCertificateVerified = this.state.validGstCertificate;
        const isVerifiedExceptGSTCertificate = true;
        isGSTCertificateVerified ?
            Mixpanel.track(trackString.BUSINESS_DETAILS_SCREEN_SUCCESSFULLY_COMPLETED)
            :
            Mixpanel.track(trackString.EXCEPT_GST_CERTIFICATE_OTHER_INFORMATION_ARE_VERIFIED)

        if (!result) {
            return localStorage.setItem(STEP_INFO_LABEL,
                JSON.stringify({
                    businessForm: isGSTCertificateVerified,
                    isVerifiedExceptGSTCertificate,
                    itrReturnsForm: false,
                    gstrReturnsForm: false,
                    bankVerificationForm: false,
                    summaryForm: false
                })
            );
        }
        localStorage.setItem(STEP_INFO_LABEL, JSON.stringify({
            ...result,
            businessForm: isGSTCertificateVerified,
            isVerifiedExceptGSTCertificate
        }))
    }

    updateLocalStorage = async () => {
        const { dateOfIncorporation = moment() } = this.state;
        await setDateOfIncorporation(dateOfIncorporation);
        await storeDataFromDatabase(COMPANY_DETAILS, this.formatCompanyDetails())
    }

    updateBusiness = async () => {
        this.getStepInfo();
        const { businessCode, dateOfIncorporation, isExistingDistributor = false } = this.state;
        await this.updateLocalStorage();
        const formInfo = {
            businessCode: businessCode,
            gstInfo: { ...getTaxPayerInfo(), isExistingDistributor },
            saleDeed: getItemByName(SALE_DEED),
            companyDetails: this.formatCompanyDetails(),
            gstCeritifcate: getItemByName(GST_CERTIFICATE),
            dateOfIncorporation,
            pancard: getItemByName(PAN),
            nocDocument: getItemByName(NOC_DOCUMENT),
            panInfo: {
                panNo: getPanNo(),
                personalPanNumber: getPersonalPanNo(),
            },
            stepInfo: JSON.parse(getItemByName(STEP_INFO_LABEL))
        }
        await updateMsme({ formInfo, props: this.props, updateState: this.updateState });
    }

    updateState = (data) => {
        this.setState(data);
    }

    verifyCaptcha = async () => {

        if (!this.state.captachaCode) return this.renderErrorGrowl(BUSINESS_FORM.ENTER_CAPTCHA_CODE);

        this.setState({ loader: true });

        const payload = {
            gstin: this.state.gstNo,
            captchaCode: this.state.captachaCode
        }

        try {
            const result = await verifyGstrCaptcha(payload);

            const status_code = result && result['status-code'];

            if (status_code === '101') {
                this.renderSuccessGrowl(BUSINESS_FORM.GST_VERIFIED_SUCCESSFULLY);

                Mixpanel.track(trackString.VERIFIED_GST_CAPTCHA_SUCCESSFULLY);

                Mixpanel.track(trackString.VERIFIED_GST_NUMBER_SUCCESSFULLY);

                this.getPanNoFromGst()
                localStorage.setItem(TAXPAYER_INFO, JSON.stringify(result.data))
                this.setState({ loader: false, isGstVerify: true, gstForm: result.data, visible: false, captachaCode: "", captachaImage: "" });
            } else {

                Mixpanel.track(trackString.VERIFYING_GST_CAPTCHA_FAILED);

                Mixpanel.track(trackString.VERIFYING_GST_NUMBER_FAILED);

                this.showErrorMsg(result && result.message)
            }
        } catch (error) {

            Mixpanel.track(trackString.FACING_ISSUE_WHILE_VERIFYING_GST_CAPTCHA);

            Mixpanel.track(trackString.FACING_ISSUE_WHILE_VERIFYING_GST_NUMBER);

            this.showErrorMsg(BUSINESS_FORM.FACING_ISSUE_WHILE_VERIFYING_CAPTCHA)
        } finally {
            this.setState({ visible: false });
        }
    }

    verifyBusinessPan = async () => {

        if (!this.compareGstIncludesPanNo()) return this.renderErrorGrowl(BUSINESS_FORM.ENTER_VALID_PAN);

        const { panNo } = this.state;

        await this.verifyPan(panNo, 'panNumber');
    }

    verifyPan = async (panNo, name) => {

        this.setState({ loader: true });

        if (name === 'panNumber') {
            await setBusinessPan(panNo);
            Mixpanel.track(trackString.VERIFIED_PAN_NUMBER_SUCCESSFULLY)
            this.setState({ loader: false, isPanVerify: true });
            return this.renderSuccessGrowl(BUSINESS_FORM.BUSINESS_PAN_VERIFIED_SUCCESSFUL);
        }
        if (name === 'personalPanNumber') await this.personalPanNumberVerified(panNo);
    }

    personalPanNumberVerified = async (panNo) => {
        await setPersonalPanNumber(panNo);
        this.renderSuccessGrowl(BUSINESS_FORM.PERSONAL_PAN_VERIFIED_SUCCESSFUL);
        this.setState({ loader: false, isPersonalPanVerify: true });
    }

    renderCaptchaBox = () => {
        const { captachaImage, captachaCode } = this.state;
        return (
            <Fragment> 
                <div className="flex">
                    {this.renderImage(captachaImage)}
                </div>
                <div className="flex">
                    <input className={'msmeInput filepadding'} placeholder='Enter captcha code' type="string" name="captachaCode" value={captachaCode} onChange={this.onValueChange} defaultValue="" />
                </div>
            </Fragment>
        )
    }

    renderVerifyButton = () => {
        return <p className={"combutton"} onClick={() =>
            this.verifyCaptcha()}>{BUSINESS_FORM.VERIFY}
        </p>
    }

    renderModel = () => {
        return <Modal visible={this.state.visible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
            <div className="padding50">
                {this.state.captachaImage && this.renderCaptchaBox()}
                <div className="textcenter">
                    {this.state.loader ? <Loader /> : this.renderVerifyButton()}
                </div>
            </div>
        </Modal>
    }

    renderVerifyIcon = (inputFields) => {
        const { disabled, isVerify, onClick, type, name } = inputFields;
        const enableVerifyOption = ((name === 'panNo') || !disabled) && !isVerify;
        const onClickHandler = enableVerifyOption ? onClick : null;
        return <Fragment> {type !== 'file' && <span className="verifyicon" onClick={onClickHandler}> {isVerify ? BUSINESS_FORM.VERIFIED : BUSINESS_FORM.VERIFY}  </span>}
            <img src={(isVerify ? require('../../../assets/images/right.jpeg') : require('../../../assets/images/wrong.jpg'))} alt='logo' className='businessIconStyle' /></Fragment>
    }

    renderTitle = () => {
        return <div className="textcenter">
            <h3 className="subtitle formtitleMargin">{BUSINESS_FORM.BUSINESS_DETAILS}</h3>
        </div>
    }

    renderRadioButton = (option, name, disabled, stateValue = "") => {
        return (
            <div className="spaceBetween verticalText">
                <input className="checkboxInput" type="radio" checked={option === stateValue} disabled={disabled} name={name} value={option} onClick={this.onValueChange} />
                <label className='flexlabel'>{option}</label>
            </div>
        )
    }

    renderDistributorOption = (option, name, stateValue = "", disabled = false) => {
        return (
            <div className="spaceBetween verticalText">
                <input className="checkboxInput" type="radio" name={name} disabled={disabled} checked={stateValue === option} value={option} onClick={this.onDistributorTypeChosen} />
                <label className='flexlabel'>{option}</label>
            </div>
        )
    }

    renderCompanyType = () => {
        const options = [BUSINESS_FORM.PRIVATE_LIMITED_COMPANY, BUSINESS_FORM.PUBLIC_LIMITED_COMPANY];
        const { isOtherInfoVerified, panSubType = "" } = this.state;
        return <div className='BusinessFormField'>
            <label className='flexlabel'>{BUSINESS_FORM.COMPANY_TYPE}<div className="mandatory">*</div></label>
            <div className="flex halfWidth">
                {options.map(field => this.renderRadioButton(field, 'panSubType', isOtherInfoVerified, panSubType))}
            </div>
        </div>
    }

    renderOwnership = () => {
        const { isOtherInfoVerified, ownershipType } = this.state;
        const options = ['Own', 'Rented'];
        return <div className='BusinessFormField'>
            <label className='flexlabel'>{BUSINESS_FORM.OWNERSHIP_OF_PROPERTY}</label>
            <div className="flex halfWidth">
                {options.map(field => this.renderRadioButton(field, 'ownershipType', isOtherInfoVerified, ownershipType))}
            </div>
        </div>
    }

    renderDistributorType = () => {
        const options = [NEW_USER, EXISTING_USER];
        const { distributorType, isOtherInfoVerified } = this.state;
        return <div className='BusinessFormField'>
            <label className='flexlabel'>{BUSINESS_FORM.DISTRIBUTOR_TYPE}<div className="mandatory">*</div></label>
            <div className="flex halfWidth">
                {options.map(field => this.renderDistributorOption(field, 'distributorType', distributorType, isOtherInfoVerified))}
            </div>
        </div>
    }

    renderOwnerShipType = (salesDeed, rentalAgreement) => {

        const { ownershipType } = this.state;

        let result = ownershipType && ownershipType === 'Own' && this.renderInputFields(salesDeed);

        if (result) return result;

        result = ownershipType && ownershipType === 'Rented' && this.renderInputFields(rentalAgreement)

        return result;
    }


    checkPanType = (letter) => {
        return this.state.panType && this.state.panType.toUpperCase() === letter;
    }

    checkPanSubType = (letter) => {
        return this.state.panSubType && this.state.panSubType === letter;
    }

    renderErrorGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'error', growlMsg: msg });
    }
    renderSuccessGrowl = (msg) => {
        this.setState({ showGrowl: true, growlStatus: 'success', growlMsg: msg });
    }
    handleClose = () => { this.setState({ showGrowl: false }); };

    checkForSaveAndProceed = async () => {
        let result = validateBusinessForm(this.state);
        if (result === false) return this.renderErrorGrowl(BUSINESS_FORM.ENTER_ALL_MANDATORY_FIELDS);
        if(!validateBusinessCriteria(this.state)){
            (this.state.panType===INDIVIDUAL) ? this.renderErrorGrowl(BUSINESS_FORM.USER_NOT_ELIGIBLE_DUE_TO_BUSINESS_VINTAGE_PERSONAL_PAN) : 
            this.renderErrorGrowl(BUSINESS_FORM.USER_NOT_ELIGIBLE_DUE_TO_BUSINESS_VINTAGE_BUSINESS_PAN)
            return;
        }
        const crifResult = await this.generateCrifReport();
        if (!crifResult) {
            return this.renderErrorGrowl(BUSINESS_FORM.SECURITY_AUTHENTICATION_FAILED);
        }
        setBusinessCode(this.state.businessCode)
        this.updateBusiness();
    }

    renderPopLoader = () => {
        return <Modal visible={this.state.popLoader} effect="fadeInUp">
            <div className="padding50">
                <div className="textcenter">
                    <Loader />
                </div>
            </div>
        </Modal>
    }

    render() {
        const { showGrowl, growlStatus, growlMsg, isExistingDistributor } = this.state;
        const formFields = generateBusinessPayload({
            state: this.state, onValueChange: this.onValueChange,
            verifyBusinessPan: this.verifyBusinessPan,
            onFileChange: this.onFileChange,
            openModal: this.openModal,
            onMultipleUploadChange: this.onMultipleUploadChange,
            onGstDocumentValidation: this.onGstDocumentValidation,
            panNumberVerify: this.verifyPersonalPanNumber
        });

        const salesDeed = generateSalesDeed({ state: this.state, onFileChange: this.onFileChange });

        const rentalAgreement = generateRentalAgreement({ state: this.state, onFileChange: this.onFileChange });

        const businessCodePayload = generateBusinessCodePayload({ state: this.state, onValueChange: this.onValueChange, })

        const privateCompany = generatePrivateCompanyPayload({
            state: this.state, onValueChange: this.onValueChange, onMultipleUploadChange: this.onMultipleUploadChange
        });

        const publicCompany = generatePublicCompanyPayload({ state: this.state, onValueChange: this.onValueChange, onMultipleUploadChange: this.onMultipleUploadChange });

        const partnerShip = generatePartnershipCompanyPayload({ state: this.state, onValueChange: this.onValueChange, onFileChange: this.onFileChange });

        const hinduUndividedFamily = generateHinduUndividedFamilyPayload({ state: this.state, onValueChange: this.onValueChange });
        return (
            <div className={"msmeOnboardingContainer"} id="formStepper">
                {this.renderTitle()}
                {this.renderModel()}
                <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />

                {<div className={'businessDetails'}>

                    {this.renderPopLoader()}

                    {this.renderDistributorType()}

                    {isExistingDistributor && this.renderInputFields(businessCodePayload)}


                    {formFields.map(field => this.hidePersonalPanNumber(field.name) ? null :
                        this.renderInputFields(field))
                    }

                    {this.renderOwnership()}

                    {this.renderOwnerShipType(salesDeed, rentalAgreement)}

                    {this.checkPanType(COMPANY) && this.renderCompanyType()}

                    {this.checkPanSubType(BUSINESS_FORM.PRIVATE_LIMITED_COMPANY) && this.checkPanType(COMPANY) && privateCompany.map(field => this.renderInputOptionalFields(field))}

                    {this.checkPanSubType(BUSINESS_FORM.PUBLIC_LIMITED_COMPANY) && this.checkPanType(COMPANY) && publicCompany.map(field => this.renderInputOptionalFields(field))}

                    {this.checkPanType(HINDU_UNDIVIDED_FAMILY) && hinduUndividedFamily.map(field => this.renderInputOptionalFields(field))}

                    {(this.checkPanType(FIRM_LIMITED_LIABILITY_PARTNERSHIP) || this.checkPanType(ASSOCIATION_OF_PERSON)) && partnerShip.map(field => this.renderInputOptionalFields(field))}
                    {this.state.loader && <Loader />}
                    {!this.state.loader && <p className={"combutton"} onClick={() => { this.checkForSaveAndProceed() }} >{BUSINESS_FORM.SAVE_AND_PROCEED}</p>}
                </div>}
            </div>
        );
    }

    componentDidMount() {
        this.getPanNoFromGst();
    }

    hidePersonalPanNumber = (name) => {
        return this.checkPanType(INDIVIDUAL) && name === 'personalPanNumber';
    }

    checkIsPersonPanCard = () => {
        if (this.checkPanType(INDIVIDUAL)) this.setState({ personalPanNumber: extractPanNumber(this.state.gstNo) });
    }

    verifyPersonalPanNumber = async () => {
        const { personalPanNumber } = this.state;

        if (validatePanNumber(personalPanNumber)) {
            return await this.verifyPan(personalPanNumber, 'personalPanNumber');
        }
        return this.showErrorMsg(BUSINESS_FORM.PLEASE_ENTER_VALID_PERSONAL_PAN);
    }
}
