import React from 'react';

import { utils } from '../../../utils/utils';

import { languageStrings } from '../../../utils/enum/languageStrings';

const { GOV_IN_URL, INCOME_TAX_FILING_URL } = require('../../../utils/enum/index');

const { getOrganizationID, getUserPhone, getUserData } = utils;

const {
    ONBOARDING: {
        WELCOME,
        ONBOARDING_MSG,
        AADHAAR_CARD,
        AADHAAR_FRONT_COPY,
        AADHAAR_BACK_COPY,
        GST_REGISTRATION_CERTIFICATE,
        DIGITAL_COPY_FROM_GST_HOME,
        PAN_CARD,
        SCANNED_COPY_PNG_JPEG_JPG,
        SCANNED_COPY_PDF_PNG_JPEG_JPG,
        SHOP_AND_ESTABLISHMENT_CERTIFICATE,
        SCANNED_COPY_PDF,
        SALES_DEED_DOCUMENT,
        PUBLIC_AND_PRIVATE_COMPANY,
        IN_CASE_OF_PARTNERSHIP_DEED_DOCUMENT,
        IN_CASE_OF_PARTNERSHIP_REG_CERTIFICATE,
        LATEST_2_YEAR_ITR,
        GET_DIGITAL_COPY_FROM,
        WEBSITE,
        OR_LABEL,
        DIGITAL_COPY_FROM_NET_BANKING_PORTAL,
        OD_CC_LIMIT_INFORMATION,
        KEEP_YOUR_NET_BANKING_CREDENTIALS_READY,
        LATEST_1_YEAR_GST_RETURNS,
        LATEST_N_MONTHS_BANK_STATEMENT,
        NOTES_GSTR_INSTRUCTION,
        SCANNED_PDF_INSTRUCTION
    }
} = languageStrings;

const incomeTaxWebsite = <a href={`${INCOME_TAX_FILING_URL}`}
    target="_blank" rel="noopener noreferrer">{`${INCOME_TAX_FILING_URL}`}</a>;

const gstWebsite = <a href={`${GOV_IN_URL}`} target="_blank" rel="noopener noreferrer">{`${GOV_IN_URL}`}</a>;

/**
 * @description Function returns information embed with HTML for redirecting purpose
 * @param {UIEvent} url 
 */
const getDigitalCopyInfo = (url) => <p>{GET_DIGITAL_COPY_FROM} {url} {WEBSITE}</p>

/**
 * @description Variable holding instruction information for rendering.
 */
export const instructionPayload = [
    {
        column1: AADHAAR_CARD,
        column2: <div>
            <p>{AADHAAR_FRONT_COPY}</p>
            <p>{AADHAAR_BACK_COPY}</p>
        </div>
    },
    {
        column1: GST_REGISTRATION_CERTIFICATE,
        column2: DIGITAL_COPY_FROM_GST_HOME
    },
    {
        column1: PAN_CARD,
        column2: SCANNED_COPY_PNG_JPEG_JPG
    },
    {
        column1: SHOP_AND_ESTABLISHMENT_CERTIFICATE,
        column2: SCANNED_COPY_PDF
    },
    {
        column1: SALES_DEED_DOCUMENT,
        column2: SCANNED_COPY_PDF_PNG_JPEG_JPG
    },
    {
        column1: PUBLIC_AND_PRIVATE_COMPANY,
        column2: SCANNED_COPY_PDF_PNG_JPEG_JPG
    },
    {
        column1: IN_CASE_OF_PARTNERSHIP_DEED_DOCUMENT,
        column2: SCANNED_COPY_PDF_PNG_JPEG_JPG
    },
    {
        column1: IN_CASE_OF_PARTNERSHIP_REG_CERTIFICATE,
        column2: SCANNED_COPY_PDF_PNG_JPEG_JPG
    },
    {
        column1: LATEST_2_YEAR_ITR,
        column2:
            <div>
                {getDigitalCopyInfo(incomeTaxWebsite)}
            </div>
    },
    {
        column1: LATEST_1_YEAR_GST_RETURNS,
        column2:
            <div>
                {getDigitalCopyInfo(gstWebsite)}
                <p><strong>{NOTES_GSTR_INSTRUCTION}</strong></p>
            </div>
    },
    {
        column1: LATEST_N_MONTHS_BANK_STATEMENT,
        column2:
            <div>
                <p>{DIGITAL_COPY_FROM_NET_BANKING_PORTAL}</p>
                <span>{OR_LABEL}</span>
                <p>{KEEP_YOUR_NET_BANKING_CREDENTIALS_READY}</p>
                <span>{OR_LABEL}</span>
                <p>{SCANNED_PDF_INSTRUCTION}</p>
                <p><strong>{OD_CC_LIMIT_INFORMATION}</strong></p>
            </div>
    }
]

/**
 * @description Function returns MSME information
 * @name formatMsmePayload
 * @returns {Object}
 */
export const formatMsmePayload = () => {
    return {
        phone: getUserPhone(),
        organizationId: getOrganizationID()
    }
}

/**
 * @description Function object for rendering onboarding card
 * @name getOnboardingContent
 * @returns {Object}
 */
export const getOnboardingContent = () => {
    let user = getUserData();
    return [
        { className: "Welcome", value: WELCOME },
        { className: "welcomeName", value: user && user.ownerName },
        { className: "welcometext", value: ONBOARDING_MSG }
    ]
}
