import moment from 'moment';

import { utils } from '../../../../utils/utils';

const { verifyGST } = require('../GSTrExpForm/service/index');

const { getItemByName } = utils;

export const generateInsurancePayload = () => {
  return [
    {
      name: "fireInsurance",
      options: ["Yes", "NO"],
      label: "Is Fire protection insurance available for your company?"
    },
    {
      name: "vehicleInsurance",
      options: ["Yes", "NO"],
      label: "Is Vehicle Insurance Available for your company?"
    },
    {
      name: "transitInsurance",
      options: ["Yes", "NO"],
      label: "Is Property in Transit Insurance Available for your company?"
    },
    {
      name: "equipmentInsurance",
      options: ["Yes", "NO"],
      label: "Is Equipment Security Insurance Available for your company?"
    }
  ];
}

const checkPanType = (panType, letter) => {
  return panType && panType.toUpperCase() === letter;
}

export const generateBusinessPayload = (payload) => {

  const { state, onFileChange, onValueChange, openModal, verifyBusinessPan,
    onGstDocumentValidation, panNumberVerify } = payload

  const {
    gstNo, panNo, isGstVerify, isPanVerify, PAN, nocDocument,
    personalPanNumber, isPersonalPanVerify, panType,
    dateOfIncorporation, validGstCertificate, isOtherInfoVerified
  } = state;

  const panCardText = checkPanType(panType, 'P') ? "Personal" : "Business";

  let result = [
    { label: 'Date of Incorporation (Business Start Date)', type: 'date', value: dateOfIncorporation, min: "", max: moment().format('YYYY-MM-DD'), name: 'dateOfIncorporation', onChange: onValueChange, placeholder: 'Enter Date of Incorporation', disabled: isOtherInfoVerified, required: true, errorString: 'Please enter a valid date of incorporation', iconRequired: false },
    {
      label: 'GSTIN', type: 'string', value: gstNo, name: 'gstNo', onChange: onValueChange, isVerify: isGstVerify, onClick: openModal, placeholder: 'Enter GST No.', disabled: isOtherInfoVerified, required: true, max: 15, min:15,
      showVerifyOption: true, errorString: "Please enter valid GSTIN", iconRequired: true
    },

    { label: 'Upload GST Registration Certificate', accept: "application/pdf", type: 'file', name: 'gstCertificate', onChange: onGstDocumentValidation, isVerify: validGstCertificate, disabled: validGstCertificate, required: true, ref: "gstrUpload", iconRequired: true },

    { label: 'Upload Shop and Establishment Certificate', accept: "application/pdf", type: 'file', name: 'nocDocument', onChange: onFileChange, isVerify: nocDocument, disabled: nocDocument && isOtherInfoVerified, required: false, iconRequired: true },
  ]

  const panCardDetails = [
    {
      label: `${panCardText} Pan Number`, type: 'string', value: panNo, name: 'panNo', onChange: onValueChange, isVerify: isPanVerify, onClick: verifyBusinessPan,
      placeholder: `Enter ${panCardText} Pan Number`, disabled: true, required: true, showVerifyOption: true, iconRequired: true
    },
    {
      label: `Personal Pan Number`, type: 'string', value: personalPanNumber, name: 'personalPanNumber', onChange: onValueChange, isVerify: isPersonalPanVerify, onClick: panNumberVerify, iconRequired: true, errorString: "Invalid personal PAN",max: 10, min:10,
      placeholder: `Enter Personal Pan Number`, disabled: isOtherInfoVerified, required: true, showVerifyOption: true
    },

    { label: `Upload Scanned Copy of ${panCardText}  PAN card`, accept: "image/jpeg, image/jpg, image/png", type: 'file', name: 'PAN', onChange: onFileChange, isVerify: PAN, disabled: isOtherInfoVerified, required: true, iconRequired: true}
  ];
  if (isGstVerify) result = result.concat(panCardDetails);

  return result;

}

export const generateBusinessCodePayload = (payload) => {
  const { onValueChange, state } = payload
  const { businessCode, isOtherInfoVerified } = state;
  return {
    label: 'Nivea Customer Code', type: 'string', value: businessCode, name: 'businessCode', onChange: onValueChange, max:10,
    placeholder: 'Enter Nivea Customer Code', disabled: isOtherInfoVerified, required: true, errorString: "Invalid Business code"
  }
}

export const generateRentalAgreement = (payload) => {

  const { state, onFileChange } = payload

  const { saleDeed, isOtherInfoVerified } = state;

  return { label: 'Upload Rent Agreement (Office/Godown)', type: 'file', accept: "application/pdf, image/jpeg, image/jpg, image/png", name: 'saleDeed', onChange: onFileChange, isVerify: saleDeed, placeholder: 'Enter sale deed', disabled: saleDeed && isOtherInfoVerified, required: false, iconRequired: true }
}

export const generateSalesDeed = (payload) => {

  const { state, onFileChange } = payload

  const { saleDeed, isOtherInfoVerified } = state;

  return { label: `Sale Deed document / Property tax paid receipt / Electricity bill copy`, accept: "application/pdf, image/jpeg, image/jpg, image/png", type: 'file', name: 'saleDeed', onChange: onFileChange, isVerify: saleDeed, placeholder: 'Upload Sale Deed document / Property tax paid receipt / electricity bill copy', disabled: saleDeed && isOtherInfoVerified, required: false, iconRequired: true }
}

export const generatePrivateCompanyPayload = (payload) => {

  const { state, onValueChange, onMultipleUploadChange } = payload;

  const { noofDirectors, noofOfficers, incorporation, momCertificate, isOtherInfoVerified } = state;

  return [

    { label: 'Upload a copy of MOA & AOA Certificate', type: 'file', name: 'momCertificate', accept: "application/pdf, image/jpeg, image/jpg, image/png", onChange: onMultipleUploadChange, isVerify: momCertificate, disabled: isOtherInfoVerified, required: true, multipleUpload: true },

    { label: 'Upload Certificate of Incorporation', type: 'file', name: 'incorporation', accept: "application/pdf, image/jpeg, image/jpg, image/png", onChange: onMultipleUploadChange, isVerify: incorporation, disabled: isOtherInfoVerified, required: true },

    { label: 'Enter the List of Directors', type: 'chip', value: noofDirectors, name: 'noofDirectors', onChange: onValueChange, placeholder: 'Enter the List of Directors', disabled: isOtherInfoVerified, required: true, errorString:'Please enter valid input', max: 250 },

    { label: 'Enter the List of Officers concerned in the Management', type: 'chip', value: noofOfficers, name: 'noofOfficers', onChange: onValueChange, placeholder: 'Enter the List of Officers', disabled: isOtherInfoVerified, required: false, errorString: "Please enter valid input", max: 250 }

  ];
}

export const generatePublicCompanyPayload = (payload) => {

  const { state, onValueChange, onMultipleUploadChange } = payload;

  const { noofDirectors, noofOfficers, momCertificate, incorporation, isOtherInfoVerified } = state;

  return [

    { label: 'Upload a copy of MOA & AOA Certificate', type: 'file', name: 'momCertificate', accept: "application/pdf, image/jpeg, image/jpg, image/png", onChange: onMultipleUploadChange, isVerify: momCertificate, disabled: isOtherInfoVerified, required: true, multipleUpload: true },

    { label: 'Upload Certificate of Incorporation', type: 'file', name: 'incorporation', accept: "application/pdf, image/jpeg, image/jpg, image/png", onChange: onMultipleUploadChange, isVerify: incorporation, disabled: isOtherInfoVerified, required: true },

    { label: 'Enter the List of Directors', type: 'chip', value: noofDirectors, name: 'noofDirectors', onChange: onValueChange, placeholder: 'Enter the List of Directors', disabled: isOtherInfoVerified, required: true, errorString:'Please enter valid list of directors', max: 250 },

    { label: 'Enter the List of Officers concerned in the Management', type: 'chip', value: noofOfficers, name: 'noofOfficers', onChange: onValueChange, placeholder: 'Enter the List of Officers', disabled: isOtherInfoVerified, required: false, errorString: "Please enter valid names of officers", max: 250 },

  ];
}


export const generatePartnershipCompanyPayload = (payload) => {

  const { state, onFileChange } = payload;

  const { agreementDeed, partnershipCertificate, isOtherInfoVerified } = state;

  return [

    { label: 'Agreement Deed (Partnership)', type: 'file', name: 'agreementDeed', accept: "application/pdf,image/jpeg, image/jpg, image/png", onChange: onFileChange, isVerify: agreementDeed, disabled: agreementDeed && isOtherInfoVerified, required: true },

    { label: 'Certificate of Registration', type: 'file', name: 'partnershipCertificate', accept: "application/pdf,image/jpeg, image/jpg, image/png", onChange: onFileChange, isVerify: partnershipCertificate, disabled: partnershipCertificate && isOtherInfoVerified, required: false },
  ];
}


export const generateHinduUndividedFamilyPayload = (payload) => {

  const { state, onValueChange } = payload;

  const { nameofHinduUndivedFamily, addresssofHinduUndivedFamily, ageofHinduUndivedFamily, isOtherInfoVerified } = state;

  return [
    { label: 'Enter names', type: 'chip', value: nameofHinduUndivedFamily, name: 'nameofHinduUndivedFamily', onChange: onValueChange, placeholder: 'Enter names', disabled: isOtherInfoVerified, required: true, errorString: "Please enter valid names", max: 250 },

    { label: 'Enter residential address', type: 'string', value: addresssofHinduUndivedFamily, name: 'addresssofHinduUndivedFamily', onChange: onValueChange, placeholder: 'Enter residential address', disabled: isOtherInfoVerified, required: true, errorString: "Please enter valid Address", max: 250 },

    { label: 'Enter ages of the Karta and of all other members of the undivided family', type: 'chip', value: ageofHinduUndivedFamily, name: 'ageofHinduUndivedFamily', onChange: onValueChange, placeholder: 'Enter ages of the Karta and of all other members of the undivided family', disabled: isOtherInfoVerified, required: true, errorString: "Please enter valid Age", max: 250 },
  ];
}


export const generateODPayload = (payload) => {

  const { state, onValueChange } = payload

  const { ODLimit, isVerifiedExceptStatement=false } = state;

  return [
    {
      label: 'OD/CC Limit (in Rs)', type: 'number', value: ODLimit || getItemByName('ODLimit'),
      name: 'ODLimit', onChange: onValueChange,
      placeholder: 'Enter OD/CC Limit', disabled: isVerifiedExceptStatement
    }
  ];
}


export const generateAadharPayload = (payload) => {

  const { state, onFileChange } = payload;

  const { aadhaarNumberBack, aadhaarNumberFront, ocrAddress } = state;

  return [
    {
      label: 'Upload Aadhaar Front Side', accept: "image/jpeg, image/jpg, image/png", type: 'file', name: 'AADHAR_FRONT',
      onChange: onFileChange, isVerify: aadhaarNumberFront, disabled: false, required: true
    },
    {
      label: 'Upload Aadhaar Back Side', accept: "image/jpeg, image/jpg, image/png", type: 'file', name: 'AADHAR_BACK',
      onChange: onFileChange, isVerify: aadhaarNumberBack || ocrAddress, disabled: false, required: true
    },
  ];
}


export const generateStateList = () => {
  return [
    { value: '', label: 'Please choose your state' },
    { value: 'AP', label: 'Andhra Pradesh' },
    { value: 'AR', label: 'Arunachal Pradesh' },
    { value: 'AS', label: 'Assam' },
    { value: 'BR', label: 'Bihar' },
    { value: 'CG', label: 'Chhattisgarh' },
    { value: 'GA', label: 'Goa' },
    { value: 'GJ', label: 'Gujarat' },
    { value: 'HR', label: 'Haryana' },
    { value: 'HP', label: 'Himachal Pradesh' },
    { value: 'JK', label: 'Jammu & Kashmir' },
    { value: 'JH', label: 'Jharkhand' },
    { value: 'KA', label: 'Karnataka' },
    { value: 'KL', label: 'Kerala' },
    { value: 'MP', label: 'Madhya Pradesh' },
    { value: 'MH', label: 'Maharashtra' },
    { value: 'MN', label: 'Manipur' },
    { value: 'ML', label: 'Meghalaya' },
    { value: 'MZ', label: 'Mizoram' },
    { value: 'NL', label: 'Nagaland' },
    { value: 'OR', label: 'Odisha' },
    { value: 'PB', label: 'Punjab' },
    { value: 'RJ', label: 'Rajasthan' },
    { value: 'SK', label: 'Sikkim' },
    { value: 'TN', label: 'Tamil Nadu' },
    { value: 'TS', label: 'Telangana' },
    { value: 'TR', label: 'Tripura' },
    { value: 'UK', label: 'Uttarakhand' },
    { value: 'UP', label: 'Uttar Pradesh' },
    { value: 'WB', label: 'West Bengal' },
    { value: 'AN', label: 'Andaman & Nicobar' },
    { value: 'CH', label: 'Chandigarh' },
    { value: 'DN', label: 'Dadra and Nagar Haveli' },
    { value: 'DD', label: 'Daman & Diu' },
    { value: 'DL', label: 'Delhi' },
    { value: 'LD', label: 'Lakshadweep' },
    { value: 'PY', label: 'Pondicherry' },
  ];
}


export const generateAadharDetailsPayload = (payload) => {

  const { stateValue, onValueChange } = payload;

  const {
    name,
    gender,
    careof,
    district,
    pin_code,
    state,
    address,
    dob
  } = stateValue;

  return [
    { label: 'Full Name', type: 'string', value: name, name: 'name', onChange: onValueChange, placeholder: 'Enter Your Full Name', disabled: false, required: false, errorString: 'The name that you entered is not valid', max: 50 },
    { label: 'DOB', type: 'date', value: dob, min: "", max: moment().subtract(1, 'day').format('YYYY-MM-DD'), name: 'dob', onChange: onValueChange, placeholder: 'Enter Your DOB', disabled: false, required: false, errorString: 'Please enter the valid date of birth' },
    { label: 'Gender', type: 'radio', value: gender, name: 'gender', onChange: onValueChange, placeholder: 'Enter Your Gender', disabled: false, required: false },
    { label: `Father's Name`, type: 'string', value: careof, name: 'careof', onChange: onValueChange, placeholder: 'Enter Your Father"s name', disabled: false, required: false, errorString: 'The name that you entered is not valid', max: 50 },
    { label: 'Address', type: 'string', value: address, name: 'address', onChange: onValueChange, placeholder: 'Enter Your Address', disabled: false, required: false, errorString: 'Maximum allowed character is 250', max: 250 },
    { label: 'Pin Code', type: 'string', value: pin_code, name: 'pin_code', onChange: onValueChange, placeholder: 'Enter Your Pin Code', disabled: false, required: false, errorString: 'The pin code you entered is not valid', max: 6 },
    { label: 'District', type: 'string', value: district, name: 'district', onChange: onValueChange, placeholder: 'Enter Your District', disabled: false, required: false, errorString: 'The district name that you entered is not valid', max: 25 },
    { label: 'State', type: 'dropdown', value: state, name: 'state', onChange: onValueChange, placeholder: 'Enter Your State', disabled: false, required: false, stateList: generateStateList(), errorString: 'Please choose your state' }
  ]
}

export const generateVerifyGSTRPayload = (props) => {

  const { state, renderErrorGrowl, renderSuccessGrowl, updateState } = props;

  const { gstrNumber, isGSTINVerified } = state;

  return {
    label: "GST Number",
    inputValue: gstrNumber,
    placeHolderString: "Enter GSTIN",
    inputType: 'text',
    required: true,
    isVerified: isGSTINVerified,
    errorLabel: 'Enter valid GSTIN',
    initiateVerification: () => verifyGST({
      state: state,
      renderErrorGrowl: renderErrorGrowl,
      renderSuccessGrowl: renderSuccessGrowl,
      updateState: updateState
    })
  }
}