import React from 'react'
import { Redirect } from 'react-router-dom';

import { utils } from '../../utils/utils'

const { getToken , getIsPasswordConfigured } = utils;

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;

        const isAuthenticated = getToken() && getIsPasswordConfigured();
       
        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default ProtectedRoute;