import React from 'react'
import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'


import ErrorBoundary from './components/ErrorBoundary/index';
import DashboardContainer from './screens/Dashboard/container';
import Footer from './components/Footer/index';
import LoginForm from '../src/screens/Login/LoginContainer';
import TermsAndConditionContainer from '../src/screens/termsandconditions/container';
import ProtectedRoute from './components/ProtectedRoute/index';
import { Mixpanel } from './utils/mixPanel'
import insp, { install } from 'inspectlet-es';
import SetPasswordContainer from '../src/screens/SetPassword/SetPasswordContainer'


import { utils } from './utils/utils';
import { config } from "./config/config"

const { MIXPANEL_TOKEN, INSPECT_LET_TOKEN } = config;

install(INSPECT_LET_TOKEN);  // initializing inspectLet token

const { getToken, getUserData, getIsPasswordConfigured } = utils;

Mixpanel.init(MIXPANEL_TOKEN);

if (getUserData() && getUserData().phone) {
  const { phone, emailId = "" } = getUserData();
  Mixpanel.identify(phone);
  insp(['identify', emailId]);
  insp(['tagSession', { emailId, phone }]);
}

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute path="/dashboard" component={DashboardContainer} />
          <Route path="/terms-of-use" component={TermsAndConditionContainer} />
          <Route path="/privacy-policy" component={TermsAndConditionContainer} />
          <Route path="/consent" component={TermsAndConditionContainer} />
          <Route path="/terms-and-condition" component={TermsAndConditionContainer} />
          <Route
            path="/setPassword"
            render={() => {
              if (getToken() || getIsPasswordConfigured()) return <SetPasswordContainer />
              return <LoginForm />
            }
            } />
          <Route
            render={() => {
              if (!getToken() && !getIsPasswordConfigured()) return <LoginForm />
              return <Redirect to='/dashboard' />
            }}
          />
        </Switch>
        <Footer />
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
