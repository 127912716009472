const moment = require('moment');

const _ = require('lodash');

const { fixedValues } = require('../utils/enum/currency');

const { languageStrings } = require('./enum/inputFieldStrings');

const {
  MANDATORY_GSTR_COUNT,
  GSTR_FILING_DEAD_LINE,
  DEFAULT_VALUE,
  MARCH_END,
  APRIL_START,
  DEFAULT_INDEX_ZERO,
  MANDATORY_ITR_FILING_COUNT,
  OPTION_1_YEAR_ITR_FILING,
  MAXIMUM_BANK_STATEMENT_MONTH,
  MIN_MONTHS_GSTR_WITHIN_DEADLINE,
  MIN_MONTHS_GSTR_AFTER_DEADLINE,
  DEFAULT_ITR_FILING_GAP,
  YES,
  NO,
  PAN_TYPE: {
    INDIVIDUAL,
  },
  MINIMAL_GSTR_FILING_PULL_EXPIRY_DAYS,
  GSTR3B_FILING_LABEL,
  GSTR3B_INDIVIDUAL_FILE_UPLOAD_NAME,
  JAN_MARCH,
  APRIL_JUNE,
  JULY_SEPTEMBER,
  OCTOBER_DECEMBER,
  DEFAULT_YEAR,
  DECEMBER,
  DIFFERENCE_BETWEEN_MONTHLY_FILING,
  DEFAULT_ITR_DEADLINE_DAY_MONTH
} = fixedValues;

const OD_CC_ACCOUNT = "Yes"

const SAVING_CURRENT_ACCOUNT = "No"

const MIN_YEARS_FOR_ITR = 2;

const MAX_FILING_RANGE = 2;

/**
 * Function for validating Each Form Fields.
 * @constant
 * @name validatingEachFields
 * @returns - true if condition satisfied / false if condition not-satisfied.
 */
const validatingEachFields = (currentValue) => currentValue !== "";

/**
 * Function for validating Entire Form results
 * @constant
 * @name validatingFormFields
 * @returns - true if every Fields are valid / false if any field is Invalid
 */
const validatingFormFields = (arrayValues) => {
  return arrayValues.every(validatingEachFields);
}

/**
 * Function for validating Each Form Fields.
 * @constant
 * @name validatingEmptyFields
 */
const validatingEmptyFields = (currentValue) => currentValue === "";

/**
 * Function for validating Entire Form results
 * @constant
 * @name validatingEmptyFormFields
 */
const validatingEmptyFormFields = (arrayValues) => {
  return arrayValues.every(validatingEmptyFields);
}

/**
 *
 * @constant
 * @param {Date} 
 * @description year month from and upto which statements should be uploaded
 * @returns {Object}
 */
const formatDateBetweenRange = (date = new Date()) => {
  let currentDate = date;
  if (!date) currentDate = new Date();
  const yearMonthTo = moment(new Date(currentDate))
    .subtract(1, 'months')
    .calendar();
  const yearMonthFrom = moment(new Date(currentDate))
    .subtract(MAXIMUM_BANK_STATEMENT_MONTH, 'months')
    .calendar();
  return {
    yearMonthTo: moment(new Date(yearMonthTo)).format('YYYY-MM'),
    yearMonthFrom: moment(new Date(yearMonthFrom)).format('YYYY-MM'),
  };
};

/**
 * @description Function for validating account type & Od limit
 * @param {String} accountType 
 * @param {Boolean} isValidODLimit 
 * @returns {Boolean}
 */
const isValidAccountInfo = (accountType, isValidODLimit) => {
  return ((accountType === OD_CC_ACCOUNT && isValidODLimit) || accountType === SAVING_CURRENT_ACCOUNT)
}

/**
 * @description Function for extracting Pan from GSTIN
 * @name extractPanNumber
 * @param {String} gst 
 * @returns {String}
 */
const extractPanNumber = (gst = "") => {
  // extracting pan from GSTIN 
  return gst.substr(2, 10)
}

/**
 * @description Function for extracting Pan type from GSTIN
 * @name extractPanTypeFromGSTIN
 * @param {String} gst 
 * @returns {String}
 */
const extractPanTypeFromGSTIN = (gst) => {
  return gst.charAt(5)
}

/**
 * @description Function for extracting Pan type
 * @name extractPanType
 * @param {String} pan 
 * @returns {String}
 */
const extractPanType = (pan) => {
  return pan.charAt(3)
}

/**
* @description Function for getting formatted year for comparison
* @name getFormattedDate
* @param {Object} payload
* @returns {String}
*/
const getFormattedDate = (payload = {}) => {
  let {
    formatString = `YYYY`, condition = 'year',
    numberToBeSubtracted = 0, date = new Date(),
    currentDateFormat = `DD/MM/YYYY`, numberToBeAdded = 0, skipOperation = false
  } = payload;

  if (skipOperation) return moment(date, currentDateFormat).format(formatString)

  return numberToBeAdded
    ? moment(date, currentDateFormat).add(numberToBeAdded, condition).format(formatString)
    :
    moment(date, currentDateFormat).subtract(numberToBeSubtracted, condition).format(formatString)
}

/**
 * @description Function that extracted year from the today`s date
 * @name getCurrentYear
 * @returns {String}
 */
const getCurrentYear = () => {
  return moment().format(`YYYY`);
}
 
/**
 * @description Function that returns current & previous year.
 * @name getAssessmentInfo
 * @param {Object} payload 
 * @returns {Object}
 */
const getAssessmentInfo = (payload = {}) => {

  const { endDate, result = false, startDate } = payload;

  const updateYearCountBy = result ? 0 : 1;

  const currentYearPayload = { date: endDate, currentDateFormat: `YYYY-MM-DD`, skipOperation: result, numberToBeAdded: updateYearCountBy };

  const previousYearPayload = { date: startDate, currentDateFormat: `YYYY-MM-DD`, skipOperation: result, numberToBeAdded: updateYearCountBy };

  return {
    currentYear: getFormattedDate(currentYearPayload),
    previousYear: getFormattedDate(previousYearPayload)
  }
}

/**
 * @description Function returns assessment year based on different date
 * @name getAssessmentInfoForITR
 * @param {Object} payload
 * @returns {Object}
 */
const getAssessmentInfoForITR = (payload) => {
  const { endDate, result = false, startDate } = payload || {};
  const condition = result ? { skipOperation: true } : { skipOperation: false, numberToBeAdded: 1 };
  let currentYear = getFormattedDate({ date: endDate, currentDateFormat: `YYYY-MM-DD`, ...condition });
  let previousYear = getFormattedDate({ date: startDate, currentDateFormat: `YYYY-MM-DD`, ...condition });
  // will take current year for validation check
  let latestYear = moment().year();
  // checking if the current or previous year is less than equals to latestYear
  // if more then latestYear we will not make it mandatory - it is optional
  let isMandatory = currentYear <= latestYear && previousYear <= latestYear;
  return {
    currentYear: moment(currentYear, `YYYY`).format(`YY`),
    previousYear,
    isMandatory
  }
}

/**
* @description Function for returning current and previous year...
* @name getAssessmentYearForITR
* @returns {Object}
*/
const getAssessmentYearForITR = (todayDate = new Date(), getAssessmentInfo) => {
  var currentYear = getFormattedDate({ date: todayDate, currentDateFormat: `MM/YYYY` }) // extracting year from today's date
  let previousYear = getFormattedDate({ numberToBeSubtracted: 1, date: todayDate, currentDateFormat: `MM/YYYY` }) // calculating previous year
  let startDate = `${previousYear}${APRIL_START}`;  // April start of previous year 2020-04-01
  let endDate = `${currentYear}${MARCH_END}`; // March end of current year  2021-03-31
  let result = moment(todayDate).isBetween(startDate, endDate); // checking whether current date is between start and end date
  return getAssessmentInfo({ endDate, result, startDate, originalDate: todayDate })
}

/**
 * @description Function returns valid latest mandatory ITR filing information
 * @name getTotalITRFilingInfo
 * @param {Date} endDate 
 * @returns {Object}
 */
const getTotalITRFilingInfo = (endDate) => {
  let updatedStartDate = moment(endDate).subtract(2, 'years');
  // added to stop month comparison iteration while it reaches zero.
  endDate.add(1, 'year');
  let updatedEndDate = endDate;
  // appending formatted filing info.
  let result = [];
  while (updatedEndDate.diff(updatedStartDate, 'year') > DEFAULT_VALUE) {
    let { currentYear, previousYear, isMandatory } = getAssessmentYearForITR(updatedStartDate, getAssessmentInfoForITR)
    // eliminating other non-mandatory filing information
    isMandatory && result.push(`${previousYear}-${currentYear}`)
    updatedStartDate.add(1, 'year')
  }
  return result
}

/**
 * @description Function returns count of optional ITR filing information
 * @name getOptionalITRFilingCount
 * @param {Date} date
 * @returns {Number}
 */
const getOptionalITRFilingCount = (date = moment()) => {

  let currentYear = moment(date).year();

  let formattedDate = moment(`${DEFAULT_ITR_DEADLINE_DAY_MONTH}/${currentYear}`, `DD/MM/YYYY`);

  // is current date before feb
  const isBeforeFeb = moment(date).isBefore(formattedDate);

  return isBeforeFeb ? OPTION_1_YEAR_ITR_FILING : DEFAULT_VALUE

}

/**
 * @description Function for returning updated filing years information.
 * @name getSlicedITRFilingInfo
 * @param {Array} totalFilingInfo 
 * @param {Number} totalITRFilingCount 
 * @param {Number} optionalITRFilingCount 
 */
const getSlicedITRFilingInfo = (totalFilingInfo, totalITRFilingCount, optionalITRFilingCount) => {

  // removing optional filing from total count
  let updatedFilingCount = totalITRFilingCount - optionalITRFilingCount;

  // getting all the total filing count except optional months.
  let updatedFilingInfo = [];

  // by default removing optional filing details
  updatedFilingInfo = totalFilingInfo.slice(0, Math.max(updatedFilingCount, DEFAULT_VALUE))

  // slicing latest 2 years in case of existing distributor
  if (updatedFilingCount > MANDATORY_ITR_FILING_COUNT)
    updatedFilingInfo = updatedFilingInfo.slice(Math.max(updatedFilingCount - MANDATORY_ITR_FILING_COUNT, DEFAULT_VALUE));

  return updatedFilingInfo

}

/**
 * @description Function returns ITR filing information for rendering
 * @name  getITRFilingInfo
 * @param {Date} endDate 
 * @returns {Object}
 */
const getITRFilingInfo = (endDate = moment()) => {

  let latestFilingInfo = [];

  // getting optional filing count based on Feb 1st
  let optionalITRFilingCount = getOptionalITRFilingCount(endDate);

  let totalFilingInfo = getTotalITRFilingInfo(endDate);

  const totalITRFilingCount = _.size(totalFilingInfo);

  if (totalITRFilingCount) {
    latestFilingInfo = getSlicedITRFilingInfo(totalFilingInfo, totalITRFilingCount, optionalITRFilingCount)
  }

  // getting total filing count
  const mandatoryFilingCount = _.size(latestFilingInfo) - optionalITRFilingCount;

  // if mandatory documents are less than zero we are enabling the warning flag
  const warningStatus = mandatoryFilingCount > DEFAULT_VALUE ? false : true;

  // in case of no GSTr filing info received based on date of incorporation setting warning message.
  const warningMessage = warningStatus ?
    languageStrings.NOT_HAVING_UPDATED_ITR_FILING
    : languageStrings.HAVING_VALID_ITR_FILING

  return {
    latestFilingInfo,
    optionalITRFilingCount,
    mandatoryFilingCount,
    warningStatus,
    warningMessage
  }
}

/**
 * @description Function returns is the date is valid filing date or not
 * if greater than or equal to 25 return true else false
 * @name isDateSameOrMoreThanDeadlineDate
 * @param {Number} date 
 * @returns {Boolean}
 */
const isDateSameOrMoreThanDeadlineDate = (date) => {
  return date >= GSTR_FILING_DEAD_LINE
}

/**
 * @description Function for returning the size of the array element 
 * that is passed on to this getSize function
 * @name getSize
 * @param {Array} arrayElement 
 * @returns {Number}
 */
const getSize = (arrayElement) => {
  return _.size(arrayElement)
}

const getDifferenceInDate = (datePayload) => {
  let {
    startDate = moment(),
    endDate = moment(),
    differenceIn = `months`
  } = datePayload;
  return endDate.diff(startDate, differenceIn)
}

/**
 * @description Function returns is a valid GSTR Month
 * @name  isLatestNineMonthsGSTR
 * @param {Array} totalFilingCount 
 * @param {Number} position 
 * @returns {Boolean}
 */
const isLatestNineMonthsGSTR = (totalFilingCount, position) => {

  // returning mandatory as true if filing count is less than 9 months
  // In case of new born distributors
  if (totalFilingCount <= MANDATORY_GSTR_COUNT) return true

  else return (totalFilingCount >= MANDATORY_GSTR_COUNT && ((totalFilingCount - MANDATORY_GSTR_COUNT) <= position))
}

/**
 * @description Function returns object in specific format for rendering file Upload UI component
 * @name individualGSTRFilingInfo
 * @param {Object} payload 
 * @returns {Object}
 */
const individualGSTRFilingInfo = (payload) => {

  const { filingInfo, position } = payload || {};

  const { filingMonth = "", filingOptions = [], isHavingFilingOptions = false, filingYear } = filingInfo || {};

  const verifiedStatus = false;

  return {
    isHavingFilingOptions,
    filingOptions,
    filingMonth: filingMonth,
    label: `${GSTR3B_FILING_LABEL}`.replace(`{}`, filingMonth),
    verifiedStatus,
    value: "",
    name: `${GSTR3B_INDIVIDUAL_FILE_UPLOAD_NAME}`.replace(`{}`, position),
    isMandatory: true,
    filingYear
  }
}

/**
 * @description Function that returns formatted filing assessment year for processing
 * @name getGSTRFormattedFilingYear
 * @param {String} fy - filingYear
 * @returns {String} 
 */
const getGSTRFormattedFilingYear = (fy) => {

  const [previousAssessmentYear, currentAssessmentYear] = `${fy}`.split('-');

  const formatCurrentFilingYear = moment(currentAssessmentYear, 'YYYY').format(`YY`);

  return `${previousAssessmentYear}-${formatCurrentFilingYear}`
}

/**
 * @description Function returns mapping on each month with respective quarterly filing name
 * @name getGSTRQuarterlyMapping
 * @returns {Object}
 */
const GSTRQuarterlyMapping = {
  January: JAN_MARCH,
  February: JAN_MARCH,
  March: JAN_MARCH,
  April: APRIL_JUNE,
  May: APRIL_JUNE,
  June: APRIL_JUNE,
  July: JULY_SEPTEMBER,
  August: JULY_SEPTEMBER,
  September: JULY_SEPTEMBER,
  October: OCTOBER_DECEMBER,
  November: OCTOBER_DECEMBER,
  December: OCTOBER_DECEMBER,
}

/**
 * @description Function that returns revised filing months with respective year
 * to extract the difference between two different months to check the filing
 * assessment type is Monthly or Quarterly
 * There is not way to identify with filing month cant able to find whether it belongs to which month
 * here we have looped through each filing and upended with respective year 
 * to find difference between 2 different filing months
 * @name getDifferentiatedGSTR3bFilingMonthsWithYear
 * @param {Array} filingMonths 
 * @returns {Array} 
 */
 const getDifferentiatedGSTR3bFilingMonthsWithYear = (filingMonths) => {

  let updatedFilingMonth = [];

  let defaultFilingYear = moment(DEFAULT_YEAR, `YYYY`).format(`YYYY`);

  for (let item of filingMonths) {

    const [filingMonth, filingYear] = `${item}`.split(' ');

    let formattedFilingMonth = moment(`${filingMonth}-${defaultFilingYear}`, `MMMM-YYYY`).format(`YYYY-MM`);

    // incase of current month filing is december then the upcoming filing belongs to the next year
    if (filingMonth === DECEMBER) {

      defaultFilingYear = moment(defaultFilingYear, `YYYY`).add(1, 'year').format(`YYYY`);

    }

    updatedFilingMonth.push({
      formattedFilingMonth,
      filingMonth: filingMonth,
      filingYear: filingYear
    });
  }

  return updatedFilingMonth;
}

/**
 * @description Function that differentiate each month whether it is Monthly or Quarterly filing period
 * @name isMonthlyAssessmentFiling
 * @param {String} previousFiling
 * @param {String} currentFiling
 * @returns {Boolean}
 */
 const isMonthlyFilingAssessment = (previousFiling, currentFiling) => {

  var startDate = moment(`${previousFiling}`, `YYYY-MM`);

  var endDate = moment(`${currentFiling}`, `YYYY-MM`);

  var monthDiff = endDate.diff(startDate, 'months');

  return monthDiff === DIFFERENCE_BETWEEN_MONTHLY_FILING;

}

/**
 * @description Function that returns filing information of first month filing
 * there is no way to identify the filing is Monthly or Quarterly
 * and checking that first filing & second filing lies on same quarterly filing period
 * if lies on same quarterly filing then it is monthly filing
 * else then the filing type can be Monthly or Quarterly
 * And returning the options based on that to render in the UI like a drop down option 
 * to switch between filing verification either Monthly or Quarterly
 * @name getFilingOptions
 * @param {Array} filingInfo 
 * @returns {Object}
 */
const getFilingOptions = filingInfo => {

  const [filingOne = {}, filingTwo = {}] = filingInfo

  const previousFiling = filingOne.formattedFilingMonth;

  const currentFiling = filingTwo.formattedFilingMonth;

  const isMonthlyFiling = isMonthlyFilingAssessment(previousFiling, currentFiling);

  const { filingMonth = '', filingYear = '' } = filingOne;

  const quarterlyFilingPeriodOne = GSTRQuarterlyMapping[`${filingMonth}`]

  const quarterlyFilingPeriodTwo = GSTRQuarterlyMapping[`${filingTwo.filingMonth}`]

  const updatedFilingYear = getGSTRFormattedFilingYear(filingYear)

  const formattedFilingPeriod = `${filingMonth} ${updatedFilingYear}`;

  if (quarterlyFilingPeriodOne === quarterlyFilingPeriodTwo) {
    return {
      filingOptions: [],
      filingMonth: formattedFilingPeriod,
      isHavingFilingOptions: false,
      filingYear: filingYear
    }
  }

  const quarterlyFilingPeriod = GSTRQuarterlyMapping[`${filingMonth}`]

  const updatedQuarterlyFilingPeriod = `${quarterlyFilingPeriod} ${updatedFilingYear}`

  const filingOptions = [
    {
      value: updatedQuarterlyFilingPeriod,
      label: updatedQuarterlyFilingPeriod,
    },
    {
      value: formattedFilingPeriod,
      label: formattedFilingPeriod,
    }
  ]

  return {
    filingOptions,
    filingMonth: isMonthlyFiling ? formattedFilingPeriod : updatedQuarterlyFilingPeriod,
    isHavingFilingOptions: true,
    filingYear: filingYear
  }
}

/**
 * @description Function for initializing GSTR file upload payload for rendering UI component.
 * @name getDefaultFilingInfo
 * @param {Object} payload 
 * @returns {Object}
 */
const getDefaultFilingInfo = filingMonths => {

  const filingPeriod = filingMonths.map(item => `${item.taxp} ${item.fy}`)

  const reversedFilingPeriod = [...filingPeriod].reverse();

  const updatedFilingPeriod = [];

  const revisedFilingMonths = getDifferentiatedGSTR3bFilingMonthsWithYear(reversedFilingPeriod)

  // first filing month from GSTR filing response
  // doesn`t have any previous month for validating whether it is Monthly or Quarterly filing
  // and providing both Monthly & Quarterly options for verification process.
  if (revisedFilingMonths) {

    const filingInfo = getFilingOptions(revisedFilingMonths)

    updatedFilingPeriod.push(filingInfo)
  }

  for (let item = 1; item < _.size(revisedFilingMonths); item++) {

    const previousFiling = revisedFilingMonths[item - 1].formattedFilingMonth

    const currentFiling = revisedFilingMonths[item].formattedFilingMonth

    const isMonthlyFiling = isMonthlyFilingAssessment(previousFiling, currentFiling)

    const { filingYear, filingMonth } = revisedFilingMonths[item];

    const updatedFilingYear = getGSTRFormattedFilingYear(filingYear)

    if (isMonthlyFiling) {

      const formattedFilingPeriod = `${filingMonth} ${updatedFilingYear}`

      updatedFilingPeriod.push({
        filingMonth: formattedFilingPeriod,
        filingYear: filingYear
      })
    } else {
      const quarterlyFilingPeriod = GSTRQuarterlyMapping[`${filingMonth}`]

      updatedFilingPeriod.push({
        filingMonth: `${quarterlyFilingPeriod} ${updatedFilingYear}`,
        filingYear: filingYear
      })
    }
  }

  const initializedFilingInfo = updatedFilingPeriod.map((filingInfo, position) =>
    individualGSTRFilingInfo({ filingInfo, position }),
  )

  return initializedFilingInfo
}

/**
 * @description Function for updating existing filing information with status & URL.
 * @name updateDefaultFilingInfo
 * @param {Object} payload 
 * @returns {Array}
 */
const updateDefaultFilingInfo = (payload) => {

  const { fileUploadInfo = [], filingMonth, verifiedStatus, url } = payload || {};

  let arrayPosition = _.findIndex(fileUploadInfo, (item) => item.filingMonth === filingMonth);

  if (arrayPosition >= DEFAULT_INDEX_ZERO) {
    fileUploadInfo[arrayPosition].value = url;
    fileUploadInfo[arrayPosition].verifiedStatus = verifiedStatus;
  }

  return fileUploadInfo;

}

/**
 * @description Function returns total mandatory documents in GSTR.
 * @name getGSTRMandatoryDocuments
 * @param {Object} payload 
 * @returns {Number}
 */
const getGSTRMandatoryDocuments = (payload) => {

  const { fileUploadInfo = [] } = payload || {};

  let mandatoryDocuments = fileUploadInfo.filter(item => item.isMandatory === true);

  return mandatoryDocuments;

}

/**
 * @description Function for getting URL of all the GSTR file that are uploaded.
 * @name getGSTRFiles
 * @param {Object} fileUploadInfo 
 * @returns {Array}
 */
const getGSTRFiles = (fileUploadInfo) => {

  let gstrFiles = [];

  gstrFiles = fileUploadInfo.map(item => item.value);

  return gstrFiles
}

/**
 * @description Function returns dummy response for initializing the ITR parsed information
 * @name getDummyITRParsedInfo
 * @param {String} businessPan 
 * @returns {Object}
 */
const getDummyITRParsedInfo = (businessPan) => {
  return {
    "financialManagement": {
      "totalRevenue": 0,
      "accountsReceivables": 0,
      "currentLiabilities": 0,
      "currentAssets": 0,
      "totalAssets": 0,
      "totalLiabilities": 0,
      "totalWages": 0,
      "grossProfitMargin": 0,
      "incentives": 0,
      "immediateAndCashEquivalents": 0,
      "ebitda": 0,
      "interestPayable": 0,
      "netProfit": 0,
      "debtorDays": 0,
      "creditorDays": 0
    },
    "financialStanding": {
      "totalDebt": 0,
      "totalEquity": 0,
      "totalAssets": 0,
      "ebitda": 0,
      "pbit": 0,
      "totalInterestPaid": 0,
      "goodsSoldPrice": 0,
      "sundryCreditors": 0,
      "itrFilingGap": 0,
      "ownFunds": 0
    },
    "stabilitySurvival": {
      "revenueGrowth": 0
    },
    "revenuePotential": {
      "totalRevenue": 0,
      "totalExpense": 0,
      "turnOver": 0
    },
    "insuranceData": {
      "commercialPropertyInsurance": false,
      "fireAndBurglaryInsurance": false,
      "plantAndMachineryInsurance": false,
      "commercialAutoOrVehicle": false,
      "propertyInTransit": false,
      "keyManInsurance": false
    },
    "others": {
      "totalSecuredLoans": 0,
      "totalUnsecuredLoans": 0,
      "netProfit": 0,
      "depreciation": 0,
      "interestPayable": 0,
      "totalRevenue": 0
    },
    "metaData": {
      "itrType": "ITR",
      "assessmentYear": "",
      "pan": `${businessPan}`,
      "message": "Dummy ITR Response"
    },
    "documentAssessmentYear": 0
  }
}

/**
 * @description Function returns object in specific format for rendering file upload for ITR.
 * @name individualITRFilingInfo
 * @param {Object} payload
 * @returns {Object} 
 */
const individualITRFilingInfo = (payload) => {
  const { filedYear, position, existingInfo = [] } = payload || {};
  const { businessPan, parsedITR = "", itrInfo = "" } = existingInfo || {};
  let fileLocationURL = itrInfo[position] ? itrInfo[position] : '';
  let individualParsedInfo = parsedITR[position] ? parsedITR[position] : getDummyITRParsedInfo(businessPan);
  let verifiedStatus = fileLocationURL ? true : false;
  let isMandatory = true;
  return {
    filedYear,
    label: 'Provide ITR file for AY {}'.replace(`{}`, filedYear),
    verifiedStatus,
    value: fileLocationURL,
    name: 'file{}'.replace(`{}`, position),
    isMandatory,
    parsedInfo: individualParsedInfo
  }
}

/**
 * @description Function for initializing ITR file upload payload for rendering UI component.
 * @name getDefaultITRFilingInfo
 * @param {Object} payload 
 * @returns {Object}
 */
const getDefaultITRFilingInfo = (payload) => {

  const { latestFilingInfo = [] } = payload;

  let initializedFilingInfo = latestFilingInfo.map((filedYear, position) =>
    individualITRFilingInfo({ filedYear, position, existingInfo: payload }))

  return { initializedFilingInfo }

}

/**
 * @description Function for updating existing filing information with status & URL.
 * @name updateDefaultITRFilingInfo
 * @param {Object} payload 
 * @returns {Array}
 */
const updateDefaultITRFilingInfo = (payload) => {

  const { fileUploadInfo = [], filedYear, verifiedStatus, url, parsedInfo = [] } = payload || {};

  let arrayPosition = _.findIndex(fileUploadInfo, (item) => item.filedYear === filedYear);

  if (arrayPosition >= DEFAULT_INDEX_ZERO) {
    fileUploadInfo[arrayPosition].value = url;
    fileUploadInfo[arrayPosition].verifiedStatus = verifiedStatus;
    fileUploadInfo[arrayPosition].parsedInfo = parsedInfo;
  }

  return fileUploadInfo;

}

/**
 * Function for formatting Parsed ITR and adding itrFilingFap as 12 
 * @constant
 * @name parsedItrFormatter
 * @param {Array} parsedItr 
 * @returns {Object}
 */
const parsedItrFormatter = (parsedItr = [{}, {}]) => {
  const [prevYearItr, currentYear] = parsedItr || [];
  return [
    prevYearItr,
    {
      ...currentYear,
      financialStanding: {
        ...currentYear.financialStanding,
        itrFilingGap: DEFAULT_ITR_FILING_GAP
      }
    }
  ]
}

/**
 * @description Function for getting all information of ITR parsed data.
 * @name getITRInfo
 * @param {Object} fileUploadInfo 
 * @returns {Array}
 */
const getITRInfo = (fileUploadInfo = []) => {

  let itrFiles = [];

  // extracting URL from where the files can be received.
  itrFiles = fileUploadInfo.map(item => item.value);

  // getting parsedInfo once file verified successful
  let parsedITR = fileUploadInfo.map(item => item.parsedInfo);

  return {
    itrInfo: itrFiles,
    parsedITR: parsedItrFormatter(parsedITR)
  }
}

/**
 * @description Function returns mandatory file upload info
 * @name getMandatoryDocuments
 * @param {Array} fileUploadInfo 
 * @returns {Array}
 */
const getMandatoryDocuments = (fileUploadInfo) => {

  let result = [];

  result = fileUploadInfo.filter(item => item.isMandatory);

  return result

}

/**
 * @description Function returns whether all the mandatory documents are verified.
 * @name isMandatoryDocumentsVerified
 * @param {Object} mandatoryDocuments
 * @returns {Boolean}
 */
const isMandatoryDocumentsVerified = (mandatoryDocuments) => {

  let isVerified = [];

  isVerified = mandatoryDocuments.every(item => item.verifiedStatus === true)

  return isVerified;

}

/**
 * @description Function returns whether all documents & information provided are verified.
 * @name isAllInformationVerified
 * @param {*} information 
 */
const isAllInformationVerified = (information) => {

  let result = true;

  result = information.every(item => item === true)

  return result

}

/**
 * @description Function returns mandatory filing information
 * @name extractMandatoryFilingInfo
 * @param {Array} mandatoryDocuments 
 * @returns {Array}
 */
const extractMandatoryFilingInfo = (fileUploadInfo) => {

  let result = [];

  // extracting mandatory documents
  let mandatoryDocuments = getMandatoryDocuments(fileUploadInfo);

  // extracting only filing month
  result = mandatoryDocuments.map(item => item.filedYear);

  return result

}

/**
 * @description Function returns valid filing instruction for ITR
 * @name getValidITRFilingInstruction
 * @param {Object} payload
 * @returns {String} 
 */
const getValidITRFilingInstruction = (payload) => {

  const { fileUploadInfo } = payload

  let mandatoryDocuments = extractMandatoryFilingInfo(fileUploadInfo);

  let mandatoryCount = _.size(mandatoryDocuments);

  let instructionMessage = mandatoryCount > 1 ?
    `${mandatoryDocuments[0]} & ${mandatoryDocuments[mandatoryCount - 1]}`
    : mandatoryDocuments[0]

  return instructionMessage
}

/**
 * Returns true if PAN type is 'P' and below cases,
 * 1. If current date is same or more than deadline(25) and less than 10 months difference between current date and Date of Incorporation
 * 2. If current date is less than deadline date and less than 11 months difference between current date and Date of Incorporation
 * @constant
 * @function
 * @name isProprietorAndInvalidBusinessVintage
 * @param {string} panType
 * @param {number} monthDifference
 * @param {number} minMonthsForGSTR
 * @returns 
 */
const isProprietorAndInvalidBusinessVintage = (panType, monthDifference, minMonthsForGSTR) => (panType === INDIVIDUAL && monthDifference < minMonthsForGSTR);

/**
 * Returns true if PAN type is not 'P' and below case,
 * 1. If less than 2 years between current date and Date of Incorporation
 * @constant
 * @function
 * @name isNotProprietorAndInvalidBusinessVintage
 * @param {string} panType
 * @param {number} yearDifference
 * @returns 
 */
const isNotProprietorAndInvalidBusinessVintage = (panType, yearDifference) => (panType !== INDIVIDUAL && yearDifference < MIN_YEARS_FOR_ITR)

/**
 * Function for checking criteria to proceed from business screen
 * @constant
 * @function
 * @name validateBusinessCriteria
 * @param {object} state 
 * @returns {boolean}
 */
const validateBusinessCriteria = (state, today = moment(new Date(), 'DD/MM/YYY')) => {
  const { dateOfIncorporation = "", panType = "" } = state;
  const monthDifference = today.diff(dateOfIncorporation, "months");
  const yearDifference = today.diff(dateOfIncorporation, "years");
  const minMonthsForGSTR = isDateSameOrMoreThanDeadlineDate(moment(today).date()) ? MIN_MONTHS_GSTR_AFTER_DEADLINE : MIN_MONTHS_GSTR_WITHIN_DEADLINE;
  // check for scenarios
  // 1 - Distributor is having other than proprietor PAN and date of incorporation is less than 2 years from current date = can't proceed as min for ITR is 2
  // 2 - Distributor is having proprietor PAN but date of incorporation is less than 9 months from current date = can't proceed as min for GSTr is 9
  // Other than above scenarios all distributors can be able to proceed to further screen
  const isInvalidBusinessCriteria = isProprietorAndInvalidBusinessVintage(panType, monthDifference, minMonthsForGSTR) ||
    isNotProprietorAndInvalidBusinessVintage(panType, yearDifference);
  return !isInvalidBusinessCriteria;
}

/**
 * Function that returns true for below cases, else false:
 * 1. If Account type is OD/CC and avg balance is -ve it is valid.
 * 2. If Account type is Savings/Current and balance is +ve it is valid.
 * @constant
 * @function
 * @name isValidStatementForAccountType
 * @param {number} balAvg - average balance from the statement data
 * @param {string} ODaccount - account type Savings/Current if 'No', else 'OD/CC account'
 * @returns 
 */
 const isValidStatementForAccountType = (balAvg, ODaccount) => ((balAvg < DEFAULT_VALUE && ODaccount === YES) || (balAvg >= DEFAULT_VALUE && ODaccount === NO))

 /**
  * Function returns true only if Account type is OD/CC and balance is +ve(statement uploaded is savings), else false
  * @constant
  * @function
  * @name removeBankStatementData
  * @param {number} balAvg - average balance from the statement data
  * @param {string} ODaccount - account type Savings/Current if 'No', else 'OD/CC account'
  * @returns 
  */
 const removeBankStatementData = (balAvg, ODaccount) => (balAvg >= DEFAULT_VALUE && ODaccount === YES);

/**
 * Function for validating the account type against average bank balance
 * 1. If Account type is OD/CC and balance is -ve it is valid.
 * 2. If Account type is OD/CC and balance is +ve it is invalid, remove the statement data and ask user to upload valid statement.
 * 3. If Account type is Savings/Current and balance is +ve it is valid.
 * 4. If Account type is Savings/Current and balance is -ve it is invalid, ask user to select proper account type and provide valid OD/CC limit.
 * @constant
 * @function
 * @name validateBankDataForOdLimit
 * @param {object} context
 * @returns {object}
 */
 const validateBankDataForOdLimit = (state) => {
  const { statementFetch = {}, ODaccount = "" } = state;
  const { summaryInfo = {} } = statementFetch;
  const { average = {} } = summaryInfo;
  const { balAvg = 0 } = average;
  return {
    valid: isValidStatementForAccountType(balAvg, ODaccount),
    removeBankStatementData: removeBankStatementData(balAvg, ODaccount)
  }
}

/**
 * @description Function returns whether GSTR filing information is expired or not
 * example : Consider GSTR.No verified on 13/07/2021 and distributor have initiated onboarding more than 10 days
 * Then returning false & allowing DB to verify again to pull updated GSTR FIling info
 * else returning true & allowing them to upload & verify GSTR3b documents
 * @name isGSTRFilingExpired
 * @param {Date} date
 * @returns {Boolean}
 */
export const isGSTRFilingExpired = (date) => {

  const lastVerifiedDate = moment(`${date}`, 'YYYY-MM-DD');

  const differentInDays = moment().diff(lastVerifiedDate, 'days');

  return differentInDays >= MINIMAL_GSTR_FILING_PULL_EXPIRY_DAYS ? false : true
}

/**
 * @description Function for getting formatted current year & previous year for GSTR file validation.
 * @name getAssessmentYearForGSTRValidation
 * @param {Array} filingInfo
 * @returns {Object}
 */
const getAssessmentYearForGSTRValidation = (filingInfo) => {

  const assessmentYears = filingInfo.map((item) => getGSTRFormattedFilingYear(item.fy));

  const uniqueAssessmentYear = _.uniq(assessmentYears);

  // extracting latest assessment year
  const [currentYear, nextYear] = uniqueAssessmentYear && uniqueAssessmentYear[0].split(`-`);

  // generating previous assessment year
  const formattedCurrentYear = moment(currentYear, `YYYY`).format('YY');

  const previousYear = moment(currentYear, `YYYY`).subtract(1, `year`).format(`YYYY`);

  return {
    currentYear: `${currentYear}-${nextYear}`,  // 2020-21
    previousYear: `${previousYear}-${formattedCurrentYear}` // 2019-20
  }
}

const isValidGSTRQuarterlyFiling = (date) => moment(date, `MMM-MMM YYYY-YY`, true).isValid()

/**
 * @description Function that returns GSTR filing mandatory filing period
 * @name getMandatoryDocumentRange
 * @param {Array} fileUploadInfo 
 * @returns {String}
 */
const getMandatoryDocumentRange = (fileUploadInfo = []) => {

  // filtering out mandatory documents
  const mandatoryDocuments = fileUploadInfo.filter((item) => item.isMandatory || item.type === "Monthly");

  // extracting filing month period
  const updatedFilingPeriod = mandatoryDocuments.map((item) => item.filingMonth)

  // getting mandatory filing count
  const totalMandatoryFilingCount = _.size(updatedFilingPeriod);

  // is having max filing count - extracting - filing range
  if (totalMandatoryFilingCount >= MAX_FILING_RANGE) {
    return `${updatedFilingPeriod[0]} - ${updatedFilingPeriod[totalMandatoryFilingCount - 1]}`
  }

  // in case of minimal filing range returning it
  return _.isEmpty(updatedFilingPeriod) ? "" : `${updatedFilingPeriod[0]}`
}

export const utilFunctions = {
  validatingEachFields, validatingFormFields, validatingEmptyFormFields, formatDateBetweenRange, isValidAccountInfo, extractPanNumber, extractPanTypeFromGSTIN,
  extractPanType, getFormattedDate, getAssessmentInfo, getAssessmentInfoForITR, getTotalITRFilingInfo, getOptionalITRFilingCount,
  getSlicedITRFilingInfo, getITRFilingInfo, isDateSameOrMoreThanDeadlineDate, getSize, getDifferenceInDate, isLatestNineMonthsGSTR,
  individualGSTRFilingInfo, getDefaultFilingInfo, updateDefaultFilingInfo, getGSTRMandatoryDocuments, getGSTRFiles, getAssessmentYearForGSTRValidation,
  getDummyITRParsedInfo, individualITRFilingInfo, getDefaultITRFilingInfo, updateDefaultITRFilingInfo, getITRInfo, getMandatoryDocuments, isMandatoryDocumentsVerified, validateBankDataForOdLimit,
  removeBankStatementData, isValidStatementForAccountType,
  isAllInformationVerified, extractMandatoryFilingInfo, getValidITRFilingInstruction, getCurrentYear, validateBusinessCriteria,
  isGSTRFilingExpired, isValidGSTRQuarterlyFiling, getMandatoryDocumentRange
}