import { sendAadharOTP, verifyAadharOtpMsg, verifyAadharCaptcha, fetchDocumentOCR, aadharSkipOtpMsg, getZipCodeAddress } from '../../../../../utils/apiService';
import { Mixpanel } from '../../../../../utils/mixPanel';
import { trackString } from '../../../../../utils/mixpanelTracks';
import { generateStateList } from '../../FormPayload'
import { regexExp } from '../../../../../utils/validationRegex/index';
import moment from 'moment';
import _ from 'lodash';
import { fixedValues } from '../../../../../utils/enum/currency';

const stopLoader = { showSubmit: true, loader: false };

const { MIN_AGE_LIMIT, MAX_AGE_LIMIT } = fixedValues;

const { AADHAR_REG } = regexExp;


export const sendAadharCaptcha = async (payload) => {

  const { state, updateState } = payload;

  if (!state.aadharNumber || !AADHAR_REG.test(state.aadharNumber)) return updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Enter a valid Aadhaar Number' });

  updateState({ loader: true });

  const result = await verifyAadharCaptcha({
    aadharNumber: state.aadharNumber
  });

  if (!result) {
    Mixpanel.track(trackString.AADHAAR_OTP_SEND_FAILED);
    return;
  }

  const status_code = result && result['status-code'];

  if (status_code === '101') {
    updateState({ loader: false, visible: true, captachaImage: result && result.captcha, captachaCode: "" });
    Mixpanel.track(trackString.AADHAAR_OTP_SEND_SUCCESSFULLY);
    return;
  }

  if (status_code === '103') {
    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'There is an issue in verifying your Aadhaar number. Please try again after some time' });

    Mixpanel.track(trackString.AADHAAR_OTP_SEND_FAILED);

  }
  updateState(stopLoader);

}

export const sendAadharOTPmsg = async (payload) => {

  const { state, updateState } = payload;

  if (!state.aadharNumber || !AADHAR_REG.test(state.aadharNumber)) {

    Mixpanel.track(trackString.VERIFYING_AADHAAR_CAPTCHA_FAILED);

    return updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Enter a valid Aadhaar Number' });
  }

  if (!(state && state.captachaCode)) return updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Enter a verification code' });

  const result = await sendAadharOtp(payload);

  if (!result) return;

  const status_code = result && result['status-code'];

  if (status_code === '101') {
    updateState({ showSubmit: false, visible: false, loader: false, showOTPPage: true, showVerify: true, showGrowl: true, growlStatus: 'success', growlMsg: result.message });
    return;
  }

  if (status_code === '102') updateState({ visible: false, showSubmit: true, loader: false, showGrowl: true, growlStatus: showErrorMsg(result.message), growlMsg: result.message });

  if (checkAadharErrorText(result && result.message) && status_code === '102') updateState({ showSubmit: false, visible: false, loader: false, showOTPPage: true, showVerify: true });

  if (status_code === '103') updateState({ showGrowl: true, visible: false, loader: false, growlStatus: 'error', growlMsg: 'There is an issue in verifying your Aadhaar number. Please try again after some time' });


  if (status_code === "104") {
    updateState({ showGrowl: true, visible: false, growlStatus: 'error', growlMsg: 'OTP Page Expired' });

    updateState(stopLoader);

  }
}

export const verifyAadharOTP = async (payload) => {

  const { state, updateState } = payload;

  if (!state.otp) return updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Enter OTP Number' });

  const result = await verifyAadhar(payload);

  if (!result) return;

  const status_code = result && result['status-code'];

  if (status_code === '101') {

    const aadhaarData = formatAadharData(result.result.data);

    if(!aadhaarData) return updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'User not within age limit for onboarding', loader: false, showVerify: true });

    updateState({ loader: false, showOTPPage: false, showVerify: false, showDetails: false, aadharDetails: result.result.data, isSkipOtp: true, showGrowl: true, growlStatus: 'success', growlMsg: result.message, ...aadhaarData });

    Mixpanel.track(trackString.VERIFIED_AADHAAR_USING_OTP_SUCCESSFULLY);

    Mixpanel.track(trackString.COMPLETED_PERSONAL_DETAILS_FORM);

    return;
  }

  if (status_code === '102') updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Invalid OTP Number' });

  Mixpanel.track(trackString.VERIFYING_AADHAAR_OTP_FAILED)

  updateState({ showVerify: true, loader: false });

}

async function sendAadharOtp(payload) {

  const { state, updateState } = payload;

  updateState({ showSubmit: false, loader: true });

  const otpPayload = {
    aadharNumber: state.aadharNumber,
    captchaCode: state.captachaCode
  }

  try {

    const result = await sendAadharOTP(otpPayload);

    return result;

  } catch (error) {

    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'There is an issue in verifying your Aadhaar number. Please try again after some time', showSubmit: true, loader: false });

    return false;
  }

}


async function verifyAadhar(payload) {

  const { state, updateState } = payload;

  updateState({ showVerify: false, loader: true });

  const aadharpayload = {
    aadharNumber: state.aadharNumber,
    otp: state.otp
  }

  try {

    const result = await verifyAadharOtpMsg(aadharpayload);

    return result;

  } catch (error) {

    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'There is an issue in verifying your Aadhaar number. Please try again after some time', showSubmit: true, loader: false });

    return false;
  }

}

export const fetchDocumentDetails = async (payload) => {

  const { updateState, formData } = payload;

  updateState({ popLoader: true });

  try {

    const result = await fetchDocumentOCR(formData);

    return result;

  } catch (error) {

    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'There is an issue in verifying your documents. Please try again after some time', popLoader: false });

    return false;
  }

}

export const aadharSkipOtp = async (payload) => {

  const { updateState, state } = payload;

  updateState({ loader: true });

  const aadharpayload = {
    aadharNumber: state.aadharNumber,
  }

  try {

    const result = await aadharSkipOtpMsg(aadharpayload);

    Mixpanel.track(trackString.COMPLETED_PERSONAL_DETAILS_FORM_WITHOUT_OTP);

    return result;

  } catch (error) {

    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'There is an issue in Skipping Otp Msg. Please try again after some time', loader: false });

    return false;
  }

}


export const verifyPinCode = async (payload) => {

  const { updateState, pinCode, state } = payload;

  try {

    Mixpanel.track(trackString.INITIATING_FETCHING_PIN_CODE_DETAILS);

    updateState({ popLoader: true, pin_code: pinCode });

    const result = await getZipCodeAddress(pinCode);

    const { records = [] } = result;

    let statePayload = generateStateList().filter(payload => payload.label.toUpperCase() === records[0].statename) || [{ value: "" }];

    const { value = "" } = statePayload[0];

    if (records.length > 0) {
      Mixpanel.track(trackString.SUCCESSFULLY_FETCHED_PIN_CODE_DETAILS);

      updateState({ popLoader: false, district: records[0].districtname, state: value, stateName: records[0].statename, subDistrict: records[0].taluk });
      return;
    }

    Mixpanel.track(trackString.INVALID_PIN_CODE);

    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Invalid PIN code', popLoader: false, stateName: "", state: "", errorStatus: { ...state.errorStatus, district: "" } });

  } catch (error) {
    Mixpanel.track(trackString.ERROR_IN_FETCHING_PIN_CODE_DETAILS);

    updateState({ showGrowl: true, growlStatus: 'error', growlMsg: 'Invalid PIN code', popLoader: false, stateName: "", state: "", district: "" });
  }
}


const showErrorMsg = (text) => {
  if (text === 'Please Enter Valid Captcha') {

    Mixpanel.track(trackString.VERIFYING_AADHAAR_CAPTCHA_FAILED);

    return 'error';

  } else {

    Mixpanel.track(trackString.VERIFIED_AADHAAR_CAPTCHA_SUCCESSFULLY);

    return 'success';

  }
}

const checkAadharErrorText = (text) => {
  return text === "Aadhaar number does not have mobile number.";
}

const formatAadharData = (aadhaarDetails = {}) => {
  const {
    name = "", careof = "", countr = "", district = "", dob = "", gender = "", state = "", photo = "", houseNumber = "", street = "", locality = "", landmark = "", pin_code = ""
  } = aadhaarDetails || {};

  let statePayload = generateStateList().filter(payload => payload.label.toUpperCase() === state.toUpperCase()) || [{ value: "" }];

  const { value = "" } = _.isEmpty(statePayload) ? {} : statePayload[0];

  let fatherName = "";

  const age = moment().diff(dob, 'years');

  if (age < MIN_AGE_LIMIT || age >= MAX_AGE_LIMIT) {
    return false;
  }

  if (careof.includes("S/O") || careof.includes("D/O")) {
    fatherName = careof.replace(/(S\/O|D\/O)/, "").replace(/[^a-zA-Z ]/g, "").trim();
  }

  return {
    name,
    careof: fatherName,
    countr,
    district,
    dob: moment(dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    gender: gender === "M" ? "Male" : "Female",
    state: value,
    stateName: state,
    photo,
    address: `${houseNumber} ${street} ${locality} ${landmark}`.trim(),
    pin_code,
    houseNumber,
    street
  }
}