/**
 * Login page tracks
 */
const loginPageStrings = {
  OTP_SEND_SUCCESSFULLY: 'OTP_SEND_SUCCESSFULLY',
  OTP_SEND_FAILED: 'OTP_SEND_FAILED',
  FACING_ISSUE_WHILE_SENDING_OTP: 'FACING_ISSUE_WHILE_SENDING_OTP',

  LOGGED_IN_SUCCESSFULLY_WITH_OTP: 'LOGGED_IN_SUCCESSFULLY_WITH_OTP',
  LOGGING_IN_FAILED_WITH_OTP: 'LOGGING_IN_FAILED_WITH_OTP',
  FACING_ISSUE_WHILE_LOGGING_IN_WITH_OTP: 'FACING_ISSUE_WHILE_LOGGING_IN_WITH_OTP',

  LOGGED_IN_SUCCESSFULLY_WITH_PASSWORD: 'LOGGED_IN_SUCCESSFULLY_WITH_PASSWORD',
  FAILED_LOGGED_IN_WITH_PASSWORD: 'FAILED_LOGGED_IN_WITH_PASSWORD',
  FACING_ISSUE_WHILE_LOGGED_IN_WITH_PASSWORD: 'FACING_ISSUE_WHILE_LOGGED_IN_WITH_PASSWORD',


  VISITED_FORGOT_PASSWORD: "VISITED_FORGOT_PASSWORD"
};

const setPasswordScreenStrings = {
  VISITED_SET_PASSWORD_SCREEN: "VISITED_SET_PASSWORD_SCREEN",
  UPDATED_PASSWORD_SUCCESSFULLY: "UPDATED_PASSWORD_SUCCESSFULLY",
  FAILED_WHILE_UPDATING_PASSWORD: "FAILED_WHILE_UPDATING_PASSWORD",
  FACING_ISSUE_WHILE_UPDATING_PASSWORD: "FAILED_WHILE_UPDATING_PASSWORD",
};


const businessDetailsScreen = {
  EXCEPT_GST_CERTIFICATE_OTHER_INFORMATION_ARE_VERIFIED: "EXCEPT_GST_CERTIFICATE_OTHER_INFORMATION_ARE_VERIFIED",
  BUSINESS_DETAILS_SCREEN_SUCCESSFULLY_COMPLETED: "BUSINESS_DETAILS_SCREEN_SUCCESSFULLY_COMPLETED",
  FACING_ISSUE_WHILE_FETCHING_GSTR_NUMBER: "FACING_ISSUE_WHILE_FETCHING_GSTR_NUMBER",
  FACING_ISSUE_WHILE_VERIFYING_GST_CAPTCHA: "FACING_ISSUE_WHILE_VERIFYING_GST_CAPTCHA",
  FACING_ISSUE_WHILE_VERIFYING_GST_NUMBER: "FACING_ISSUE_WHILE_VERIFYING_GST_NUMBER",
}

const crifDetailsScreen = {
  VISITED_CRIF_QUESTIONNAIRE_SCREEN : "VISITED_CRIF_QUESTIONNAIRE_SCREEN"
}

const itrDetailsScreen = {
  VISITED_ITR_VERIFICATION_SCREEN : "VISITED_ITR_VERIFICATION_SCREEN",
  PARTIALLY_SAVED_AND_PROCEEDED_ITR_FILING: "PARTIALLY_SAVED_AND_PROCEEDED_ITR_FILING",
  VERIFIED_ITR_DOCUMENTS_SUCCESSFULLY : "VERIFIED_ITR_DOCUMENTS_SUCCESSFULLY",
  FACING_ISSUE_WHILE_VERIFYING_ITR_DOCUMENTS : "FACING_ISSUE_WHILE_VERIFYING_ITR_DOCUMENTS"
}

const gstrDetailsScreen = {
  VISITED_GSTR_VERIFICATION_SCREEN : "VISITED_GSTR_VERIFICATION_SCREEN",
  PARTIALLY_SAVED_AND_PROCEEDED_GSTR_FILING: "PARTIALLY_SAVED_AND_PROCEEDED_GSTR_FILING",
  VERIFIED_GSTR_DOCUMENTS_SUCCESSFULLY : "VERIFIED_GSTR_DOCUMENTS_SUCCESSFULLY",
  FACING_ISSUE_WHILE_VERIFYING_GSTR_DOCUMENTS : "FACING_ISSUE_WHILE_VERIFYING_GSTR_DOCUMENTS"
}


const summaryScreen = {
  VISITED_SUMMARY_SCREEN: "VISITED_SUMMARY_SCREEN",
  DISTRIBUTOR_COMPLETED_ASSESSMENT: 'DISTRIBUTOR_COMPLETED_ASSESSMENT',
  COMPLETING_ASSESSMENT_FAILED: 'COMPLETING_ASSESSMENT_FAILED'
}

/**
 * Dashboard page tracks
 */

const dashboardPageStrings = {

  DISTRIBUTOR_STARTED_ASSESSMENT: 'DISTRIBUTOR_STARTED_ASSESSMENT',

  ONBOARDING_PAGE: 'ONBOARDING_PAGE',
  DASHBOARD_PAGE: 'DASHBOARD_PAGE',

  PERSONAL_DETAILS_FORM_PAGE: 'PERSONAL_DETAILS_FORM_PAGE',
  BUSINESS_DETAILS_FORM_PAGE: 'BUSINESS_DETAILS_FORM_PAGE',
  CRIF_QUESTION_FORM_PAGE: 'CRIF_QUESTION_FORM_PAGE',
  INCOME_AND_EXPENSES_FORM_PAGE: 'INCOME_AND_EXPENSES_FORM_PAGE',
  BANK_DETAILS_FORM_PAGE: 'BANK_DETAILS_FORM_PAGE',

  // PERSONAL_DETAILS_FORM_PAGE - REF

  UPLOADED_AADHAAR_FRONT_SUCCESSFULLY: 'UPLOADED_AADHAAR_FRONT_SUCCESSFULLY',
  UPLOADING_AADHAAR_FRONT_FAILED: 'UPLOADING_AADHAAR_FRONT_FAILED',
  UPLOADED_AADHAAR_BACK_SUCCESSFULLY: 'UPLOADED_AADHAAR_BACK_SUCCESSFULLY',
  UPLOADING_AADHAAR_BACK_FAILED: 'UPLOADING_AADHAAR_BACK_FAILED',

  SUBMITTED_AADHAAR_DETAILS_SUCCESSFULLY: 'SUBMITTED_AADHAAR_DETAILS_SUCCESSFULLY',
  SUBMITTING_AADHAAR_DETAILS_FAILED: 'SUBMITTING_AADHAAR_DETAILS_FAILED',


  AADHAAR_OTP_SEND_SUCCESSFULLY: 'AADHAAR_OTP_SEND_SUCCESSFULLY',

  INITIATING_FETCHING_PIN_CODE_DETAILS: 'INITIATING_FETCHING_PIN_CODE_DETAILS',
  SUCCESSFULLY_FETCHED_PIN_CODE_DETAILS: 'SUCCESSFULLY_FETCHED_PIN_CODE_DETAILS',
  ERROR_IN_FETCHING_PIN_CODE_DETAILS: 'ERROR_IN_FETCHING_PIN_CODE_DETAILS',
  INVALID_PIN_CODE: 'INVALID_PIN_CODE',

  VERIFIED_AADHAAR_CAPTCHA_SUCCESSFULLY: 'VERIFIED_AADHAAR_CAPTCHA_SUCCESSFULLY',
  VERIFYING_AADHAAR_CAPTCHA_FAILED: 'VERIFYING_AADHAAR_CAPTCHA_FAILED',
  VERIFIED_AADHAAR_USING_OTP_SUCCESSFULLY: 'VERIFIED_AADHAAR_USING_OTP_SUCCESSFULLY',
  VERIFYING_AADHAAR_OTP_FAILED: 'VERIFYING_AADHAAR_OTP_FAILED',
  UPDATED_AADHAAR_DETAILS_WITHOUT_OTP: 'UPDATED_AADHAAR_DETAILS_WITHOUT_OTP',
  COMPLETED_PERSONAL_DETAILS_FORM: 'COMPLETED_PERSONAL_DETAILS_FORM',

  COMPLETED_PERSONAL_DETAILS_FORM_WITHOUT_OTP: 'COMPLETED_PERSONAL_DETAILS_FORM_WITHOUT_OTP',

  // BUSINESS_DETAILS_FORM_PAGE. - REF

  VERIFIED_GST_NUMBER_SUCCESSFULLY: 'VERIFIED_GST_NUMBER_SUCCESSFULLY',
  VERIFYING_GST_NUMBER_FAILED: 'VERIFYING_GST_NUMBER_FAILED',
  VERIFIED_GST_CAPTCHA_SUCCESSFULLY: 'VERIFIED_GST_CAPTCHA_SUCCESSFULLY',
  VERIFYING_GST_CAPTCHA_FAILED: 'VERIFYING_GST_CAPTCHA_FAILED',

  UPLOADED_GST_REGISTRATION_CERTIFICATE_SUCCESSFULLY: 'UPLOADED_GST_REGISTRATION_CERTIFICATE_SUCCESSFULLY',
  UPLOADING_GST_REGISTRATION_CERTIFICATE_FAILED: 'UPLOADING_GST_REGISTRATION_CERTIFICATE_FAILED',

  VERIFIED_PAN_NUMBER_SUCCESSFULLY: 'VERIFIED_PAN_NUMBER_SUCCESSFULLY',
  VERIFYING_PAN_NUMBER_FAILED: 'VERIFYING_PAN_NUMBER_FAILED',
  UPLOADED_SCANNED_COPY_OF_PAN_CARD_SUCCESSFULLY: 'UPLOADED_SCANNED_COPY_OF_PAN_CARD_SUCCESSFULLY',
  UPLOADING_SCANNED_COPY_OF_PAN_CARD_FAILED: 'UPLOADING_SCANNED_COPY_OF_PAN_CARD_FAILED',

  UPLOADED_SHOP_AND_ESTABLISHMENT_CERTIFICATE_SUCCESSFULLY: 'UPLOADED_SHOP_AND_ESTABLISHMENT_CERTIFICATE_SUCCESSFULLY',
  UPLOADING_SHOP_AND_ESTABLISHMENT_CERTIFICATE_FAILED: 'UPLOADING_SHOP_AND_ESTABLISHMENT_CERTIFICATE_FAILED',

  UPLOADED_MOC_AND_AOC_CERTIFICATES_SUCCESSFULLY: 'UPLOADED_MOC_AND_AOC_CERTIFICATES_SUCCESSFULLY',
  UPLOADING_MOC_AND_AOC_CERTIFICATES_FAILED: 'UPLOADING_MOC_AND_AOC_CERTIFICATES_FAILED',

  UPLOADED_CERTIFICATE_OF_INCORPORATION_SUCCESSFULLY: 'UPLOADED_CERTIFICATE_OF_INCORPORATION_SUCCESSFULLY',
  UPLOADING_CERTIFICATE_OF_INCORPORATION_FAILED: 'UPLOADING_CERTIFICATE_OF_INCORPORATION_FAILED',

  COMPLETED_BUSINESS_DETAILS_FORM: 'COMPLETED_BUSINESS_DETAILS_FORM',

  // INCOME_AND_EXPENSES_FORM_PAGE - REF

  UPLOADED_ITR_FILE_SUCCESSFULLY: 'UPLOADED_ITR_FILE_SUCCESSFULLY',
  UPLOADING_ITR_FILE_FAILED: 'UPLOADING_ITR_FILE_FAILED',

  UPLOADED_GST_RETURNS_FILE_SUCCESSFULLY: 'UPLOADED_GST_RETURNS_FILE_SUCCESSFULLY',
  UPLOADING_GST_RETURNS_FILE_FAILED: 'UPLOADING_GST_RETURNS_FILE_FAILED',

  FETCHED_ITR_DOCUMENT_SUCCESSFULLY: 'FETCHED_ITR_DOCUMENT_SUCCESSFULLY',
  FETCHING_ITR_DOCUMENT_FAILED: 'FETCHING_ITR_DOCUMENT_FAILED',

  FETCHED_GSTR_SUCCESSFULLY: 'FETCHED_GSTR_SUCCESSFULLY',
  FETCHING_GSTR_FAILED: 'FETCHING_GSTR_FAILED',

  COMPLETED_INCOME_AND_EXPENSES_FORM: 'COMPLETED_INCOME_AND_EXPENSES_FORM',

  // BANK_DETAILS_FORM_PAGE - REF

  FETCHED_BANK_STATEMENT_SUCCESSFULLY: 'FETCHED_BANK_STATEMENT_SUCCESSFULLY',
  FETCHING_BANK_STATEMENT_FAILED: 'FETCHING_BANK_STATEMENT_FAILED',

  FETCHED_BANK_DETAILS_FROM_NET_BANKING_SUCCESSFULLY: 'FETCHED_BANK_DETAILS_FROM_NET_BANKING_SUCCESSFULLY',
  FETCHING_BANK_DETAILS_FROM_NET_BANKING_FAILED: 'FETCHING_BANK_DETAILS_FROM_NET_BANKING_FAILED',
  FACING_ISSUE_WHILE_FETCHING_BANK_DETAILS: 'FACING_ISSUE_WHILE_FETCHING_BANK_DETAILS',

  PARTIALLY_SAVED_AND_PROCEEDED_BANK_STATEMENT_SCREEN : "PARTIALLY_SAVED_AND_PROCEEDED_BANK_STATEMENT_SCREEN",
  VERIFIED_BANK_STATEMENT_SUCCESSFULLY: "VERIFIED_BANK_STATEMENT_SUCCESSFULLY",

  UPLOADING_SCANNED_STATEMENT_FAILED_DUE_TO_SIZE_ISSUE: `UPLOADING_SCANNED_STATEMENT_FAILED_DUE_TO_SIZE_ISSUE`,
  DISTRIBUTOR_STATUS_IN_PROGRESS: 'DISTRIBUTOR_STATUS_IN_PROGRESS',
  FAILED_TO_UPLOAD__SCANNED_STATEMENT: 'FAILED_TO_UPLOAD__SCANNED_STATEMENT',
  UPLOADED_SCANNED_STATEMENT_SUCCESSFULLY: 'UPLOADED_SCANNED_STATEMENT_SUCCESSFULLY',
  FETCHING_BANK_NAMES_FOR_STATEMENT_PROCESSING: 'FETCHING_BANK_NAMES_FOR_STATEMENT_PROCESSING',
  FAILED_TO_FETCH_BANK_NAMES_FOR_STATEMENT_PROCESSING: 'FAILED_TO_FETCH_BANK_NAMES_FOR_STATEMENT_PROCESSING',
  SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_STATUS: 'SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_STATUS',
  FAILED_TO_FETCH_SCANNED_STATEMENT_STATUS: 'FAILED_TO_FETCH_SCANNED_STATEMENT_STATUS',
  SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_DATA: 'SUCCESSFULLY_FETCHED_SCANNED_STATEMENT_DATA',
  SUCCESSFULLY_STORED_SCANNED_STATEMENT_DATA: 'SUCCESSFULLY_STORED_SCANNED_STATEMENT_DATA',
  FAILED_TO_STORE_SCANNED_STATEMENT_DATA: 'FAILED_TO_STORE_SCANNED_STATEMENT_DATA',
  FAILED_TO_FETCH_SCANNED_STATEMENT_DATA: 'FAILED_TO_FETCH_SCANNED_STATEMENT_DATA',
  UPLOADED_SAVINGS_STATEMENTS_FOR_OD_ACCOUNT_TYPE: 'UPLOADED_SAVINGS_STATEMENTS_FOR_OD_ACCOUNT_TYPE',
  UPLOADED_OD_STATEMENT_FOR_SAVINGS_ACCOUNT_TYPE: 'UPLOADED_OD_STATEMENT_FOR_SAVINGS_ACCOUNT_TYPE',
  DISCREPANCY_FOUND_IN_BANK_ACCOUNT_HOLDER_CHECK: 'DISCREPANCY_FOUND_IN_BANK_ACCOUNT_HOLDER_CHECK',
  VERIFIED_BANK_ACCOUNT_HOLDER_CHECK_SUCCESSFULLY: 'VERIFIED_BANK_ACCOUNT_HOLDER_CHECK_SUCCESSFULLY',
  INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION: 'INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION',
  FAILED_TO_INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION: 'FAILED_TO_INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION',
  SUCCESSFULLY_INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION: 'SUCCESSFULLY_INITIATED_PERFIOS_SCANNED_STATEMENT_TRANSACTION',
  INITIATED_UPLOAD_SCANNED_TRANSACTION_STATEMENT: 'INITIATED_UPLOAD_SCANNED_TRANSACTION_STATEMENT',
  FAILED_TO_INITIATE_UPLOAD_SCANNED_TRANSACTION_STATEMENT: 'FAILED_TO_INITIATE_UPLOAD_SCANNED_TRANSACTION_STATEMENT',
  INITIATING_CANCEL_PERFIOS_SCANNED_TRANSACTION: 'INITIATING_CANCEL_PERFIOS_SCANNED_TRANSACTION',
  SUCCESSFULLY_CANCELLED_PERFIOS_SCANNED_TRANSACTION: 'SUCCESSFULLY_CANCELLED_PERFIOS_SCANNED_TRANSACTION',
  SUCCESSFULLY_UPLOADED_SCANNED_TRANSACTION_STATEMENT: 'SUCCESSFULLY_UPLOADED_SCANNED_TRANSACTION_STATEMENT',
  INITIATED_PROCESS_PERFIOS_SCANNED_STATEMENT: 'INITIATED_PROCESS_PERFIOS_SCANNED_STATEMENT',
  FAILED_TO_INITIATE_PROCESS_PERFIOS_SCANNED_STATEMENT: 'FAILED_TO_INITIATE_PROCESS_PERFIOS_SCANNED_STATEMENT',
  SUCCESSFULLY_INITIATED_PROCESS_PERFIOS_SCANNED_STATEMENT: 'SUCCESSFULLY_INITIATED_PROCESS_PERFIOS_SCANNED_STATEMENT',
  FAILED_TO_INITIATE_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT: 'FAILED_TO_INITIATE_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT',
  SUCCESSFULLY_INITIATED_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT: 'SUCCESSFULLY_INITIATED_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT',
  SCANNED_STATEMENT_PROCESSING_IS_INITIATED: 'SCANNED_STATEMENT_PROCESSING_IS_INITIATED',
  SUCCESSFULLY_INITIATED_SCANNED_STATEMENT_PROCESS: 'SUCCESSFULLY_INITIATED_SCANNED_STATEMENT_PROCESS',
  FAILED_INITIATING_SCANNED_STATEMENT_PROCESS: 'FAILED_INITIATING_SCANNED_STATEMENT_PROCESS',
  FACING_ISSUE_WHILE_SCANNED_STATEMENT_UPLOAD: 'FACING_ISSUE_WHILE_SCANNED_STATEMENT_UPLOAD',
  INITIATED_SCANNED_STATEMENT_PROCESS: 'INITIATED_SCANNED_STATEMENT_PROCESS',
  INITIATED_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT: 'INITIATED_GENERATE_REPORT_FOR_PROCESSED_SCANNED_STATEMENT',

  // CRIF_FORM_PAGE - REF
  WRONG_ANSWER_SUBMIT_FOR_CRIF_QUESTION: 'WRONG_ANSWER_SUBMIT_FOR_CRIF_QUESTION',
  CRIF_AUTH_FAILURE: 'CRIF_AUTH_FAILURE',
  CRIF_RESOLVED_SUCCESSFULLY: 'CRIF_RESOLVED_SUCCESSFULLY',
  FACING_ISSUE_WHILE_RESOLVING_CRIF: 'FACING_ISSUE_WHILE_RESOLVING_CRIF',
};


const logoutTrack = {
  LOGOUT: 'LOGOUT',
}

/**
 * Spreading all the tracks
 */
 export const  trackString = {
  ...loginPageStrings,
  ...setPasswordScreenStrings,
  ...dashboardPageStrings,
  ...businessDetailsScreen,
  ...logoutTrack,
  ...itrDetailsScreen,
  ...gstrDetailsScreen,
  ...summaryScreen,
  ...crifDetailsScreen,
};
