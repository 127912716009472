/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { utils } from '../../utils/utils';

const { getDataFromLocalStorage } = utils

const {
  localStorageStrings: {
      STEP_INFO_LABEL,
      BANK_NAMES
  }
} = require('../../utils/enum/localStorageStrings');

export default function BankNameSelect(props) {

  const { context } = props;

  const stepInfo = getDataFromLocalStorage(STEP_INFO_LABEL) || {};

  const { bankData = { id: ''} } = stepInfo;

  const bankDetails = JSON.parse(localStorage.getItem(BANK_NAMES)) || {};

  return (
    <Autocomplete
      id="bank-names-list"
      style={{ width: '100%' }}
      value={bankData}
      getOptionSelected={ option => option.id === bankData.id }
      options={bankDetails}
      className={'flex halfWidth'}
      autoHighlight
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        context.setState({ bankData : newValue });
        localStorage.setItem(STEP_INFO_LABEL, JSON.stringify({
          ...stepInfo,
          bankData: newValue
        }))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select the Bank Name"
          variant="outlined"
        />
      )}
    />
  );
}