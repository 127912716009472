import validator from 'validator';

import { utils } from '../../../../utils/utils';

import { regexExp } from '../../../../utils/validationRegex';

import { fixedValues } from '../../../../utils/enum/currency';

import _ from 'lodash'

const { validatingBusinessFormFields, validateFormErrorStatus, validateIsDocumentVerified } = utils;

const { ALPHA_NUMERIC_WITH_SPACE_REG_EXP, GST_REG, PAN_REG_EXP } = regexExp;

const moment = require('moment');

const NAME = 'name';

const ADDRESS = 'address'

const POSTAL_CODE = "pin_code"

const DISTRICT = "district"

const STATE = "state"

const CARE_OF_NAME = "careof"

const DOB = 'dob';

const OD_LIMIT = 'ODLimit';

const GST_NUMBER = 'gstNo';

const DATE_OF_INCORPORATION = 'dateOfIncorporation';

const PERSONAL_PAN = 'personalPanNumber';

const NO_OF_OFFICER = 'noofOfficers';

const NO_OF_DIRECTORS = 'noofDirectors';

const NAMES_OF_HUF = 'nameofHinduUndivedFamily';

const ADDRESS_OF_HUF = 'addresssofHinduUndivedFamily';

const AGES_OF_HUF = 'ageofHinduUndivedFamily';

const BUSINESS_CODE = 'businessCode';

const {
  MIN_OD_LIMIT,
  PAN_TYPE: {
    INDIVIDUAL,
    COMPANY,
    HINDU_UNDIVIDED_FAMILY,
    FIRM_LIMITED_LIABILITY_PARTNERSHIP,
    ASSOCIATION_OF_PERSON
  },
  MIN_AGE_LIMIT,
  MAX_AGE_LIMIT
} = fixedValues;


export const validateBusinessForm = (state) => {

  const {
    businessCode,
    gstForm,
    isPanVerify,
    personalPanNumber,
    PAN,
    panType,
    panSubType,
    isExistingDistributor,
    dateOfIncorporation
  } = state;

  let result = [
    gstForm,
    isPanVerify,
    personalPanNumber,
    PAN,
    dateOfIncorporation
  ];

  // if existing distributor we are taking business code for validation
  isExistingDistributor && result.push(businessCode);

  const finalResult = result.concat(formatCompanyDetails(state, panSubType, panType));  

  const isDocumentVerified = isDocumentsVerified(state, panType);

  return isDocumentVerified && validatingBusinessFormFields(finalResult);

}

const upperCase = (type) => {
  return type && type.toUpperCase();
}

const isDocumentsVerified = (state, panType) => {

  const { isPersonalPanVerify } = state;

  let validationFields = [];

  if (upperCase(panType) !== INDIVIDUAL) { validationFields.push(isPersonalPanVerify) }

  return validateIsDocumentVerified(validationFields);

}

/**
 * @description Function for checking if passed object is empty or not.
 * @name isEmpty
 * @param {Object} array 
 * @returns {Boolean}
 */
const isEmpty = (array) => {
  return _.isEmpty(array) ? "" : array
}

const formatCompanyDetails = (state, subtype, type) => {

  const { momCertificate, noofDirectors, nameofHinduUndivedFamily, addresssofHinduUndivedFamily, ageofHinduUndivedFamily, agreementDeed, panSubType, incorporation } = state;

  if (subtype === 'Private Limited Company' && upperCase(type) === COMPANY) return [isEmpty(momCertificate), isEmpty(noofDirectors), isEmpty(incorporation)]

  if (subtype === 'Public Limited Company' && upperCase(type) === COMPANY) return [isEmpty(momCertificate), isEmpty(noofDirectors), isEmpty(incorporation)]

  if (upperCase(type) === HINDU_UNDIVIDED_FAMILY) return [isEmpty(nameofHinduUndivedFamily), isEmpty(addresssofHinduUndivedFamily), isEmpty(ageofHinduUndivedFamily)]

  if (upperCase(type) === FIRM_LIMITED_LIABILITY_PARTNERSHIP || upperCase(type) === ASSOCIATION_OF_PERSON) return [agreementDeed];

  if (upperCase(type) === COMPANY) { return [panSubType] }

  return [];

}


export const validateAadharForm = (stateValue) => {

  const {
    name,
    dob,
    gender,
    careof,
    district,
    pin_code,
    state,
    address,
  } = stateValue;

  let result = [
    name,
    dob,
    gender,
    careof,
    district,
    pin_code,
    state,
    address
  ];

  return validatingBusinessFormFields(result);

}


export const isValid = (label, value) => {

  let trimValue = _.trim(`${value}`)

  let validationResult = false;

  switch (label) {

    case NAME: validationResult = ALPHA_NUMERIC_WITH_SPACE_REG_EXP.test(trimValue) && validator.isAscii(trimValue) && validator.isLength(trimValue, { min: 1, max: 50 })
      break

    case DISTRICT: validationResult = ALPHA_NUMERIC_WITH_SPACE_REG_EXP.test(trimValue) && validator.isAscii(trimValue) && validator.isLength(trimValue, { min: 1, max: 50 })
      break

    case CARE_OF_NAME: validationResult = ALPHA_NUMERIC_WITH_SPACE_REG_EXP.test(trimValue) && validator.isAscii(trimValue) && validator.isLength(trimValue, { min: 1, max: 50 })
      break

    case POSTAL_CODE: validationResult = validator.isPostalCode(trimValue, 'IN');
      break;

    case STATE: validationResult = !validator.isEmpty(value);
      break;

    case ADDRESS: validationResult = validator.isAscii(trimValue) && validator.isLength(trimValue, { min: 3, max: 250 })
      break;

    case DOB: const age = moment().diff(trimValue, 'years');
    validationResult = (age >= MIN_AGE_LIMIT && age < MAX_AGE_LIMIT);
      break;

    case DATE_OF_INCORPORATION: validationResult = (validator.isBefore(trimValue, moment().format(`YYYY-MM-DD`)))
      break;

    case OD_LIMIT: validationResult = !(validator.isNumeric(trimValue, { no_symbols: true }) && Number(trimValue) >= MIN_OD_LIMIT)
      break;

    case GST_NUMBER : validationResult = GST_REG.test(value);
      break;

    case PERSONAL_PAN : validationResult = PAN_REG_EXP.test(value);
      break;
    
    case NO_OF_OFFICER : validationResult =  !_.isEmpty(value) && value.every( field => validator.isAscii(field));
      break;

    case NO_OF_DIRECTORS : validationResult =  !_.isEmpty(value) && value.every( field => validator.isAscii(field));
      break;
    
    case NAMES_OF_HUF : validationResult = !_.isEmpty(value) && value.every( field => validator.isAscii(field));
      break;

    case ADDRESS_OF_HUF : validationResult = validator.isAscii(trimValue) && validator.isLength(trimValue, { min: 3, max: 250 })
      break;

    case AGES_OF_HUF : validationResult = !_.isEmpty(value) && value.every( field => isNaN(field)===false && (field<100) && (field>0));
      break;

    case BUSINESS_CODE : validationResult = validator.isAlphanumeric(value);
      break;

    default: validationResult = false;
      break;
  }

  return !validationResult

}

export const validatePersonalInfo = (errorStatus) => {

  const {
    name,
    dob,
    careof,
    district,
    pin_code,
    state,
    address,
  } = errorStatus;

  let result = [
    name,
    dob,
    careof,
    district,
    pin_code,
    state,
    address
  ];

  return validateFormErrorStatus(result);

}