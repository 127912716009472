import React, { Component } from 'react';

import Loader from '../../../../utils/loader';

import Snackbar from '../../../../utils/alert';

import { Mixpanel } from '../../../../utils/mixPanel';

import { trackString } from '../../../../utils/mixpanelTracks';

import { utils } from '../../../../utils/utils'

import { utilFunctions } from '../../../../utils'

import { completeAssessment, getPerfiosTransactionStatus } from './service/index';

import { getPersonalInfoPayload, getBusinessInfoPayload } from './payload/index';

import Modal from 'react-awesome-modal';

const { getDataFromLocalStorage } = utils;

const { isAllInformationVerified } = utilFunctions;

const {
    languageStrings: {
        SUMMARY_SCREEN: {
            COMPLETE_ASSESSMENT,
            SUMMARY_DETAILS
        }
    }
} = require('../../../../utils/enum/languageStrings');

const {
    localStorageStrings: {
        STEP_INFO_LABEL
    }
} = require('../../../../utils/enum/localStorageStrings');

export default class SummaryForm extends Component {

    constructor(props){
        super(props);
        Mixpanel.track(trackString.VISITED_SUMMARY_SCREEN)
    }

    state = {
        loader: false,
        popLoader: false,
        showGrowl: false,
        growlMsg: "",
        visible: false,
        documentStatus: {
            bankVerificationForm: false,
            businessForm: false,
            gstrReturnsForm: false,
            itrReturnsForm: false,
            summaryForm: false,
            perfiosScannedStatement: getDataFromLocalStorage(STEP_INFO_LABEL) && getDataFromLocalStorage(STEP_INFO_LABEL).perfiosScannedStatement
        },
        perfiosScannedDocumentStatus : {}
    }

    componentDidMount = async () => {
        // get flag from the local storage or from DB...

        let {
            businessForm = false,
            itrReturnsForm = false,
            gstrReturnsForm = false,
            bankVerificationForm = false,
            perfiosScannedStatement = false,
            perfiosTransactionId = ''
        } = getDataFromLocalStorage(STEP_INFO_LABEL);

        await this.getTransactionStatus(perfiosScannedStatement, perfiosTransactionId);

        this.setState({
            documentStatus: {
                businessForm,
                itrReturnsForm,
                gstrReturnsForm,
                bankVerificationForm,
                perfiosScannedStatement,
            }
        })
    }

    getTransactionStatus = async(perfiosScannedStatement, perfiosTransactionId) => {
        if( perfiosScannedStatement && perfiosTransactionId){
            this.setState({popLoader : true});
            await getPerfiosTransactionStatus({
                perfiosTransactionId,
                updateState: this.updateState,
                state: this.state,
                renderErrorGrowl: this.renderErrorGrowl,
                renderSuccessGrowl: this.renderSuccessGrowl
            });
            this.setState({popLoader : false});
        }
    }

    onValueChange = event => {

        const { name, value } = event.target;

        this.setState({ [name]: value })
    }

    renderSubTitle = (label) => {
        return (
            <div className='BusinessFormField'>
                <label className='summaryFlexLabel summarySubtitle '>{label}</label>
            </div>
        )
    }

    renderSuccessStatus = (statusMessage) => {
        return <label className='summaryFlexLabel textLeft successMessage'>{statusMessage}</label>
    }

    renderWarningStatus = (message, onRedirect) => (
        <div>
            <div className="summaryFlexLabel textLeft ahrefWarningLink" onClick={onRedirect && (() => onRedirect())}>{message}</div>
        </div>
    )

    updateState = (data) => {
        this.setState(data);
    }

    renderTitle = () => {
        return <div className="textcenter">
            <h3 className="subtitle formtitleMargin"> {SUMMARY_DETAILS} </h3>
        </div>
    }

    renderInputFields = (inputFields) => {
        const { label, isValid, message, onRedirect = "" } = inputFields;
        return <div className='BusinessFormField'>
            <label className='summaryFlexLabel textLeft'>{label}</label>
            <div className="flex halfWidth">
                {isValid ? this.renderSuccessStatus(message) : this.renderWarningStatus(message, onRedirect)}
            </div>
        </div>
    }

    /**
     * Function for listing personal information verification status
     */
    renderPersonalDetailsInfo = () => {
        const { title, documentsList } = getPersonalInfoPayload();
        return (<>
            {this.renderSubTitle(title)}
            {documentsList.map(item => this.renderInputFields(item))}
        </>)
    }

    /**
     * Function for listing business documents verification status
     */
    renderBusinessDetailsInfo = () => {

        const { perfiosScannedDocumentStatus } = this.state;

        const { redirectSpecificStep } = this.props;

        const { title, documentsList } = getBusinessInfoPayload({
            redirectSpecificStep, perfiosScannedDocumentStatus
        });

        return (<>
            {this.renderSubTitle(title)}
            {documentsList.map(item => this.renderInputFields(item))}
        </>)
    }

    renderErrorGrowl = (msg) => {
        this.setState({showGrowl: true, growlStatus: 'error', growlMsg: msg});
    }
    renderSuccessGrowl = (msg) => {
        this.setState({showGrowl: true, growlStatus: 'success', growlMsg: msg});
    }
    handleClose = () => {this.setState({ showGrowl: false });};

    checkAllInformationVerified = () => {
        const {
            bankVerificationForm,
            businessForm,
            gstrReturnsForm,
            itrReturnsForm } = getDataFromLocalStorage(STEP_INFO_LABEL);

        let payload = [bankVerificationForm, businessForm, gstrReturnsForm, itrReturnsForm];
        return isAllInformationVerified(payload)
    }

    onSubmitAssessment = async () => {
        await completeAssessment({
            props: this.props,
            updateState: this.updateState
        });
    }

    renderPopLoader = () => {
        return <Modal visible={this.state.popLoader} effect="fadeInUp">
            <div className="padding50">
                <div className="textcenter">
                    <Loader />
                </div>
            </div>
        </Modal>
    }
    
    render() { 

        const { showGrowl, growlStatus, growlMsg } = this.state;

        const showCompleteAssessment = this.checkAllInformationVerified();

        return (
            <div className={"msmeOnboardingContainer"} id="formStepper">

                {this.renderTitle()}

                {this.renderPersonalDetailsInfo()}

                {this.renderBusinessDetailsInfo()}

                <Snackbar handleClose={this.handleClose} showBar={showGrowl} status={growlStatus} msg={growlMsg} />

                {
                    <div className={'businessDetails'}>

                        {this.renderPopLoader()}

                        {this.state.loader && <Loader />}

                        {!this.state.loader && showCompleteAssessment &&
                            <p className={"combutton"}
                                onClick={() => this.onSubmitAssessment()} >{COMPLETE_ASSESSMENT}
                            </p>
                        }
                    </div>
                }
            </div>

        );
    }

}

