import React from 'react';

import { withRouter } from 'react-router-dom';

import FooterComponent from '../../components/Footer/index'

import Dashboard from './index';

import SideBar from "../../components/Sidebar/index";

import { utils } from '../../utils/utils'
import Modal from 'react-awesome-modal';

import DistributorOnboardingContainer from '../DistributorOnboarding/DistributorOnboardingContainer';

const { getUserData, logOut, getItemByName } = utils;

const {
  fixedValues: {
    DEFAULT_ONBOARDING_STATUS,
    STEP_0_AND_1
  }
} = require('../../utils/enum/currency')

function DashboardContainer(props) {
  return (
    <div className=''>
      <SideBar />
      <Modal visible={STEP_0_AND_1.includes(getItemByName('activeStep'))} effect="fadeInUp">
            <div className="padding50">
            Please use our new portal for onboarding. For support, contact help@actyvaisupport.zohodesk.in.
            <div className='.marginTopForm'>
            <b>Admin Portal:</b> <a href='https://nivea-platform.actyv.ai/'>https://nivea-platform.actyv.ai/</a>
            </div>
            <div className='.marginTopForm'>
            <b>Distributor Portal:</b> <a href='https://platform.actyv.ai/'>https://platform.actyv.ai/</a>
            </div>
                    <div className="textcenter">
                        <p className={"combutton"}
                            onClick={logOut}>{'EXIT'}</p>
                    </div>
            </div>
        </Modal>
      {getUserData() && getUserData().onboardingStatus &&
        getUserData().onboardingStatus !== DEFAULT_ONBOARDING_STATUS ? <Dashboard history={props.history} /> :
        <DistributorOnboardingContainer history={props.history} />}
      <FooterComponent />
    </div>
  )
}

export default withRouter(DashboardContainer)
